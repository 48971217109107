import React, { useCallback, useState } from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import AuthStoreSelect from '../../components/AuthStoreSelect'
import CaseEditTable from '../../components/CaseEditTable';
import { Col, Empty, Row } from 'antd';
import PermissionManager from '../../config/PermissionManager';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';

function AppraiseCasesPage() {
    const [storeId, setStoreId] = useState();
    const roles = PermissionManager.getRoles();
    const handleSelectStore = useCallback((value) => {
        console.log("store", storeId);
        setStoreId(value);
    }, [storeId]);
    return (
        <ContentLayout>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <AuthStoreSelect type="appraisal" value={storeId} onChange={handleSelectStore} />
                </Col>
                <Col span={24}>
                    {!storeId && <Empty description="請先選擇門市" />}
                    {storeId && <CaseEditTable
                        roles={roles}
                        storeId={storeId}
                        storeType="appraisal"
                        onIndex={(query, page, pageSize) => KodomoRecyclingAPI.store.stores.cases.index(storeId, query, page, pageSize)}
                        onShow={(id) => KodomoRecyclingAPI.store.stores.cases.show(storeId, id)}
                        onPhotoIndex={(caseId, goodId, query) => KodomoRecyclingAPI.store.stores.cases.goods.photos.index(storeId, caseId, goodId, query)}
                        onPhotoShow={(caseId, goodId, id) => KodomoRecyclingAPI.store.stores.cases.goods.photos.show(storeId, caseId, goodId, id)}
                    />}
                </Col>
            </Row>
        </ContentLayout>
    )
}

export default AppraiseCasesPage