import { Button, Drawer, Form, Space, Spin, notification } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
// eslint-disable-next-line no-unused-vars
import { ColumnType } from 'antd/es/table';
import dayjs from 'dayjs';

/**
 * @typedef {Object} DrawerColumnType
 * @property {React.ReactNode} formComponent 欄位的表單組件
 * @property {string} formItemName 欄位的表單欄位名稱
 * @property {Object} formItemProps 欄位的表單屬性
 * @property {boolean} hiddenFromForm 從表單隱藏
 * @typedef {ColumnType | DrawerColumnType} EditTableColumnType
 */

/**
 * 編輯 Drawer 元件
 *
 * @component
 * @param {Object} props 屬性
 * @param {string} props.name 元件名稱
 * @param {boolean} props.open 是否打開 Drawer 
 * @param {string} props.mode 模式 ('store' 或 'update')
 * @param {string | number | null} props.editId 編輯的 ID
 * @param {DrawerColumnType[]} [props.columns=[]] 表單欄位
 * @param {boolean} props.readonly 是否為唯讀
 * @param {function} props.onShow 顥示編輯內容的 Callback 函式
 * @param {function} props.onStore 建立新內容的 Callback 函式
 * @param {function} props.onUpdate 更新內容的 Callback 函式
 * @param {function} props.onComplete 完成編輯的 Callback 函式
 * @param {function} props.onClose 關閉 Drawer 的回調函式
 * @param {string} props.editText 修改動作文字
 * @param {boolean} props.hideReset 隱藏重置按鈕
 * @param {string} props.submitText 修改送出文字
 * @param {boolean} props.highlightEditable 隱藏重置按鈕
 * @returns {JSX.Element} 編輯 Drawer 元件
 */
function EditableDrawer({
  name,
  open,
  mode,
  editId,
  columns = [],
  onShow,
  onStore,
  onUpdate,
  onComplete,
  onClose,
  readonly,
  editText = '修改',
  hideReset = false,
  submitText = '送出',
  highlightEditable = false,
  ...props }) {
  const [editLoading, setEditLoading] = useState(false);
  const [editForm] = Form.useForm();
  const formDefaultValue = useRef();

  const getFormItemName = (column) => {
    return column.formItemName || column.dataIndex;
  }
  const getFormFieldsValue = useCallback((row) => {
    let result = {
      ...row,
    };
    columns.forEach((column) => {
      // 若未設定 formItemName 則使用預設以 dataIndex 做為表單欄位名稱
      const formItemName = getFormItemName(column);
      if (!formItemName) return;
      if (!Array.isArray(column.dataIndex)) {
        result[formItemName] = row[column.dataIndex];
      } else {
        // 若 dataIndex 為二維陣列則依階層取出 row 的值
        const dataIndexs = column.dataIndex;
        let rowValue = row;
        dataIndexs.forEach((dataIndex) => {
          rowValue = rowValue[dataIndex];
        });
        result[formItemName] = rowValue;
      }

    });
    // console.log("getColumnValues", row, result);
    return result;
  }, [columns]);

  const refresh = useCallback((id) => {
    setEditLoading(true);
    onShow && onShow(id).then((response) => {
      const result = response.data;
      const fieldsValue = getFormFieldsValue(result);
      formDefaultValue.current = fieldsValue;
      editForm.setFieldsValue(fieldsValue);
      console.log("EditDrawer: data loaded.", result);
    }).finally(() => {
      setEditLoading(false);
    });
  }, [editForm, getFormFieldsValue, onShow]);

  useEffect(() => {
    if (open) {
      if (editId) {
        refresh(editId);
      }
    }
  }, [editId, refresh, open]);

  const handleHideDrawer = useCallback(() => {
    editForm.resetFields();
    onClose && onClose();
  }, [editForm, onClose]);

  const handleFinish = useCallback((values) => {
    console.log("handleFinish", values);
    if (mode === 'store' && onStore) {
      setEditLoading(true);
      onStore && onStore(values)?.then((response) => {
        notification.info({ message: `新增${name}成功` });
        editForm.resetFields();
        onComplete && onComplete(response.data);
      }).catch((error) => {
      }).finally(() => {
        setEditLoading(false);
      });
    }
    if (mode === 'update' && onUpdate) {
      onUpdate && onUpdate(editId, values)?.then((response) => {
        notification.info({ message: `${editText}${name}成功` });
        editForm.resetFields();
        onComplete && onComplete(response.data);
      }).catch((error) => {
      }).finally(() => {
        setEditLoading(false);
      });
    }
  }, [mode, onStore, onUpdate, name, editForm, onComplete, editId, editText]);

  const handleReset = useCallback(() => {
    editForm.setFieldsValue(formDefaultValue.current);
  }, [editForm]);


  const IsHiddenFromForm = useCallback((column, { getFieldValue }) => {
    // 若有定義 hiddenFromForm 則動態判斷是否顯示
    let hiddenFromForm = false;
    if (typeof column.hiddenFromForm === 'function') {
      hiddenFromForm = column.hiddenFromForm({ getFieldValue });
    } else {
      hiddenFromForm = column.hiddenFromForm;
    }
    return hiddenFromForm;
  }, []);

  const newColumns = useMemo(() => {
    console.log("EditDrawer: columns changed.", columns);
    return columns
      .filter((column) => column.title)
      .map((column) => {
        //console.log("EditDrawer: column", column);
        // 若有定義 formComponent 則回傳可編輯欄位
        if (getFormItemName(column) && column.formComponent) {
          return <Form.Item key={getFormItemName(column)} shouldUpdate={true} noStyle>
            {({ getFieldValue }) => {
              if (IsHiddenFromForm(column, { getFieldValue })) return;
              return <Form.Item
                label={
                  // highlightEditable ?
                  //   <span><span style={{ fontSize: 20, color: '#69CB64' }}>●</span> {column.title}</span>
                  //   :
                  column.title
                }
                name={getFormItemName(column)}
                validateStatus={highlightEditable ? "warning" : undefined}
                {...column.formItemProps}>
                {column.formComponent}
              </Form.Item>
            }}

          </Form.Item>
        } else { // Readonly
          return <Form.Item key={`key-${column.title}`} shouldUpdate={true} noStyle>
            {({ getFieldValue, getFieldsValue }) => {
              if (IsHiddenFromForm(column, { getFieldValue })) return;
              if (typeof column.render === 'function') {
                return <Form.Item label={column.title} {...column.formItemProps}>
                  {column?.render(getFieldValue(column.dataIndex), getFieldsValue(true))}
                </Form.Item>
              }
              if (getFieldValue(column.dataIndex) && typeof getFieldValue(column.dataIndex) === 'object') {
                if (dayjs.isDayjs(getFieldValue(column.dataIndex))) {
                  return <Form.Item label={column.title} {...column.formItemProps}>
                    {getFieldValue(column.dataIndex).format("YYYY-MM-DD HH:mm:ss")}
                  </Form.Item>
                } else {
                  return <Form.Item label={column.title} {...column.formItemProps}>
                    無法顯示的數值
                  </Form.Item>
                }
              }
              return <Form.Item label={column.title} {...column.formItemProps}>
                {getFieldValue(column.dataIndex) ?? '-'}
              </Form.Item>
            }}
          </Form.Item>
        }
      })
  }, [columns, IsHiddenFromForm, highlightEditable]);

  // console.log("EditDrawer: columns", columns);
  return (
    <Drawer title={`${mode === 'store' ? '新增' : (readonly ? '檢視' : editText)}${name}`} open={open} onClose={handleHideDrawer} {...props}>
      <Spin spinning={editLoading}>
        <Form
          layout={props.size === "large" ? "horizontal" : "vertical"}
          labelCol={props.size === "large" ? { span: 8 } : {}}
          labelAlign="left"
          form={editForm} onFinish={handleFinish} disabled={readonly}>
          {newColumns}
          <Form.Item style={{ textAlign: 'center' }}>
            <Space>
              {!readonly && !hideReset && <Button onClick={handleReset}>重設</Button>}
              {!readonly ?
                <Button type="primary" htmlType="submit">{submitText}</Button> :
                <Button type="primary" disabled={false} onClick={handleHideDrawer}>關閉</Button>}
            </Space>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  )
}

export default EditableDrawer