import React, { useCallback, useState } from 'react'
import { Button, Form, Input, notification } from 'antd'
import { useNavigate, useParams } from 'react-router-dom';
import AddressSelect from '../../components/AddressSelect';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';

const liff = window.liff;

function RejectSalePage() {
    const navigate = useNavigate();
    let { caseId } = useParams();
    const [saleRejectForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    console.log("caseId", caseId);

    const handleFinish = useCallback((values) => {
        if (!Number.parseInt(caseId)) {
            notification.warning({ message: '案件不存在，返回案件清單' });
            navigate('/user/cases');
            return;
        }
        setLoading(true);
        console.log("RejectSalePage: ", values);
        KodomoRecyclingAPI.member.cases.salesReject(caseId, {
            ...values,
            returnaddr: values.returnaddr.fullAddress,
        }).then((response) => {
            notification.info({ message: '已拒絕販售' });
            setTimeout(() => {
                if (liff.isInClient()) {
                    liff.closeWindow();
                } else {
                    navigate('/user/cases');
                }
            }, 500);
        }).catch(() => {
            //
        }).finally(() => {
            setLoading(false);
        })
    }, [caseId, navigate]);
    return (
        <div style={{ padding: 20 }}>
            <h2>商品寄回</h2>
            <Form form={saleRejectForm} onFinish={handleFinish} labelCol={{ span: 4 }}>
                <Form.Item label="退回聯絡人" name="returncontactname" rules={[{ required: true }]}>
                    <Input placeholder="請輸入退回聯絡人" />
                </Form.Item>
                <Form.Item label="退回聯繫電話" name="returncontacttel" rules={[
                    { required: true },
                    {
                        pattern: /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/,
                        message: '請輸入有效的電話號碼，例: 02-29876543 或 0987654321)',
                    }
                ]}>
                    <Input placeholder="請輸入退回聯繫電話" />
                </Form.Item>
                <Form.Item label="退回地址" name="returnaddr" rules={[{ required: true }]}>
                    <AddressSelect />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button loading={loading} htmlType="submit" type="primary">送出</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default RejectSalePage