import React, { useCallback, useEffect, useState } from 'react'
import EditableDrawer from './EditableDrawer';
import { Button, Col, Popconfirm, Row, Space, Table, Typography, notification } from 'antd';
// eslint-disable-next-line no-unused-vars
import { ColumnType } from 'antd/es/table';

/**
 * @typedef {Object} DrawerColumnType
 * @property {React.ReactNode} formComponent 欄位的表單組件
 * @property {string} formItemName 欄位的表單欄位名稱
 * @property {Object} formItemProps 欄位的表單屬性
 * @property {boolean} hiddenFromForm 從表單隱藏
 * 
 * @typedef {Object} TableColumnType
 * @property {boolean} hidden 是否隱藏欄位
 * 
 * @typedef {ColumnType | DrawerColumnType | TableColumnType} EditTableColumnType
 */

/**
 * 編輯表格元件
 *
 * @component
 * @param {Object} props 屬性
 * @param {string} props.name 元件名稱
 * @param {EditTableColumnType[]} props.columns 欄位
 * @param {boolean} props.readonly 是否為唯讀
 * @param {function} props.onIndex 查詢列表的 Callback 函式
 * @param {function} props.onDestroy 刪除列表項目的回調函式
 * @param {function} props.onShow 查看列表項目的 Callback 函式
 * @param {function} props.onStore 新增列表項目的 Callback 函式
 * @param {function} props.onUpdate 更新列表項目的 Callback 函式
 * @param {function} props.extraActions 額外的操作項目
 * @param {function} props.extraActions.onClick onClick 事件
 * @param {string} props.editText 修改動作文字
 * @param {EditableDrawerProps} prop.drawerProps EditDrawer 額外屬性
 * @returns {JSX.Element} 編輯表格元件
 */
function EditableTable({
    name,
    columns,
    onIndex,
    onDestroy,
    onShow,
    onStore,
    onUpdate,
    readonly,
    extraActions = [],
    drawerSize = undefined,
    editText = '修改',
    drawerProps = {},
    ...props
}) {
    const [categories, setCategories] = useState([]);
    const [editDrawer, setEditDrawer] = useState({ open: false, mode: 'store', editId: null });
    const [pagination, setPagination] = useState({
        current: null,
        pageSize: null,
        total: null,
    });
    const [loading, setLoading] = useState(false);
    const refresh = useCallback((page, pageSize) => {
        setLoading(true);
        onIndex && onIndex({}, page, pageSize)?.then((response) => {
            const result = response.data;
            setCategories(result.data);
            setPagination({
                current: result.current_page,
                pageSize: result.per_page,
                total: result.total,
            });
        }).catch((error) => {

        }).finally(() => {
            setLoading(false);
        });
    }, [onIndex]);

    useEffect(() => {
        refresh(1, 10);
    }, [refresh]);

    const handleShowAddDrawer = useCallback(() => {
        setEditDrawer({
            mode: 'store',
            editId: null,
            open: true,
        });
    }, []);

    const handleShowUpdateDrawer = useCallback((storeId) => {
        setEditDrawer({
            mode: 'update',
            editId: storeId,
            open: true,
        });
    }, []);

    const handleHideDrawer = useCallback(() => {
        setEditDrawer({
            mode: 'store',
            editId: null,
            open: false,
        });
    }, []);

    const handleDelete = useCallback((storeId) => {
        onDestroy && onDestroy(storeId).then((response) => {
            notification.info({ message: `刪除${name}成功` });
            refresh();
        }).finally(() => {
        });
    }, [name, onDestroy, refresh]);

    const handleTableChange = useCallback((pagination, filters, sorter, extra) => {
        console.log("handleTableChange", pagination);
        refresh(pagination.current, pagination.pageSize);
    }, [refresh]);

    const newColumns = [
        ...columns.filter((column) => !column.hidden),
        {
            title: '操作',
            fixed: 'right',
            render: (value, record) => <Space>
                <Typography.Link onClick={() => handleShowUpdateDrawer(record.id)}>{readonly ? '檢視' : editText}</Typography.Link>
                {onDestroy && <Popconfirm title="是否確認要刪除?" onConfirm={() => handleDelete(record.id)} >
                    <Typography.Link>刪除</Typography.Link>
                </Popconfirm>}
                {extraActions.map((action) => <Typography.Link key={action?.name} onClick={() => action?.onClick && action.onClick(record)}>{action?.name}</Typography.Link>)}
            </Space>
        }
    ]

    return (
        <>
            <Row gutter={[10, 10]}>
                {onStore && <Col span={24} style={{ textAlign: 'right' }}>
                    <Button onClick={handleShowAddDrawer}>新增</Button>
                </Col>}
                <Col span={24}>
                    <Table
                        loading={loading}
                        dataSource={categories}
                        columns={newColumns}
                        rowKey={props.rowKey ?? "id"}
                        scroll={{ x: 'max-content' }}
                        pagination={pagination}
                        onChange={handleTableChange} />
                </Col>
            </Row>
            <EditableDrawer
                size={drawerSize}
                readonly={readonly}
                name={`${name}`}
                open={editDrawer.open}
                mode={editDrawer.mode}
                editId={editDrawer.editId}
                columns={columns}
                onShow={onShow ? (id) => onShow && onShow(id) : null}
                onStore={onStore ? (values) => onStore && onStore(values) : null}
                onUpdate={onUpdate ? (id, values) => onUpdate && onUpdate(id, values) : null}
                onComplete={() => {
                    console.log("onComplete")
                    handleHideDrawer();
                    refresh(pagination.current, pagination.pageSize);
                }}
                onClose={handleHideDrawer}
                editText={editText}
                {...drawerProps}
            />
        </>
    )
}

export default EditableTable