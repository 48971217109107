import React, { useEffect, useState } from 'react'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';
import { Select } from 'antd';

function GoodCategorySelect({ ...props }) {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        KodomoRecyclingAPI.user.goodCategories.index({}, 1, 999).then((response) => {
            const results = response.data;
            setCategories(results.data);
        });
    }, []);
    return (
        <Select
            options={categories.map((category) => ({ value: category.id, label: category.name }))}
            {...props}
        />
    )
}

export default GoodCategorySelect