import React, { useCallback, useEffect, useState } from 'react'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';
import { Button, Select } from 'antd';

/**
 * @param {string} type 門市 (store) 或鑑價中心 (appraisal) 
 * @returns 
 */
function StoreSelect({ type = null, onChange, ...props }) {
    const [stores, setStores] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedStoreId, setSelectedStoreId] = useState();

    const handleChange = useCallback((value) => {
        setSelectedStoreId(value);
        onChange && onChange(value)
    }, [onChange]);

    useEffect(() => {
        setLoading(true);
        KodomoRecyclingAPI.user.stores.index({ type: type }, 1, 999).then((response) => {
            const results = response.data;
            setStores(results.data);
            if (results.data.length === 1) {
                handleChange(results.data[0].id);
            }
        }).finally(() => {
            setLoading(false);
        });
    }, [handleChange, onChange, type]);

    return (
        <div style={{ display: 'flex', direction: 'row', columnGap: 5 }}>
            <Select
                style={{ minWidth: 150, flex: 1 }}
                placeholder="請輸入地區名稱搜尋門市"
                loading={loading}
                showSearch
                filterOption={(input, option) =>
                    (option?.name ?? '').includes(input) ||
                    (option?.address ?? '').includes(input)
                }
                filterSort={(optionA, optionB) =>
                    (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                }
                options={stores.map((store) => ({
                    value: store.id,
                    name: store.name,
                    address: store.address,
                    label: <>
                        <div>{store.name}</div>
                        <div style={{ fontSize: '0.8rem' }}>{store.address}</div>
                    </>
                }))}
                onChange={handleChange}
                {...props}
            />
            <Button
                type="primary"
                disabled={!selectedStoreId}
                onClick={() => {
                    const store = stores.find((row) => row.id === selectedStoreId);
                    if (!store) return;
                    console.log("store", store);
                    window.open(`http://maps.google.com/?q=${store.address}`)
                }}>位置</Button>
        </div>
    )
}

export default StoreSelect