import { notification } from "antd";
import axios from "axios";
// import { redirect } from "react-router-dom";
import KodomoRecyclingAPI from "../services/KodomoRecyclingAPI";

axios.defaults.baseURL = 'http://localhost:8091/api';
if (process.env.REACT_APP_URL) {
    axios.defaults.baseURL = process.env.REACT_APP_URL + '/api';
}

// Important: If axios is used with multiple domains, the AUTH_TOKEN will be sent to all of them.
// See below for an example using Custom instance defaults instead.
//axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

export const responseOnFulfilledInterceptor = (response, navigate) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}
export const responseOnRejectedInterceptor = (error, navigate) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log("responseOnRejectedInterceptor", error);
    const statusCode = error?.response?.status;
    console.log("statusCode", statusCode);
    // 401 錯誤不跳錯誤訊息，轉交由呼叫的程式自行處理錯誤
    if (statusCode === 401) {
        KodomoRecyclingAPI.auth.clearLoginData();
        navigate && navigate('/login');
        return Promise.reject(error);
    }
    if (error?.response?.data) {
        const content = error.response.data;
        if (content.message) {
            console.log("error has message", content.message);
            notification.error({
                title: '錯誤',
                message: content.message
            });
        }
        if (content.errors) {
            let errorFields = content.errors;
            console.log("error has validation", content.errors);
            for (let fieldId in errorFields) {
                const errors = errorFields[fieldId];
                if (!Array.isArray(errors)) continue;
                errors.forEach((message) => {
                    notification.error({
                        title: '驗証錯誤',
                        message: message
                    });
                });
            }
        }
    }
    return Promise.reject(error);
}


// axios.interceptors.request.use(async function (config) {
//     return await KodomoRecyclingAPI.auth.checkToken(config);
// });
