import { Button, Result } from 'antd';
import React, { useCallback } from 'react'
const liff = window.liff;
function AnonymousPaymentResultPage() {
    const handleFinish = useCallback(() => {
        if (liff.isInClient()) {
            liff.closeWindow();
        } else {
            window.close();
        }
    }, []);

    return (
        <Result
            status="success"
            title="付款成功"
            subTitle="您的付款已經成功，請按完成關閉分頁並返回"
            extra={[
                <Button type="primary" key="console" onClick={handleFinish}>
                    完成
                </Button>,
            ]}
        />
    )
}

export default AnonymousPaymentResultPage