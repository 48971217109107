import React, { } from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import { DatePicker, Input, Select } from 'antd'
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import EditableTable from '../../components/EditableTable';
import dayjs from 'dayjs';
import AddressSelect from '../../components/AddressSelect';
function AdminMembersPage() {
    const readonly = true;
    const columns = [
        {
            dataIndex: 'id',
            title: 'ID',
        },
        {
            dataIndex: 'name',
            title: '名稱',
            // formComponent: <Input />,
        },
        {
            dataIndex: 'sex',
            title: '姓別',
            formComponent: readonly ? undefined : <Select>
                <Select.Option value={1}>男</Select.Option>
                <Select.Option value={0}>女</Select.Option>
            </Select>,
            render: (value) => value ? (value === 1 ? '男' : '女') : '-',
        },
        {
            dataIndex: 'birthday',
            title: '生日',
            formComponent: readonly ? undefined : <DatePicker />,
        },
        {
            dataIndex: 'email',
            title: '電子信箱',
            formComponent: readonly ? undefined : <Input />,
        },
        {
            dataIndex: 'address',
            title: '地址',
            formComponent: readonly ? undefined : <AddressSelect />,
        },
    ];
    if (!readonly) {
        columns.push(...[
            {
                title: '修改密碼',
                hidden: true,
                formItemName: 'password',
                formComponent: <Input.Password />,
            },
            {
                title: '確認密碼',
                hidden: true,
                formItemName: 'password_confirmation',
                formComponent: <Input.Password />,
            }
        ])
    }
    return (
        <ContentLayout>
            <EditableTable
                name="會員"
                columns={columns}
                onIndex={(query, page, pageSize) => KodomoRecyclingAPI.admin.members.index(query, page, pageSize)}
                readonly={true}
                onShow={(id) => {
                    return KodomoRecyclingAPI.admin.members.show(id).then((response) => {
                        response.data = {
                            ...response.data,
                            birthday: response.data.birthday ? dayjs(response.data.birthday) : null,
                        }
                        return response;
                    });
                }}
            // onStore={(values) => {
            //     return KodomoRecyclingAPI.admin.members.store({
            //         ...values,
            //         birthday: dayjs(values.birthday).format("YYYY-MM-DD"),
            //         address: values.address.fullAddress,
            //         region_id: values.address.regionId,
            //     })
            // }}
            // onUpdate={(id, values) => {
            //     return KodomoRecyclingAPI.admin.members.update(id, {
            //         ...values,
            //         birthday: dayjs(values.birthday).format("YYYY-MM-DD"),
            //         address: values.address.fullAddress,
            //         region_id: values.address.regionId,
            //     });
            // }}
            //onDestroy={(id) => KodomoRecyclingAPI.admin.members.destroy(id)}
            />
        </ContentLayout>
    )
}

export default AdminMembersPage