import { Button, Result, Skeleton } from 'antd';
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
const liff = window.liff;
function PaymentResultPage() {
    const navigate = useNavigate();
    let { caseId } = useParams();
    const [caseData, setCaseData] = useState({ isappraisalfeepaid: true });
    const [loading, setLoading] = useState(false);

    const handleFinish = useCallback(() => {
        if (liff.isInClient()) {
            liff.closeWindow();
        } else {
            navigate(`/user/cases`);
        }
    }, [navigate]);

    useEffect(() => {
        if (!caseId) return;
        setLoading(true);
        KodomoRecyclingAPI.member.cases.show(caseId).then((response) => {
            const result = response.data;
            setCaseData(result);
            setLoading(false);
        });
    }, [caseId]);

    const success = caseData?.isappraisalfeepaid;
    if (!caseId) return (<Result status="error" title="錯誤" subTitle="缺少案件 ID" />);
    return (<>
        {loading ?
            <Skeleton style={{ padding: 20 }} /> :
            <Result
                status={success ? "success" : "error"}
                title={`付款${success ? '成功' : '失敗'}`}
                subTitle={success ? "您的付款已經成功，請等待審核通過" : caseData?.appraisalfeepaidfailreason}
                extra={[
                    <Button type="primary" key="console" onClick={handleFinish}>
                        繼續
                    </Button>,
                ]}
            />}
    </>)
}

export default PaymentResultPage