import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';

const caseStatesAdapter = createEntityAdapter({
    sortComparer: (a, b) => a.id - b.id,
});

export const fetchCaseStates = createAsyncThunk(
    'caseStates/fetchCaseStates',
    async (_, { getState }) => {
        const response = await KodomoRecyclingAPI.user.caseStates.index({});
        return response.data;
    }
)

const caseStatesSlice = createSlice({
    name: 'caseStates',
    initialState: caseStatesAdapter.getInitialState,
    reducers: {


    },
    extraReducers(builder) {
        builder.addCase(fetchCaseStates.fulfilled, (state, action) => {
            // console.log("action.payload", action.payload);
            caseStatesAdapter.setAll(state, action.payload);
        })
    }
});

//export const { } = caseStatesSlice.actions

export default caseStatesSlice.reducer

export const {
    selectAll: selectAllCaseStates,
    selectById: selectCaseStatesById,
} = caseStatesAdapter.getSelectors((state) => state.caseStates)