import { Radio } from 'antd'
import React from 'react'

function DeliveryMethodRadioGroup({ ...props }) {
  return (
    <Radio.Group {...props}>
      <Radio value="self">送至門市</Radio>
      {false && <Radio value="pick-up">到府收件</Radio>}
    </Radio.Group>
  )
}

export default DeliveryMethodRadioGroup