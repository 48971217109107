import React, { useCallback, useEffect, useState } from 'react'
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, List, Row, Space, Tabs, Tag, Timeline } from 'antd'
// import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI'
import './MyCasePage.css';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';

const liff = window.liff;
function CollapsableItem({ children }) {
    const [collapse, setCollapse] = useState(true);
    return <>
        <div style={{ textAlign: 'center', color: '#b8b8b8', cursor: 'pointer' }} onClick={() => setCollapse(!collapse)}>
            <DownOutlined />
        </div>
        <div style={{ overflow: 'hidden', transition: 'max-height 0.3s', maxHeight: collapse ? 0 : 1800 }}>
            {children}
        </div>
    </>
}
function CaseListItem({ item, ...props }) {
    const navigate = useNavigate();
    // console.log("frontStateNames", frontStateNames, filteredFrontStateNames)

    const getIsMyTurn = useCallback((caseState) => {
        const memberRoleId = 5;
        if (caseState?.role_id !== memberRoleId) return false;
        const caseStateId = caseState.id;
        switch (caseStateId) {
            case 17: //待付款
                return {
                    name: '付款',
                    actionRoute: `/user/cases/${item.id}/pay`,
                }
            case 1: // 待送件
                return {
                    name: '修改',
                    actionRoute: `/user/cases/${item.id}/edit`,
                }
            case 3: // 待確認資訊
                return {
                    name: '確認送件資訊',
                    actionRoute: `/user/cases/${item.id}/confirm-delivery`,
                }
            case 8: // 待確認販售
                return {
                    name: '確認是否接受',
                    actionRoute: `/user/cases/${item.id}/confirm-sales`,
                }
            default:
                return false;
        }
    }, [item.id]);
    const isMyTurn = getIsMyTurn(item.casestate);
    return <List.Item {...props}>
        <Row>
            <Col span={16}>
                <div>{dayjs(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
                <div>初審編號: {item.serialno}</div>
                <div>商品編號: {item.deliveryserialno ?? '無'}</div>
            </Col>
            <Col span={8}>
                <Space direction="vertical">
                    <Tag color={isMyTurn ? "#f8a1a1" : "#4edf88"}>
                        {item.casestate.frontname}
                    </Tag>
                    {isMyTurn && <Button size="small" onClick={() => {
                        const actionRoute = isMyTurn.actionRoute;
                        navigate(actionRoute);
                    }}>
                        {isMyTurn.name}
                    </Button>}
                </Space>
            </Col>
            <Col span={24}>
                <CollapsableItem>
                    <Timeline style={{ paddingTop: 10 }} items={item.casestatedetail.map((caseState, index) => ({
                        color: caseState.ispassed ? 'green' : 'gray',
                        children: caseState.frontname,
                        position: 'right'

                    }))} />
                </CollapsableItem>
            </Col>
        </Row>
    </List.Item>
}
function MyCasePage() {
    const navigate = useNavigate();
    const [liffProfile, setLiffProfile] = useState({
        userId: null,
        displayName: null,
        pictureUrl: null,
        statusMessage: null,
    });
    const [cases, setCases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });
    const [total, setTotal] = useState(0);
    const [queryIsFinished, setQueryIsFinished] = useState(false);

    useEffect(() => {
        liff.ready.then(() => {
            liff.getProfile().then((response) => {
                setLiffProfile(response);
            });
        });
    }, []);

    const refresh = useCallback(() => {
        setLoading(true);
        KodomoRecyclingAPI.member.cases.index({
            isfinished: queryIsFinished,
        }, pagination.current, pagination.pageSize).then((response) => {
            const results = response.data;
            setCases(results.data);
            setTotal(results.total);
        }).finally(() => {
            setLoading(false);
        });
    }, [pagination, queryIsFinished]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleSelectTab = useCallback((activeKey) => {
        setQueryIsFinished(activeKey === 'finished' ? 1 : 0);
    }, []);

    return (
        <div style={{ paddingLeft: 5, paddingRight: 5, paddingBottom: 20 }}>
            <Row gutter={[10, 10]} style={{ backgroundColor: '#363636' }}>
                <Col span={24} style={{ textAlign: 'right', paddingTop: 10 }}>
                    <Button shape="round" onClick={() => {
                        navigate('/user/cases/apply');
                    }}>申請</Button>
                </Col>
                <Col span={24} style={{ textAlign: 'center', paddingTop: 20, paddingBottom: 20 }}>
                    <Avatar size={96} style={{ margin: 10 }} icon={<UserOutlined />} src={liffProfile.pictureUrl} />
                    <div style={{ fontSize: '1.2rem', color: 'white' }}>
                        {liffProfile.displayName ?? '不明'}
                    </div>
                </Col>
                <Col span={24} style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8, backgroundColor: 'white' }}>
                    <h3 style={{ textAlign: 'center' }}>我的案件</h3>
                    <Tabs onChange={handleSelectTab} centered items={[
                        {
                            key: 'progressing',
                            label: '進行中',
                            children: <>
                                <List
                                    loading={loading}
                                    itemLayout="vertical"
                                    pagination={{
                                        align: 'center',
                                        onChange: (page, pageSize) => {
                                            setPagination({
                                                current: page,
                                                pageSize: pageSize,
                                            });
                                        },
                                        total: total,
                                    }}
                                    dataSource={cases}
                                    size="large"
                                    renderItem={(item) => <CaseListItem item={item} key={item.serialno} />}

                                />
                            </>
                        },
                        {
                            key: 'finished',
                            label: '已完成',
                            children: <>
                                <List
                                    loading={loading}
                                    size="large"
                                    itemLayout="vertical"
                                    pagination={{
                                        align: 'center',
                                        onChange: (page, pageSize) => {
                                            setPagination({
                                                current: page,
                                                pageSize: pageSize,
                                            });
                                        },
                                        total: total,
                                    }}
                                    dataSource={cases}
                                    renderItem={(item) => <CaseListItem item={item} key={item.serialno} />}
                                />
                            </>
                        }
                    ]} />

                </Col>
            </Row>
        </div>
    )
}

export default MyCasePage