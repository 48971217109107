import React from 'react'

function BuySellAgreement() {
    return (
        <ol>
            <li>本人 (即賣方) 轉讓商品所有相關權利予台灣卡多摩嬰童館股份有限公司 (即買方)，且不得於出售後要求取消、退貨、換貨、退還、折扣、退費與買回。</li>
            <li>賣方同意將商品按表列金額出售予買方，且不會對再次出售提出異議。</li>
            <li>賣方未滿 20 歲，買方謝絕收購。</li>
            <li>買賣同意人姓名需與身份証及帳號戶名一致，若資料不同可能導致日後撥款失敗。</li>
            <li>賣方保證商品皆透過正常管道合法購入或轉讓取得，絕非贓物、非仿冒品或依竊盜等不法方式取得，若商品涉及不法，致發生第三人要求返還等事宜，將自負相關法律責任，包含但不限於買方付出之人力、商譽、律師費等，並須賠償買方因此所發生之損害。</li>
            <li>賣方保證出售之商品皆符合所得稅法第 4 條第 1 項第 16 款免稅項目，係屬出清個人日常用品，非為營利目的。</li>
            <li>賣方保證商品皆為正常使用，絕無發生重大事故損壞，亦無自行拆裝商品主體結構。</li>
            <li>若商品有明顯破損、髒汙或與審查資料有明顯落差致未符合收購標準，買方將會與配合之外部物流業者將商品退還給賣方。</li>
            <li>賣方同意經鑑價完成之後，未簽收退回物品，由買方簽收退回物品之日起兩周內未取得聯繫，即喪失該物品所有權，並由買方處置，事後不得主張任何權利。</li>
            <li>本契約書之解釋、效力及其他未盡事宜，皆以中華民國法律為準則，如有糾紛雙方亦依誠信原則解決，如有訴訟必要，雙方同意以台灣新北地方法院為專屬第一審管轄法院。</li>
        </ol>
    )
}

export default BuySellAgreement