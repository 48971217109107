export class ECPayEnv {
    static Prod = 'Prod';
    static Stage = 'Stage';
}
export default class ECPayLoader {
    static initialized = false;
    static initializing = false;

    static async waitObjectConstruct() {
        return new Promise((resolve, reject) => {
            const handler = setInterval(() => {
                if (window.ECPay) {
                    clearInterval(handler);
                    resolve(true);
                }
            }, 100);
        });
    }

    static async loadScript() {
        return new Promise((resolve, reject) => {
            // Refer to https://developers.ecpay.com.tw/?p=8989
            const scriptSrc = process.env.REACT_APP_ECPAY_ENV === ECPayEnv.Prod ?
                'https://ecpg.ecpay.com.tw/Scripts/sdk-1.0.0.js?t=20210121100116' :
                'https://ecpg-stage.ecpay.com.tw/Scripts/sdk-1.0.0.js?t=20210121100116';

            // Check script src is loaded
            const scripts = document.getElementsByTagName('script');
            for (let i = 0; i < scripts.length; i++) {
                if (scripts[i].src === scriptSrc) {
                    // wait for script loaded
                    this.waitObjectConstruct().then(() => {
                        resolve(true);
                    });
                    return;
                }
            }

            const script = document.createElement('script');
            script.src = scriptSrc;
            script.addEventListener('load', () => {
                console.log("ECPay script loaded");
                this.waitObjectConstruct().then(() => {
                    resolve(true);
                });
            });
            document.body.appendChild(script);
        });
    }
    static async initECPay() {
        return new Promise((resolve, reject) => {
            try {
                window.ECPay.initialize(process.env.REACT_APP_ECPAY_ENV || ECPayEnv.Stage, 0, function (errMsg) {
                    if (errMsg != null) {
                        reject(errMsg);
                        return;
                    }
                    resolve(true);
                });
            } catch (err) {
                console.warn(err);
                reject(err);
            }
        });
    }

    static getECPay() {
        return window.ECPay;
    }

    static async ready() {
        await this.loadScript();
        if (this.initialized) {
            return new Promise((resolve, reject) => { resolve(window.ECPay) });
        }
        if (this.initializing) {
            return new Promise((resolve, reject) => {
                // wait for initialized
                const handler = setInterval(() => {
                    if (this.initialized) {
                        clearInterval(handler);
                        resolve(window.ECPay);
                    }
                }, 100);
            });
        }
        this.initializing = true;
        return new Promise((resolve, reject) => {
            this.initECPay().then(() => {
                this.initialized = true;
                this.initializing = false;
                resolve(window.ECPay);
            }).catch((err) => {
                console.warn(err);
                reject(err);
            });
        });
    }
}