import { Image, Spin } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import CustomMultipleUpload from './CustomMultipleUpload';

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const CustomUploadItem = ({ file, actions, showUploadList }) => {
    // eslint-disable-next-line no-unused-vars
    const { download, preview, remove } = actions;
    const { url, thumbUrl } = file;
    const imageSrc = url || thumbUrl;
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                cursor: showUploadList?.showPreviewIcon ? 'pointer' : 'unset',
            }}
            onClick={showUploadList?.showPreviewIcon ? preview : undefined}
        >
            {imageSrc && (
                <img
                    alt={file.name}
                    style={{ width: "100%", height: "100%", objectFit: 'cover' }}
                    src={imageSrc}
                />
            )}
            {showUploadList?.showRemoveIcon && <div
                style={{
                    width: 20,
                    position: "absolute",
                    right: 0,
                    top: 0,
                    aspectRatio: 1,
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={(event) => {
                    remove();
                    event.stopPropagation();
                }}
            >
                <CloseOutlined />
            </div>}
        </div>
    );
}

function UploadPhotoList({ uploadLimit = 10, onIndex, onStore, onShow, onDestroy }) {
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    // eslint-disable-next-line no-unused-vars
    const [photos, setPhotos] = useState([]);
    const [fileList, setFileList] = useState([
        // {
        //     uid: '-1',
        //     name: 'image.png',
        //     status: 'done',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //     uid: '-xxx',
        //     percent: 50,
        //     name: 'image.png',
        //     status: 'uploading',
        //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        // },
        // {
        //     uid: '-5',
        //     name: 'image.png',
        //     status: 'error',
        // },
    ]);
    const [imagePreviewVisible, setImagePreviewVisible] = useState(false);

    const handlePreview = async (file) => {
        console.log("handlePreview", file, onShow)
        if (onShow) {
            const id = file.response.id;
            setLoading(true);
            return onShow(id).then(async (response) => {
                const result = response.data;
                setPreviewImage(result.filedata);
                setImagePreviewVisible(true);
                return response;
            }).finally(() => {
                setLoading(false);
            });
        }

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };
    const lastRefresh = useRef();

    const handleChange = ({ file, fileList, event }) => {
        //console.log("onChange", file, fileList, event);
        switch (file.status) {
            case 'error':
            case 'success':
            case 'done':
            case 'uploading':
            case 'removed':
            default:
                break;
        }
        setFileList(fileList);
        console.log("onChange: fileList", fileList);
    }

    const handleUpload = ({ file, filename, onProgress, onSuccess, onError, ...other }) => {
        //console.log("handleUpload", file, filename, onProgress, onSuccess, onError, other);
        onProgress({ percent: 50 });
        if (onStore) {
            onStore({}, file).then((response) => {
                //console.log("handleUpload: response", response);
                onSuccess(response.data);
            }).catch((error) => {
                onError(error);
            }).finally(() => {
                //
            });
        } else {
            onSuccess({});
        }
    };
    const handleRemove = (file) => {
        //console.log("handleRemove", file);
        if (!onDestroy || !file.response) {
            return;
        }
        const id = file.response.id;
        setLoading(true);
        return onDestroy(id).then((response) => {
            //console.log(response);
            return response;
        }).finally(() => {
            setLoading(false);
        });
    }

    const refresh = useCallback(() => {
        // Workaround to avoid refresh every change.
        const intervalSecs = (new Date() - lastRefresh.current) / 1000;
        console.log("UploadPhotoList: refresh: skipped.", intervalSecs);
        if (!lastRefresh.current || intervalSecs > 10) {
            setLoading(true);
            onIndex && onIndex().then((response) => {
                const photos = response.data.data;
                const fileList = [];
                photos.forEach((photo) => {
                    fileList.push({
                        uid: photo.id,
                        name: photo.name,
                        description: photo.description,
                        status: 'done',
                        url: photo.filedata, //this.getImageFullUrl(photo.image_file),
                        response: photo,
                    });
                });
                setPhotos(photos);
                setFileList(fileList);
            }).finally(() => {
                setLoading(false);
                lastRefresh.current = new Date();
            })
        }
    }, [onIndex]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                新增
            </div>
        </div>
    );
    const showUploadList = {
        showDownloadIcon: false,
        showRemoveIcon: onDestroy ? true : false,
        showPreviewIcon: true,
    }
    return (
        <Spin spinning={loading}>
            <CustomMultipleUpload
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={onDestroy ? handleRemove : null}
                customRequest={handleUpload}
                itemRender={(originNode, file, fileList, actions) => {
                    //console.log("itemRender: file: ", file, originNode);
                    const { status } = file;
                    if (status === 'error' || status === 'uploading') {
                        return originNode;
                    }
                    return <CustomUploadItem file={file} actions={actions} showUploadList={showUploadList} />
                }}
            >
                {!onStore || fileList.length >= uploadLimit ? null : uploadButton}
            </CustomMultipleUpload>
            {/* <Upload
                accept="image/*"
                listType="picture-card"
                fileList={fileList}
                showUploadList={showUploadList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={onDestroy ? handleRemove : null}
                customRequest={handleUpload}
                itemRender={(originNode, file, fileList, actions) => {
                    //console.log("itemRender: file: ", file, originNode);
                    const { status } = file;
                    if (status === 'error' || status === 'uploading') {
                        return originNode;
                    }
                    return <CustomUploadItem file={file} actions={actions} showUploadList={showUploadList} />
                }}
                multiple={true}
            >
                {!onStore || fileList.length >= 5 ? null : uploadButton}
            </Upload> */}
            <Image
                alt="Preview"
                style={{
                    width: '100%',
                    display: 'none',
                }}
                src={previewImage}
                preview={{
                    visible: imagePreviewVisible,
                    onVisibleChange: (value) => {
                        setImagePreviewVisible(value);
                    },
                }}
            />
        </Spin>
    );
}

export default UploadPhotoList
