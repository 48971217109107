import { DatePicker } from 'antd';
import React from 'react'
import dayjs from 'dayjs';

function FutureDatePicker({ plusDays, ...props }) {
    function disabledDate(current) {
        return current && (current < dayjs().add(plusDays, 'days').endOf('day'));
    }
    return (
        <DatePicker disabledDate={disabledDate} {...props} />
    );
}

export default FutureDatePicker