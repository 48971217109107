import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import { Button, Checkbox, DatePicker, Form, Input, Space, Spin, notification } from 'antd';
import StoreSelect from '../../components/StoreSelect';
import DeliveryMethodRadioGroup from '../../components/DeliveryMethodRadioGroup';
import AddressSelect from '../../components/AddressSelect';
import dayjs from 'dayjs';
import GoodCategorySelect from '../../components/GoodCategorySelect';

const liff = window.liff;

function ConfirmDeliveryPage() {
    const params = useParams();
    const navigate = useNavigate();
    const caseId = Number.parseInt(params.caseId);
    const [loading, setLoading] = useState(false);
    const [confirmDeliveryLoading, setConfirmDeliveryLoading] = useState(false);
    const [form] = Form.useForm();

    const refresh = useCallback(() => {
        if (!caseId) {
            notification.warning({ message: '缺少案件 ID' });
            return;
        }
        setLoading(true);
        KodomoRecyclingAPI.member.cases.show(caseId).then((response) => {
            const result = response.data;
            form.setFieldsValue({
                ...result,
                expectdeliverytime: dayjs(result.expectdeliverytime),
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [caseId, form]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const updateDeliveryInfo = useCallback((values) => {
        if (!caseId) {
            notification.warning({ message: '缺少案件 ID' });
            return;
        }
        setConfirmDeliveryLoading(true);
        KodomoRecyclingAPI.member.cases.confirmDelivery(caseId, {
            ...values,
            expectdeliverytime: values.expectdeliverytime?.toISOString(),
            pickupaddr: values.pickupaddr?.fullAddress,
            region_id: values.pickupaddr?.regionId,
        }).then((response) => {
            notification.info({ message: '修改送件資訊成功' })
            if (liff.isInClient()) {
                liff.closeWindow();
            } else {
                navigate('/user/cases');
            }
        }).finally(() => {
            setConfirmDeliveryLoading(false);
        })
    }, [caseId, navigate]);

    const confirmDelivery = useCallback(() => {
        if (!caseId) {
            notification.warning({ message: '缺少案件 ID' });
            return;
        }
        setConfirmDeliveryLoading(true);
        KodomoRecyclingAPI.member.cases.confirmDelivery(caseId, {}).then((response) => {
            notification.info({ message: '確認送件資訊成功' });
            if (liff.isInClient()) {
                liff.closeWindow();
            } else {
                navigate('/user/cases');
            }
        }).finally(() => {
            setConfirmDeliveryLoading(false);
        })
    }, [caseId, navigate]);

    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < dayjs().endOf('day');
    };
    return (
        <Spin spinning={loading}>
            <div style={{ padding: 20 }}>
                <h2>初審通過，請確認送件資訊</h2>
                <Form form={form} onFinish={updateDeliveryInfo} labelCol={{ span: 4 }}>
                    <Form.Item label="姓名" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('applycontactname')}
                    </Form.Item>
                    <Form.Item label="聯絡電話" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('applycontacttel')}
                    </Form.Item>
                    <Form.Item label="商品類別" shouldUpdate>
                        {({ getFieldValue }) => {
                            const goods = getFieldValue('goods');
                            if (goods?.length) {
                                return <GoodCategorySelect value={goods[0].goodcategory_id} disabled />;
                            }
                            return '無';
                        }}
                    </Form.Item>
                    <Form.Item label="是否為 KODOMO 會員" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('ismember') ? '是' : '否'}
                    </Form.Item>
                    <Form.Item label="是否在 KODOMO 購買" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('isbuyfromhere') ? '是' : '否'}
                    </Form.Item>
                    <Form.Item name="deliverymethod" label="送件方式" rules={[{ required: true }]}>
                        <DeliveryMethodRadioGroup />
                    </Form.Item>
                    <Form.Item dependencies={['deliverymethod']} noStyle>
                        {({ getFieldValue, setFieldValue }) => {
                            const deliveryMethod = getFieldValue('deliverymethod');
                            if (deliveryMethod === "self") {
                                return <>
                                    <h3>選擇門市資訊</h3>
                                    <Form.Item name="deliverystore_id" label="預計送達門市">
                                        <StoreSelect />
                                    </Form.Item>
                                    <Form.Item label="預計送達時間">
                                        <Space>
                                            <Form.Item name="expectdeliverytime" noStyle>
                                                <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
                                            </Form.Item>
                                        </Space>
                                    </Form.Item>
                                </>;
                            }
                            if (deliveryMethod === "pick-up") {
                                return <>
                                    <h3>輸入收件資訊</h3>
                                    <Form.Item label="預計收件時間">
                                        <Space>
                                            <Form.Item name="expectdeliverytime" noStyle>
                                                <DatePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
                                            </Form.Item>
                                        </Space>
                                    </Form.Item>
                                    <Form.Item label="收件地址" name="pickupaddr">
                                        <AddressSelect />
                                    </Form.Item>
                                    <Form.Item name="pickupcontactname" label={<Space>
                                        <div style={{ flex: 1 }}>收件聯絡人</div>
                                        <div>
                                            <Checkbox onChange={(e) => {
                                                if (e.target.checked) {
                                                    setFieldValue('pickupcontactname', getFieldValue('applycontactname'));
                                                    setFieldValue('pickupcontacttel', getFieldValue('applycontacttel'));
                                                } else {
                                                    setFieldValue('pickupcontactname', null);
                                                    setFieldValue('pickupcontacttel', null);
                                                }

                                            }} /> 同上
                                        </div>
                                    </Space>}>
                                        <Input placeholder="請輸入收件聯絡人" />
                                    </Form.Item>
                                    <Form.Item name="pickupcontacttel" label="收件聯繫電話" rules={[
                                        {
                                            pattern: /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/,
                                            message: '請輸入有效的電話號碼，例: 02-29876543 或 0987654321)',
                                        }
                                    ]}>
                                        <Input placeholder="請輸入收件聯繫電話" />
                                    </Form.Item>
                                </>
                            }
                        }}
                    </Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Space>
                            {liff.isInClient() ?
                                [
                                    <Button loading={confirmDeliveryLoading} type="primary" htmlType="submit">修改</Button>,
                                ] :
                                [
                                    <Button loading={confirmDeliveryLoading} htmlType="submit">修改</Button>,
                                    <Button loading={confirmDeliveryLoading} onClick={confirmDelivery} type="primary">確認</Button>,
                                ]
                            }
                        </Space>
                    </div>
                </Form>
            </div>
        </Spin>
    )
}

export default ConfirmDeliveryPage