/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import { Button, Form, Space, Spin, notification } from 'antd';
import dayjs from 'dayjs';

function ConfirmSalesPage() {
    const navigate = useNavigate();
    const params = useParams();
    const caseId = Number.parseInt(params.caseId);
    const [caseData, setCaseData] = useState();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const refresh = useCallback(() => {
        if (!caseId) {
            notification.warning({ message: '缺少案件 ID' });
            return;
        }
        setLoading(true);
        KodomoRecyclingAPI.member.cases.show(caseId).then((response) => {
            const result = response.data;
            form.setFieldsValue({
                ...result,
                expectdeliverytime: dayjs(result.expectdeliverytime),
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [caseId, form]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const salesAccept = useCallback(() => {
        if (!caseId) {
            notification.warning({ message: '缺少案件 ID' });
            return;
        }
        navigate(`/user/cases/${caseId}/sales-accept`);
    }, [caseId, navigate]);

    const salesReject = useCallback(() => {
        if (!caseId) {
            notification.warning({ message: '缺少案件 ID' });
            return;
        }
        navigate(`/user/cases/${caseId}/sales-reject`);
    }, [caseId, navigate]);

    return (
        <Spin spinning={loading}>
            <div style={{ padding: 20 }}>
                <h2>請確認是否接受鑑價結果</h2>
                <Form form={form} layout="vertical">
                    <Form.Item label="初審編號" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('serialno') ?? '-'}
                    </Form.Item>
                    <Form.Item label="商品編號" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('deliveryserialno') ?? '-'}
                    </Form.Item>
                    <Form.Item label="是否為 KODOMO 會員" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('ismember') ? '是' : '否'}
                    </Form.Item>
                    <Form.Item label="是否在 KODOMO 購買" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('isbuyfromhere') ? '是' : '否'}
                    </Form.Item>
                    <Form.Item label="鑑價商品金額" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('totalamount')?.toLocaleString() ?? '-'}
                    </Form.Item>
                    <Form.Item label="預收逆物流費" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('appraisalfee')?.toLocaleString() ?? '-'}
                    </Form.Item>
                    <Form.Item label="會員獎勵紅利點數" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('memberbonus')?.toLocaleString() ?? '-'}
                    </Form.Item>
                    <Form.Item label="商品獎勵紅利點數" shouldUpdate>
                        {({ getFieldValue }) => getFieldValue('buyfromherebonus')?.toLocaleString() ?? '-'}
                    </Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Space>
                            <Button onClick={salesReject}>拒絕</Button>
                            <Button type="primary" onClick={salesAccept}>接受</Button>
                        </Space>
                    </div>
                </Form>
            </div>
        </Spin>
    )
}

export default ConfirmSalesPage