import React, {  } from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import { Input } from 'antd';
import EditableTable from '../../components/EditableTable';

function AdminCategoriesPage() {
    const columns = [
        {
            dataIndex: 'id',
            title: 'ID',
        },
        {
            dataIndex: 'name',
            title: '分類名稱',
            formComponent: <Input />,
        },
    ]
    return (
        <ContentLayout>
            <EditableTable
                name="商品分類"
                columns={columns}
                onIndex={(query, page, pageSize) => KodomoRecyclingAPI.admin.goodCategories.index(query, page, pageSize)}
                onShow={(id) => KodomoRecyclingAPI.admin.goodCategories.show(id)}
                onStore={(values) => KodomoRecyclingAPI.admin.goodCategories.store(values)}
                onUpdate={(id, values) => KodomoRecyclingAPI.admin.goodCategories.update(id, values)}
                onDestroy={(id) => KodomoRecyclingAPI.admin.goodCategories.destroy(id)}
            />
        </ContentLayout>
    )
}

export default AdminCategoriesPage