import React, { useCallback, useState } from 'react'
import { Button, Checkbox, Col, Drawer, Form, Input, Row, Space, Typography, notification } from 'antd'
import StoreSelect from '../../components/StoreSelect'
import { useNavigate, useParams } from 'react-router-dom';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import AppropriateTypeRadioGroup from '../../components/AppropriateTypeRadioGroup';
import BankSelect from '../../components/BankSelect';
import SignaturePad from '../../components/SignaturePad';
import { CloseOutlined } from '@ant-design/icons';
import CredentialUpload from '../../components/CredentialUpload';
import AddressSelect from '../../components/AddressSelect';
import FutureDatePicker from '../../components/FutureDatePicker';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink';
import BuySellAgreement from '../../components/BuySellAgreement';

const liff = window.liff;

function AcceptSalePage() {
    const navigate = useNavigate();
    let { caseId } = useParams();
    const [saleAcceptForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [showTermOfServiceDrawer, setShowTermOfServiceDrawer] = useState({ open: false });
    const [showBuyAgreementDrawer, setBuyAgreementDrawer] = useState({ open: false });

    const handleFinish = useCallback((values) => {
        if (!Number.parseInt(caseId)) {
            notification.warning({ message: '案件不存在，返回案件清單' });
            navigate('/user/cases');
            return;
        }
        setLoading(true);
        console.log("AcceptSalePage: ", values);
        KodomoRecyclingAPI.member.cases.salesAccept(caseId, {
            ...values,
            expectappropriatedate: values.expectappropriatedate?.toISOString(),
            paymentreceiptaddr: values.paymentreceiptaddr?.fullAddress,
        }).then((response) => {
            notification.info({ message: '已接受販售' });
            setTimeout(() => {
                if (liff.isInClient()) {
                    liff.closeWindow();
                } else {
                    navigate('/user/cases');
                }
            }, 500);
        }).catch(() => {
            //
        }).finally(() => {
            setLoading(false);
        })
    }, [caseId, navigate]);

    const handleShowTermOfServiceDrawer = useCallback(() => {
        setShowTermOfServiceDrawer({ open: true });
    }, []);
    const handleHideTermOfServiceDrawer = useCallback(() => {
        setShowTermOfServiceDrawer({ open: false });
    }, []);
    const handleShowBuyAgreementDrawer = useCallback(() => {
        setBuyAgreementDrawer({ open: true });
    }, []);
    const handleHideBuyAgreementDrawer = useCallback(() => {
        setBuyAgreementDrawer({ open: false });
    }, []);
    return (
        <div style={{ padding: 20 }}>
            <h2>接受販售</h2>
            <Form form={saleAcceptForm} onFinish={handleFinish} labelCol={{ span: 4 }}>
                <Form.Item label="撥款方式" name="appropriatetype" rules={[{ required: true }]}>
                    <AppropriateTypeRadioGroup />
                </Form.Item>
                <Form.Item dependencies={['appropriatetype']} noStyle>
                    {({ getFieldValue }) => {
                        return <>
                            {getFieldValue('appropriatetype') === 'cash' && <>
                                <Form.Item label="選擇門市" name="appropriatestore_id" rules={[{ required: true }]}>
                                    <StoreSelect placeholder="請選擇選擇門市" />
                                </Form.Item>
                                <Form.Item label="預計領款日期" name="expectappropriatedate" rules={[{ required: true }]}>
                                    <FutureDatePicker plusDays={3} />
                                </Form.Item>
                                <Form.Item>
                                    <ol>
                                        <li>領款須攜帶身分證核對</li>
                                        <li>親臨門市並於當下折抵消費，享 10% 購物金回饋</li>
                                        <li>購物金限本人使用，無法轉讓、兌換現金及找零，或折換贈品</li>
                                        <li>購物金限折抵商品金額，無法折抵物流費、運費等費用</li>
                                    </ol>
                                </Form.Item>
                            </>}
                            {getFieldValue('appropriatetype') === 'remit' && <>
                                <Form.Item label="匯款戶名" name="remitaccountname" rules={[{ required: true }]}>
                                    <Input placeholder="請輸入匯款戶名" />
                                </Form.Item>
                                <Form.Item label="匯款銀行 (代碼)" name="remitaccountbankcode" rules={[{ required: true }]}>
                                    <BankSelect />
                                </Form.Item>
                                <Form.Item label="匯款帳號" name="remitaccount" rules={[
                                    { required: true },
                                    { pattern: /[0-9]+/, message: '請輸入有效的匯款帳號' }
                                ]}>
                                    <Input placeholder="請輸入匯款帳號" />
                                </Form.Item>
                                {false && <Form.Item label="地址" name="paymentreceiptaddr">
                                    <AddressSelect />
                                </Form.Item>}
                                {false && <Form.Item label="身分證">
                                    <CredentialUpload
                                        text="正面"
                                        onShow={() => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.idcard.show(caseId, 'front')}
                                        onStore={(file) => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.idcard.store(caseId, 'front', file)}
                                        onRemove={() => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.idcard.destroy(caseId, 'front')}
                                    />
                                    <CredentialUpload
                                        text="背面"
                                        onShow={() => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.idcard.show(caseId, 'back')}
                                        onStore={(file) => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.idcard.store(caseId, 'back', file)}
                                        onRemove={() => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.idcard.destroy(caseId, 'back')}
                                    />
                                </Form.Item>}
                                {true && <Form.Item label="存摺封面 (或數位帳戶證明)" name="bankbook">
                                    <CredentialUpload
                                        onShow={() => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.bankbook.show(caseId)}
                                        onStore={(file) => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.bankbook.store(caseId, file)}
                                        onRemove={() => KodomoRecyclingAPI.member.cases.paymentReceiptCredentials.bankbook.destroy(caseId)}
                                    />
                                </Form.Item>}
                                {true && <Form.Item>
                                    <Space>
                                        <Form.Item name="isagreedprivacy" valuePropName="checked" noStyle rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('你必須閱讀並同意個資安全同意書才能繼續'));
                                                },
                                            })
                                        ]}>
                                            <Checkbox />
                                        </Form.Item>
                                        我已閱讀並同意<Typography.Link onClick={handleShowTermOfServiceDrawer}>個人資料授權同意書</Typography.Link>
                                    </Space>
                                </Form.Item>}
                            </>}
                            {getFieldValue('appropriatetype') != null && <>
                                <Form.Item>
                                    <Space>
                                        <Form.Item name="isagreedbuy" valuePropName="checked" noStyle rules={[
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('你必須閱讀並同意買賣同意書才能繼續'));
                                                },
                                            })
                                        ]}>
                                            <Checkbox />
                                        </Form.Item>
                                        我已閱讀並同意<Typography.Link onClick={handleShowBuyAgreementDrawer}>買賣同意書</Typography.Link>
                                    </Space>
                                </Form.Item>
                                <Form.Item label="簽名">
                                    <SignaturePad
                                        onShow={() => KodomoRecyclingAPI.member.cases.paymentReceiptSignature.show(caseId)}
                                        onStore={(file) => KodomoRecyclingAPI.member.cases.paymentReceiptSignature.store(caseId, file)}
                                        onDestroy={() => KodomoRecyclingAPI.member.cases.paymentReceiptSignature.destroy(caseId)}
                                    />
                                </Form.Item>
                            </>}
                        </>
                    }}
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button loading={loading} htmlType="submit" type="primary">送出</Button>
                </Form.Item>
            </Form>
            <Drawer
                open={showTermOfServiceDrawer.open}
                onClose={handleHideTermOfServiceDrawer}
                placement="bottom"
                headerStyle={{ display: 'none' }}
                contentWrapperStyle={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: 'hidden',
                    maxHeight: '80%',
                }}
                size="large"
                bodyStyle={{ display: 'flex', padding: 0 }}
            >
                <Row style={{ flex: 1, flexDirection: 'column' }}>
                    <Col style={{}}>
                        <div>
                            <div style={{ fontSize: 20, position: 'absolute', top: 15, right: 15 }}>
                                <CloseOutlined onClick={handleHideTermOfServiceDrawer} />
                            </div>
                        </div>
                        <h3 style={{ textAlign: 'center' }}>
                            個人資料授權同意書
                        </h3>
                    </Col>
                    <Col style={{ flex: 1, overflowX: 'auto', paddingLeft: 30, paddingRight: 30 }}>
                        <div>
                            <p>本同意書說明台灣卡多摩嬰童館股份有限公司（以下簡稱本公司）將如何處理本表單所蒐集到的個人資料。當您勾選「我同意」並簽署本同意書時，表示您已閱讀、瞭解並同意接受本同意書之所有內容及其後修改變更規定。若您未滿二十歲，應於您的法定代理人閱讀、瞭解並同意本同意書之所有內容及其後修改變更規定後，方得使用本服務，但若您已接受本服務，視為您已取得法定代理人之同意，並遵守以下所有規範。 </p>
                            <h4>一、基本資料之蒐集、更新及保管</h4>
                            <ol>
                                <li>本公司將遵循中華民國「個人資料保護法」與相關法令之規範下取得您的個人資料，並依據本公司【<PrivacyPolicyLink />】，蒐集、處理及利用您的個人資料。 </li>
                                <li>請於申請時提供您本人正確、最新及完整的個人資料。 </li>
                                <li>本公司因執行業務所蒐集您的個人資料。 </li>
                                <li>若您的個人資料有任何異動，請主動向本公司申請更正，使其保持正確、最新及完整。</li>
                                <li>若您提供錯誤、不實、過時或不完整或具誤導性的資料，您將損失相關權益。</li>
                                <li>
                                    您可依中華民國「個人資料保護法」，就您的個人資料行使以下權利：
                                    <ol>
                                        <li>請求查詢或閱覽。</li>
                                        <li>製給複製本。</li>
                                        <li>求補充或更正。</li>
                                        <li>求停止蒐集、處理及利用。</li>
                                        <li>請求刪除。</li>
                                    </ol>
                                    但因本公司執行職務或業務所必須者，本公司得拒絕之。若您欲執行上述權利時，請參考本公司【<PrivacyPolicyLink />】之個人資料保護聯絡窗口聯絡方式與本公司連繫。但因您行使上述權利，而導致權益受損時，本公司將不負相關賠償責任。
                                </li>
                            </ol>
                            <h4>二、蒐集個人資料之目的</h4>
                            <ol>
                                <li>當您的個人資料使用方式與當初本公司蒐集的目的不同時，我們會在使用前先徵求您的書面同意，您可以拒絕向本公司提供個人資料，但您可能因此喪失您的權益。 </li>
                            </ol>
                            <h4>三、基本資料之保密 </h4>
                            <ol>
                                <li>您的個人資料受到本公司【<PrivacyPolicyLink />】之保護及規範。請閱讀【<PrivacyPolicyLink />】以查閱本公司完整【<PrivacyPolicyLink />】。本公司如違反「個人資料保護法」規定或因天災、事變或其他不可抗力所致者，致您的個人資料被竊取、洩漏、竄改、遭其他侵害者，本公司將於查明後以電話、信函、電子郵件或網站公告等方法，擇適當方式通知您。 </li>
                            </ol>
                            <h4>四、 同意書之效力</h4>
                            <ol>
                                <li>當您勾選「我同意」並簽署本同意書時，即表示您已閱讀、瞭解並同意本同意書之所有內容，您如違反下列條款時，本公司得隨時終止對您所提供之所有權益或服務。</li>
                                <li>本公司保留隨時修改本同意書規範之權利，本公司將於修改規範時，於本公司網頁 (站) 公告修改之事實，不另作個別通知。如果您不同意修改的內容，請勿繼續接受本服務。否則將視為您已同意並接受本同意書該等增訂或修改內容之拘束。</li>
                                <li>您自本同意書取得的任何建議或資訊，無論是書面或口頭形式，除非本同意書條款有明確規定，均不構成本同意條款以外之任何保證。</li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Drawer>
            <Drawer
                open={showBuyAgreementDrawer.open}
                onClose={handleHideBuyAgreementDrawer}
                placement="bottom"
                headerStyle={{ display: 'none' }}
                contentWrapperStyle={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: 'hidden',
                    maxHeight: '80%',
                }}
                size="large"
                bodyStyle={{ display: 'flex', padding: 0 }}
            >
                <Row style={{ flex: 1, flexDirection: 'column' }}>
                    <Col style={{}}>
                        <div>
                            <div style={{ fontSize: 20, position: 'absolute', top: 15, right: 15 }}>
                                <CloseOutlined onClick={handleHideBuyAgreementDrawer} />
                            </div>
                        </div>
                        <h3 style={{ textAlign: 'center' }}>
                            買賣同意書
                        </h3>
                    </Col>
                    <Col style={{ flex: 1, overflowX: 'auto', paddingLeft: 30, paddingRight: 30 }}>
                        <div>
                            <BuySellAgreement />
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </div>
    )
}

export default AcceptSalePage
