import { Spin } from 'antd';
import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom';
import BuySellAgreement from './BuySellAgreement';

function BuySellAgreementPortal({ targetDomNode, caseData, onCredentialShow, onBankbookShow, onSignatureShow, onLoaded }) {
    const [credentialFront, setCredentialFront] = useState();
    const [credentialBack, setCredentialBack] = useState();
    const [bankbook, setBankbook] = useState();
    const [signature, setSignature] = useState();
    const [signatureDate, setSignatureDate] = useState();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const handleShowFront = onCredentialShow && caseData?.id ? (params) => onCredentialShow(caseData?.id, 'front', params) : undefined;
        const handleShowBack = onCredentialShow && caseData?.id ? (params) => onCredentialShow(caseData?.id, 'back', params) : undefined;
        const handleShowBankbook = onBankbookShow && caseData?.id ? (params) => onBankbookShow(caseData?.id, params) : undefined;
        const handleShowSignature = onSignatureShow && caseData?.id ? (params) => onSignatureShow(caseData?.id, params) : undefined;

        let promises = [];

        if (handleShowFront) {
            promises.push(handleShowFront().then((response) => {
                console.log("handleShowFront: response", response);
                setCredentialFront(response.data.filepath);
                return response;
            }));
        }
        if (handleShowBack) {
            promises.push(handleShowBack().then((response) => {
                console.log("handleShowBack: response", response);
                setCredentialBack(response.data.filepath);
                return response;
            }));
        }
        if (handleShowBankbook) {
            promises.push(handleShowBankbook().then((response) => {
                console.log("handleShowBankbook: response", response);
                setBankbook(response.data.filepath);
                return response;
            }));
        }
        if (handleShowSignature) {
            promises.push(handleShowSignature().then((response) => {
                console.log("handleShowSignature: response", response);
                setSignature(response.data.filedata);
                setSignatureDate(response.data.created_at);
                return response;
            }));
        }
        setLoading(true);
        Promise.all(promises).then((results) => {
            console.log("Data loaded", results);
            setTimeout(() => {
                onLoaded && onLoaded();
            }, 50);
        }).finally(() => {
            setLoading(false);
        });
    }, [caseData?.id, onBankbookShow, onCredentialShow, onLoaded, onSignatureShow]);

    const numberToChinese = (number) => {
        const chineseNumbers = ['零', '壹', '貳', '參', '肆', '伍', '陸', '柒', '捌', '玖'];
        const unit = {
            1: '',
            10: '拾',
            100: '佰',
            1000: '仟',
            10000: '萬',
            100000000: '億',
        };

        if (!Number.isInteger(number)) {
            return '';
        }
        if (number < 0) {
            return '負' + numberToChinese(-number);
        }
        if (number === 0) {
            return chineseNumbers[0];
        }

        const numStr = number.toString();
        const len = numStr.length;
        let result = '';

        let shouldAddZero = false;
        let shouldAddUnit = false;

        for (let i = 0; i < len; i++) {
            const currentDigit = parseInt(numStr[i], 10);
            const currentDigitCount = len - i - 1;

            // console.log("digit", currentDigit, currentDigitCount);
            if (currentDigit !== 0) {
                if (shouldAddZero) {
                    result += chineseNumbers[0];
                    shouldAddZero = false;
                }
                result += chineseNumbers[currentDigit];
                const currentDigitUnit = unit[Math.pow(10, currentDigitCount)];

                if (currentDigitUnit) {
                    result += currentDigitUnit;
                    // console.log("currentDigitUnit", currentDigitUnit);
                    shouldAddUnit = false;
                } else {
                    // 如果找不到直接對應的單位則用小單位
                    // 找到可用的最大單位
                    let largestUnitDigitCount = 0;
                    for (let j = currentDigitCount; j >= 0; j--) {
                        if (unit[Math.pow(10, j)]) {
                            largestUnitDigitCount = j;
                            break;
                        }
                    }
                    // 扣除可用的最大單位後使用較小的單位來表示 (ex: 90000000 => 玖"仟"萬)
                    const smallerDigitUnit = unit[Math.pow(10, currentDigitCount - largestUnitDigitCount)];
                    result += smallerDigitUnit;
                    // console.log("smallerDigitUnit", smallerDigitUnit);
                    shouldAddUnit = true;
                }
            } else {
                // 若為零時可省略單位只顯示 "零" 代過 (ex: 90000001 => 玖仟萬"零"壹)，除非有用到較小單位來表示，則需要補上單位 (ex: 90000001 => 玖仟"萬"零壹)
                shouldAddZero = true;
                const currentDigitUnit = unit[Math.pow(10, currentDigitCount)];
                if (shouldAddUnit && currentDigitUnit) {
                    // console.log("shouldAddUnitWhenZero", currentDigitUnit);
                    result += currentDigitUnit;
                    shouldAddUnit = false;
                }
            }
        }

        return result;
    }

    // eslint-disable-next-line no-unused-vars
    const testNumberToChinese = () => {
        if (numberToChinese(90000000) !== '玖仟萬') {
            throw Error('numberToChinese(90000000) !== 玖仟萬, got: ' + numberToChinese(90000000));
        }
        if (numberToChinese(90000001) !== '玖仟萬零壹') {
            throw Error('numberToChinese(90000001) !== 玖仟萬零壹, got: ' + numberToChinese(90000001));
        }
        if (numberToChinese(900009001) !== '玖億零玖仟零壹') {
            throw Error('numberToChinese(900009001) !== 玖億零玖仟零壹, got: ' + numberToChinese(900009001));
        }
        if (numberToChinese(900190001) !== '玖億零壹拾玖萬零壹') {
            throw Error('numberToChinese(900190001) !== 玖億零壹拾玖萬零壹, got: ' + numberToChinese(900190001));
        }
        if (numberToChinese(123456789) !== '壹億貳仟參佰肆拾伍萬陸仟柒佰捌拾玖') {
            throw Error('numberToChinese(123456789) !== 壹億貳仟參佰肆拾伍萬陸仟柒佰捌拾玖, got: ' + numberToChinese(123456789));
        }
    };

    const tableBorderStyles = {
        padding: 5,
        border: '1px solid',
    };
    const tableHeaderStyles = {
        textAlign: 'left',
    };
    const content = <Spin spinning={loading}>
        <div style={{ maxWidth: '210mm', margin: 'auto' }}>
            <h1 style={{ clear: 'both' }}>
                買賣同意書
                <div style={{ float: 'right' }}>
                    NO {caseData?.serialno ?? '無'}
                </div>
            </h1>

            <table style={{ width: '100%', borderCollapse: 'collapse', ...tableBorderStyles }}>
                <tbody>
                    <tr style={{ ...tableBorderStyles }}>
                        <th style={{ ...tableBorderStyles, ...tableHeaderStyles, width: 200 }}>姓名</th>
                        <td style={{ ...tableBorderStyles }}>{caseData?.applycontactname ?? '無'}</td>
                    </tr>
                    <tr style={{ ...tableBorderStyles }}>
                        <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>電話</th>
                        <td style={{ ...tableBorderStyles }}>{caseData?.applycontacttel ?? '無'}</td>
                    </tr>
                    <tr style={{ ...tableBorderStyles }}>
                        <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>E-mail</th>
                        <td style={{ ...tableBorderStyles }}>{caseData?.member?.user?.email ?? '無'}</td>
                    </tr>
                    <tr style={{ ...tableBorderStyles }}>
                        <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>商品編號</th>
                        <td style={{ ...tableBorderStyles }}>{caseData?.deliveryserialno ?? '無'}</td>
                    </tr>
                    <tr style={{ ...tableBorderStyles }}>
                        <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>鑑價商品金額</th>
                        <td style={{ ...tableBorderStyles }}>{caseData?.totalamount?.toLocaleString() ?? '無'}</td>
                    </tr>
                    <tr style={{ ...tableBorderStyles }}>
                        <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>核發總點數</th>
                        <td style={{ ...tableBorderStyles }}>{(caseData?.memberbonus + caseData?.buyfromherebonus)?.toLocaleString() ?? '無'}</td>
                    </tr>
                </tbody>
            </table>
            <BuySellAgreement />
            {caseData?.appropriatetype === 'remit' && <>
                <h2>匯款資料</h2>
                <table style={{ width: '100%', borderCollapse: 'collapse', ...tableBorderStyles }}>
                    <tbody>
                        <tr>
                            <th style={{ ...tableBorderStyles, ...tableHeaderStyles, width: 200 }}>戶名</th>
                            <td style={{ ...tableBorderStyles }}>{caseData?.remitaccountname ?? '無'}</td>
                        </tr>
                        <tr>
                            <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>匯款銀行</th>
                            <td style={{ ...tableBorderStyles }}>{caseData?.remitaccountbankcode ?? '無'}</td>
                        </tr>
                        <tr>
                            <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>匯款帳號</th>
                            <td style={{ ...tableBorderStyles }}>{caseData?.remitaccount ?? '無'}</td>
                        </tr>
                        <tr>
                            <th style={{ ...tableBorderStyles, ...tableHeaderStyles }}>地址</th>
                            <td style={{ ...tableBorderStyles }}>{caseData?.paymentreceiptaddr ?? '無'}</td>
                        </tr>
                        <tr>
                            <th style={{ ...tableBorderStyles, ...tableHeaderStyles, verticalAlign: 'top' }}>身份証正反面</th>
                            <td style={{ ...tableBorderStyles }}>
                                {credentialFront && <div>
                                    <img src={credentialFront} alt="身份証正面" style={{ width: 400, objectFit: 'cover', margin: 10 }} />
                                </div>}
                                {credentialBack && <div>
                                    <img src={credentialBack} alt="身份証反面" style={{ width: 400, objectFit: 'cover', margin: 10 }} />
                                </div>}
                            </td>
                        </tr>
                        <tr>
                            <th style={{ ...tableBorderStyles, ...tableHeaderStyles, verticalAlign: 'top' }}>存摺封面</th>
                            <td style={{ ...tableBorderStyles }}>
                                {bankbook && <div>
                                    <img src={bankbook} alt="存摺封面" style={{ objectFit: 'cover', margin: 10 }} />
                                </div>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </>}

            <div style={{ textAlign: 'right' }}>
                <span>簽章</span> <div style={{ display: 'inline-block', verticalAlign: 'middle', height: 100, width: 200 }}>
                    {signature && <div style={{ textAlign: 'center' }}>
                        <img style={{ width: '100%', height: '100%', objectFit: 'contain' }} src={signature} alt="簽章" />
                        <span>{new Date(signatureDate).toLocaleString()}</span>
                    </div>}
                </div>
            </div>
            {caseData?.appropriatetype !== 'remit' && <div style={{ borderTopWidth: 3, borderTopStyle: 'dashed', marginTop: 50, padding: 10, fontSize: 16, fontWeight: 'bold' }}>
                <p>領據</p>
                <p>茲收到 貴公司 商品收購金額 新台幣 {numberToChinese(caseData?.totalamount)}元 整。</p>
                <div style={{ paddingLeft: '10%' }}>
                    <p>
                        此　致<br />
                        台灣卡多摩嬰童館股份有限公司</p>
                    <div style={{ paddingLeft: '40%' }}>
                        <p>
                            賣方姓名：<br />
                            身份證字號：
                        </p>
                    </div>
                </div>
            </div>}
        </div>
    </Spin>;
    return (<>
        {targetDomNode && createPortal(content, targetDomNode)}
    </>)
}

export default BuySellAgreementPortal