import React, { useCallback, useEffect, useState } from 'react'
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI'
import { Alert, Button, Col, Form, Input, Radio, Row, Select, Space, Spin, notification } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import AddressSelect from '../../components/AddressSelect';
import ECPayLoader from '../../utils/ECPayLoader';

const liff = window.liff;

function PaymentPage() {
  const navigate = useNavigate();
  const [caseLoading, setCaseLoading] = useState(false);
  const [caseData, setCaseData] = useState();
  const [createPaymentTokenLoading, setCreatePaymentTokenLoading] = useState(false);
  const [payLoading, setPayLoading] = useState(false);
  const [bindCardLoading, setBindCardLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const [useBindCard, setUseBindCard] = useState(false); // 是否綁定信用卡 (綁定信用卡可不定期不定額扣款)
  // eslint-disable-next-line no-unused-vars
  const [newWindowMode, setNewWindowMode] = useState(false); // 付款畫面是否開新視窗
  // eslint-disable-next-line no-unused-vars
  const [fillHolderInfo, setFillHolderInfo] = useState(false); // 是否填寫持卡人資訊
  const [vendorToken, setVendorToken] = useState(); // 廠商驗證碼
  const [payToken, setPayToken] = useState(); // 付款 Token
  const [startRefreshStatus, setStartRefreshCaseStatus] = useState(false);
  let { caseId } = useParams();

  useEffect(() => {
    if (!caseId) return;
    setCaseLoading(true);
    KodomoRecyclingAPI.member.cases.show(caseId).then((response) => {
      const result = response.data;
      // console.log("caseData", result);
      setCaseData(result);
    }).finally(() => {
      setCaseLoading(false);
    });
  }, [caseId]);

  const handleChangeCreditCardBind = useCallback((e) => {
    setUseBindCard(e.target.value);
  }, []);

  // 建立一般信用卡付款 Token
  const createPaymentToken = useCallback((vendorToken) => {
    // console.log("createPaymentToken", vendorToken);
    if (!vendorToken) {
      setErrorMessage("無法取得付款 Token");
      return;
    }
    setBindCardLoading(true);
    ECPayLoader.ready().then((ECPay) => {
      ECPay.createPayment(vendorToken, ECPay.Language.zhTW, function (errMsg) {
        //console.log('Callback Message: ' + errMsg);
        if (errMsg != null)
          setErrorMessage(errMsg);
      }, 'V2');
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setBindCardLoading(false);
    });
  }, []);

  // 建立綁定信用卡付款 Token
  const createPaymentTokenByBindCard = useCallback((vendorToken) => {
    // console.log("createPaymentTokenByBindCard", vendorToken);

    // ECPay Binding Card
    if (!vendorToken) {
      setErrorMessage("無法取得付款 Token");
      return;
    }
    setBindCardLoading(true);
    ECPayLoader.ready().then((ECPay) => {
      ECPay.addBindingCard(vendorToken, ECPay.Language.zhTW, function (errMsg) {
        //console.log('Callback Message: ' + errMsg);
        if (errMsg != null)
          setErrorMessage(errMsg);
      }, 'V2');
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setBindCardLoading(false);
    });
  }, []);

  // 初始化付款
  const initPayment = useCallback((holderData) => {
    if (!caseData) return;
    if (caseData?.isappraisalfeepaid) {
      notification.warning({ message: '此案件已付款' })
      return;
    }
    setErrorMessage();
    setCreatePaymentTokenLoading(true);
    KodomoRecyclingAPI.member.cases.payByCreditCard(caseData.id, {
      is_bindingcard: useBindCard,
      ...holderData
    }).then((res) => {
      // console.log("payByCreditCard", res);
      const token = res.data.token;
      setVendorToken(token);
      setPayToken();
      if (useBindCard) {
        createPaymentTokenByBindCard(token);
      } else {
        createPaymentToken(token);
      }
    }).catch((err) => {
      const content = err.response?.data;
      if (content?.message) {
        setErrorMessage(content?.message);
      }
      console.log(err);
    }).finally(() => {
      setCreatePaymentTokenLoading(false);
    });
  }, [caseData, useBindCard, createPaymentTokenByBindCard, createPaymentToken]);

  const handleInitPayment = useCallback((values) => {
    const holderData = {
      ...values,
      cardbillingaddress: values.address.fullAddress,
    };
    // console.log("handleInitPayment", values, holderData);
    initPayment(holderData);
  }, [initPayment]);

  useEffect(() => {
    if (!fillHolderInfo) {
      initPayment();
    }
  }, [initPayment, fillHolderInfo]);

  const startRefreshOrderStatus = useCallback(() => {
    setStartRefreshCaseStatus(true);
  }, []);

  useEffect(() => {
    let handler;
    if (startRefreshStatus) {
      handler = setInterval(() => {
        KodomoRecyclingAPI.member.cases.show(caseId).then((response) => {
          const result = response.data;
          if (result.isappraisalfeepaid) {
            navigate('/user/cases/' + caseId + '/pay-result');
          }
        }).catch((err) => {
          console.log(err)
        });
      }, 3000);
    } else {
      clearInterval(handler);
    }
    return () => {
      clearInterval(handler);
    }
  }, [caseId, navigate, startRefreshStatus]);

  const confirmPayByCreditCard = useCallback((payToken) => {
    let resultUrl = null;

    if (liff.isInClient()) { // 是否在 Line LIFF App 中
      const liffAppUrl = 'https://liff.line.me/' + process.env.REACT_APP_LINE_LIFF_ID;
      resultUrl = newWindowMode ?
        liffAppUrl + '/#/pay-result' : // 開新視窗，完成導回匿名頁關閉視窗
        liffAppUrl + '/#/user/cases/' + caseId + '/pay-result'; // 不開新視窗，完成導回 Line LIFF App
    } else {
      const browserUrl = window.location.origin;
      resultUrl = newWindowMode ?
        browserUrl + '/#/pay-result' : // 開新視窗，完成導回匿名頁關閉視窗
        browserUrl + '/#/user/cases/' + caseId + '/pay-result'; // 不開新視窗，完成導回 Web App
    }

    KodomoRecyclingAPI.member.cases.payByCreditCardConfirm(caseId, {
      is_bindingcard: useBindCard,
      paytoken: payToken,
      payresult_url: resultUrl,
    }).then((res) => {
      // console.log("payByCreditCardConfirm", res);
      const redirectUrl = res.data.redirect_url;
      // 如有需要 3D 驗證，會需要導向驗證頁面
      if (redirectUrl) {
        if (!newWindowMode) {
          // 不另開視窗
          window.location.href = redirectUrl;
        } else {
          // 另開驗証視窗
          startRefreshOrderStatus();
          if (liff.isInClient()) {
            liff.openWindow({
              url: redirectUrl,
              external: false,
            });
          } else {
            window.open(redirectUrl, 'kodomo-recycling-payment');
          }
        }
      }
    });
  }, [caseId, newWindowMode, startRefreshOrderStatus, useBindCard]);

  // 進行付款
  const handlePay = useCallback(() => {
    // console.log("resultUrl", resultUrl);
    if (!caseId) return;
    try {
      setPayLoading(true);
      ECPayLoader.ready().then((ECPay) => {
        let getPayTokenFn;
        let getPayTokenRequest;

        if (useBindCard) {
          getPayTokenFn = (paymentInfo) => paymentInfo.BindCardPayToken;
          getPayTokenRequest = ECPay.getBindCardPayToken;
        } else {
          getPayTokenFn = (paymentInfo) => paymentInfo.PayToken;
          getPayTokenRequest = ECPay.getPayToken;
        }
        getPayTokenRequest(function (paymentInfo, errMsg) {
          if (errMsg != null) {
            setErrorMessage(errMsg);
            setPayLoading(false);
            return;
          };
          const payToken = getPayTokenFn(paymentInfo);
          setPayToken(payToken);
          // console.log('PayToken: ', paymentInfo);
          confirmPayByCreditCard(payToken);
        });
      });
    } catch (err) {
      setErrorMessage(err.message);
    } finally {
      setPayLoading(false);
    }
  }, [caseId, useBindCard, confirmPayByCreditCard]);

  const handleReturn = useCallback(() => {
    setTimeout(() => {
      if (liff.isInClient()) {
        liff.closeWindow();
      } else {
        navigate('/user/cases');
      }
    }, 500);
  }, [navigate]);
  return (
    <Spin
      spinning={caseLoading || createPaymentTokenLoading || bindCardLoading || payLoading || startRefreshStatus}
      tip={startRefreshStatus ? "等候付款狀態更新" : undefined}
      size="large"
    >
      <div style={{ padding: 20 }}>
        <h2>預付逆物流費</h2>
        <Row gutter={[10, 10]}>
          <Col span={24}>
            <Form>
              <Form.Item>
                <ul>
                  <li>預付逆物流費用將使用於鑑價後，消費者決定「不販售」之品項，商品將透過「逆物流」宅配至您指定的地址。</li>
                  <li>鑑價後消費者決定「販售」之品項，逆物流費用將於 3 日內退回至預付之信用卡。</li>
                  <li>每件商品預付之逆物流費用為 NT$250 ，兩件為 NT$500 ，以此類推。</li>
                  <li>更多預付說明，請聯繫 KODO-KODO 客服人員。</li>
                </ul>
              </Form.Item>
              <Form.Item label="案件編號">
                {caseData && caseData?.serialno}
              </Form.Item>
              <Form.Item label="預付逆物流費">
                $ {caseData && (caseData?.appraisalfee?.toLocaleString() || '-')}
              </Form.Item>
              {false && <Form.Item>
                <Radio.Group value={useBindCard} onChange={handleChangeCreditCardBind}>
                  <Radio.Button value={false}>一般</Radio.Button>
                  <Radio.Button value={true}>綁定</Radio.Button>
                </Radio.Group>
              </Form.Item>}
            </Form>
          </Col>
          {fillHolderInfo && !vendorToken && <Col span={24}>
            <Form onFinish={handleInitPayment} labelCol={{ span: 4 }}>
              <h3>持卡人資訊</h3>
              <Form.Item label="電子信箱" name="cardholderemail">
                <Input type="email" />
              </Form.Item>
              <Form.Item label="手機號碼" name="cardmobilenumber">
                <Input type="tel" />
              </Form.Item>
              <Form.Item label="姓名" name="cardholdername">
                <Input />
              </Form.Item>
              <Form.Item label="國別" name="cardbillincountrycode">
                <Select options={[
                  { label: '台灣', value: "158" },
                ]} />
              </Form.Item>
              <Form.Item label="帳單地址" name="address">
                <AddressSelect />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Space>
                  <Button type="primary" htmlType="submit">繼續</Button>
                  <Button onClick={handleReturn}>取消</Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>}
          {false && <Col span={24}>
            <p>payToken: {payToken}</p>
            <p>vendorToken: {vendorToken}</p>
          </Col>}
          {vendorToken && <Col span={24}>
            {errorMessage && <Alert message={errorMessage} type="error" showIcon />}
            <div id="ECPayPayment"></div>
            <div style={{ textAlign: 'center' }}>
              <Space>
                <Button type="primary" onClick={handlePay} disabled={caseData?.isappraisalfeepaid || !vendorToken}>付款</Button>
                <Button onClick={handleReturn}>返回</Button>
              </Space>
            </div>
          </Col>}
          {!vendorToken && <Col span={24}>
            <div style={{ textAlign: 'center' }}>
              <Space>
                <Button type="primary" onClick={handleReturn}>返回</Button>
              </Space>
            </div>
          </Col>}
        </Row>
      </div>
    </Spin>
  )
}

export default PaymentPage