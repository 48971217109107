import React, { useCallback, useEffect, useState } from 'react'
import { Button, Checkbox, Col, Drawer, Form, Input, Radio, Row, Space, Typography } from 'antd'
import UploadPhotoList from '../../components/UploadPhotoList'
import { CloseOutlined } from '@ant-design/icons';
import GoodCategorySelect from '../../components/GoodCategorySelect';
import StoreSelect from '../../components/StoreSelect';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import AddressSelect from '../../components/AddressSelect';
import { useNavigate, useParams } from 'react-router-dom';
import DeliveryMethodRadioGroup from '../../components/DeliveryMethodRadioGroup';
import dayjs from 'dayjs';
import FutureDatePicker from '../../components/FutureDatePicker';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink';

const liff = window.liff;

function ApplyCasePage() {
    const params = useParams();
    const navigate = useNavigate();
    const [showTermOfServiceDrawer, setShowTermOfServiceDrawer] = useState({ open: false });
    const [applyForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [caseData, setCaseData] = useState();
    const [editId, setEditId] = useState(null);
    const [mode, setMode] = useState('store');
    const UPLOAD_LIMIT = 10;

    const [liffProfile, setLiffProfile] = useState({
        userId: null,
        displayName: null,
        pictureUrl: null,
        statusMessage: null,
    });

    // const [cases, setCases] = useState([]);
    const refresh = useCallback(() => {
        const caseId = Number.parseInt(params.caseId);
        console.log("caseId", caseId)
        if (caseId) {
            KodomoRecyclingAPI.member.cases.show(params.caseId).then((response) => {
                setMode('update');
                setEditId(caseId);
                const result = response.data;
                applyForm.setFieldsValue({
                    ...result,
                    goodcategory_id: result.goods[0].goodcategory_id,
                    expectdeliverytime: result.expectdeliverytime ? dayjs(result.expectdeliverytime) : null,
                    isagreedterms: true,
                });
                setCaseData(result);
                console.log("caseData", result);
            }).finally(() => {

            });
        } else {
            setMode('store');
            setEditId(null);
            liff.ready.then(() => {
                liff.getProfile().then((response) => {
                    setLiffProfile(response);
                    applyForm.setFieldValue('name', response.displayName);
                });
            });
        }
    }, [applyForm, params.caseId]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleShowTermOfServiceDrawer = useCallback(() => {
        setShowTermOfServiceDrawer({ open: true });
    }, []);
    const handleHideTermOfServiceDrawer = useCallback(() => {
        setShowTermOfServiceDrawer({ open: false });
    }, []);
    const handleFinish = useCallback((values) => {
        console.log("ApplyCasePage: handleFinish: ", values);
        setLoading(true);
        const newValues = {
            ...values,
            expectdeliverytime: values.expectdeliverytime?.toISOString(),
            pickupaddr: values.pickupaddr?.fullAddress,
            region_id: values.pickupaddr?.regionId,
            goods: [
                { goodcategory_id: values.goodcategory_id }
            ],
        }
        if (mode === 'store') {
            KodomoRecyclingAPI.member.cases.store(newValues).then((response) => {
                const result = response.data;
                setCaseData(result);
                setEditId(result.id);
            }).finally(() => {
                setLoading(false);
            });
        } else {
            KodomoRecyclingAPI.member.cases.update(editId, newValues).then((response) => {
                const result = response.data;
                setCaseData(result);
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [editId, mode]);

    const handleApply = useCallback(() => {
        setLoading(true);
        KodomoRecyclingAPI.member.cases.apply(caseData?.id).then((response) => {
            // 資料填寫完直接進行付款作業
            navigate(`/user/cases/${caseData?.id}/pay`);
        }).finally(() => {
            setLoading(false);
        });
    }, [caseData?.id, navigate]);

    // const handlePayment = useCallback(() => {
    //     navigate(`/user/cases/${caseData?.id}/pay`);
    // }, [caseData?.id, navigate]);

    console.log("caseData", caseData, caseData?.id);
    return (
        <div style={{ padding: 20 }}>
            <h2>申請送件</h2>
            <Form
                form={applyForm}
                onFinish={handleFinish}
                labelCol={{ span: 4 }}
                layout="vertical"
                disabled={!!caseData}
                initialValues={{
                    name: liffProfile.displayName,
                }}>
                <Form.Item name="applycontactname" label="真實姓名" rules={[{ required: true }]}>
                    <Input placeholder="請輸入真實姓名" />
                </Form.Item>
                <Form.Item name="applycontacttel" label="聯絡電話" rules={[
                    {
                        required: true,
                    },
                    {
                        pattern: /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/,
                        message: '請輸入有效的電話號碼，例: 02-29876543 或 0987654321)',
                    }
                ]}>
                    <Input placeholder="請輸入聯絡電話，若為卡多摩會員，請輸入會員登記電話" />
                </Form.Item>
                <Form.Item name="goodcategory_id" label="商品類別" rules={[{ required: true }]}>
                    <Form.Item name="goodcategory_id" noStyle>
                        <GoodCategorySelect />
                    </Form.Item>
                    <ul>
                        <li>
                            嬰兒推車開放 7 大品牌<br />
                            ABC DESIGN、APRICA、CHICCO、COMBI、GRACO、JOIE、NUNA</li>
                        <li>
                            汽車座椅開放 9 大品牌 <br />
                            ABC DESIGN、APRICA、CHICCO、COMBI、GRACO、JOIE、NUNA、OSANN、STM
                        </li>
                        <li>
                            商品需為 5 年內商品 (以製造年份為準)
                        </li>
                    </ul>
                </Form.Item>
                <Form.Item name="ismember" label="是否為 KODOMO 會員" rules={[{ required: true }]}>
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="isbuyfromhere" label="是否在 KODOMO 購買" rules={[{ required: true }]}>
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="deliverymethod" label="送件方式" rules={[{ required: true }]}>
                    <DeliveryMethodRadioGroup />
                </Form.Item>
                <Form.Item dependencies={['deliverymethod']} noStyle>
                    {({ getFieldValue, setFieldValue }) => {
                        const deliveryMethod = getFieldValue('deliverymethod');
                        if (deliveryMethod === "self") {
                            return <>
                                <h3>選擇門市資訊</h3>
                                <Form.Item name="deliverystore_id" label="預計送達門市">
                                    <StoreSelect type="store" />
                                </Form.Item>
                                <Form.Item label="預計送達時間">
                                    <Space>
                                        <Form.Item name="expectdeliverytime" noStyle>
                                            <FutureDatePicker plusDays={3} format="YYYY-MM-DD" />
                                        </Form.Item>
                                    </Space>
                                </Form.Item>
                            </>;
                        }
                        if (deliveryMethod === "pick-up") {
                            return <>
                                <h3>輸入收件資訊</h3>
                                <Form.Item label="期望收件時間">
                                    <Space>
                                        <Form.Item name="expectdeliverytime" noStyle>
                                            <FutureDatePicker plusDays={3} format="YYYY-MM-DD" />
                                        </Form.Item>
                                    </Space>
                                </Form.Item>
                                <Form.Item label="收件地址" name="pickupaddr">
                                    <AddressSelect />
                                </Form.Item>
                                <Form.Item name="pickupcontactname" label={<Space>
                                    <div style={{ flex: 1 }}>收件聯絡人</div>
                                    <div>
                                        <Checkbox onChange={(e) => {
                                            if (e.target.checked) {
                                                setFieldValue('pickupcontactname', getFieldValue('applycontactname'));
                                                setFieldValue('pickupcontacttel', getFieldValue('applycontacttel'));
                                            } else {
                                                setFieldValue('pickupcontactname', null);
                                                setFieldValue('pickupcontacttel', null);
                                            }

                                        }} /> 同上
                                    </div>
                                </Space>}>
                                    <Input placeholder="請輸入收件聯絡人" />
                                </Form.Item>
                                <Form.Item name="pickupcontacttel" label="收件聯繫電話" rules={[
                                    {
                                        pattern: /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}|09\d{2}(\d{6}|-\d{3}-\d{3})/,
                                        message: '請輸入有效的電話號碼，例: 02-29876543 或 0987654321)',
                                    }
                                ]}>
                                    <Input placeholder="請輸入收件聯繫電話" />
                                </Form.Item>
                            </>
                        }
                    }}
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Form.Item name="isagreedterms" valuePropName="checked" noStyle rules={[
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('你必須閱讀並同意服務條款才能繼續'));
                                },
                            })
                        ]}>
                            <Checkbox />
                        </Form.Item>
                        我已閱讀並同意<Typography.Link onClick={handleShowTermOfServiceDrawer}>會員服務條款</Typography.Link>
                    </Space>
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Space>
                        <Button
                            htmlType="submit"
                            //style={{ color: 'white', backgroundColor: "#136af3", borderColor: "#136af3" }}
                            loading={loading}
                            disabled={!!caseData}
                        >
                            下一步
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
            {caseData && <div>
                <h3>上傳照片</h3>
                <div style={{ margin: 10 }}>
                    <h4>中文標籤照片</h4>
                    <Typography.Text>
                        請上傳包含製造年份及CNS商品檢驗標識
                    </Typography.Text>
                    <h4>商品現況照片</h4>
                    <Typography.Text>
                        請上傳商品 <span style={{ fontWeight: 'bold' }}>{UPLOAD_LIMIT} 張</span>以上照片，照片完整詳盡越能加速初審時程
                        <ul>
                            <li>嬰兒推車<br />
                                展開前方、展開後方、展開側面、中文標籤 (製造年份及CNS商品檢驗標識)、車體收折、輪子
                            </li>
                            <li>
                                汽車座椅【ISOFIX 固定模式】<br />
                                前方、後方、側面、中文標籤 (製造年份及CNS商品檢驗標識)、ISOFIX、第三點腳架或第三點固定帶
                            </li>
                            <li>
                                汽車座椅【車用安全帶固定模式】<br />
                                前方、後方、側面、中文標籤 (製造年份及CNS商品檢驗標識)
                            </li>
                        </ul>
                    </Typography.Text>
                </div>
                <UploadPhotoList
                    uploadLimit={UPLOAD_LIMIT}
                    onIndex={() => KodomoRecyclingAPI.member.cases.goods.photos.index(caseData?.id, caseData?.goods[0]?.id, {}, 1, 999)}
                    onStore={(data, file) => KodomoRecyclingAPI.member.cases.goods.photos.store(caseData?.id, caseData?.goods[0]?.id, data, file)}
                    onDestroy={(id) => KodomoRecyclingAPI.member.cases.goods.photos.destroy(caseData?.id, caseData?.goods[0]?.id, id)}
                    onShow={(id) => KodomoRecyclingAPI.member.cases.goods.photos.show(caseData?.id, caseData?.goods[0]?.id, id)}
                />
                <div>
                    <p>．初審通過不代表保證收貨．</p>
                    <span style={{ color: 'darkred', fontWeight: 'bold' }}>照片若與實體有重大落差，本公司保留拒絕之權利</span>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={handleApply}>送件</Button>
                </div>
            </div>}
            <Drawer
                open={showTermOfServiceDrawer.open}
                onClose={handleHideTermOfServiceDrawer}
                placement="bottom"
                headerStyle={{ display: 'none' }}
                contentWrapperStyle={{
                    borderTopLeftRadius: 8,
                    borderTopRightRadius: 8,
                    overflow: 'hidden',
                    maxHeight: '80%',
                }}
                size="large"
                bodyStyle={{ display: 'flex', padding: 0 }}
            >
                <Row style={{ flex: 1, flexDirection: 'column' }}>
                    <Col style={{}}>
                        <div>
                            <div style={{ fontSize: 20, position: 'absolute', top: 15, right: 15 }}>
                                <CloseOutlined onClick={handleHideTermOfServiceDrawer} />
                            </div>
                        </div>
                        <h3 style={{ textAlign: 'center' }}>
                            會員服務條款
                        </h3>
                    </Col>
                    <Col style={{ flex: 1, overflowX: 'auto', paddingLeft: 30, paddingRight: 30 }}>
                        <div>
                            <p>親愛的會員您好，非常感謝您使用母嬰商品循環服務，客戶資料及客戶信賴是我們服務的關鍵及重要的資產，為了讓您能夠安心的使用本網站提供的各項服務，本服務將根據以下之會員服務條款(以下簡稱本條款)，提供會員便利的交易。</p>
                            <p>為了保障您的權益，請務必詳閱本條款，在您註冊成為會員或開始使用本服務時，即視為您已經閱讀、瞭解並同意接受本條款及「<PrivacyPolicyLink />」之所有內容，並視為您已確認詳閱並同意個人資料保護告知暨同意事項。</p>
                            <p>如您不同意本條款之全部或部分内容，請勿註冊，並請立即停止使用本服務。本條款之具體約定內容如下：</p>
                            <h4>一、會員資料</h4>
                            <ol>
                                <li>在您使用本服務前，必須提供正確個人資料，並加入成為會員，無須支付任何費用；在加入完成後，使用本服務及參加與本服務相關之活動。如個人資料有異動，請務必即時更新，以保障您的權益。<br />
                                    如因會員未正確更新個人資料，致未能收到本公司發送之會員權益、消費優惠、活動內容等相關資訊，或變更、終止會員權益、消費優惠、活動內容之通知，會員同意在此情形下，視為會員已經收到該等資訊或通知。</li>
                                <li>隱私權保護聲明：會員之個人資料本公司將依「<PrivacyPolicyLink />」為利用與保護。</li>
                                <li>會員使用本服務時，其使用過程中所有的資料記錄，以本服務資料庫所記錄之資料為準，如有任何糾紛，以本服務資料庫所記錄之電子資料為認定標準，但客戶如能提出其他資料並證明為真實者則不在此限。</li>
                            </ol>
                            <h4>二、服務內容的變更</h4>
                            <ol>
                                <li>會員同意本服務隨時調整、變更、修改或終止本服務及本條款，於本服務公告後生效，不再另行個別通知。</li>
                                <li>會員因參與活動及使用本服務，而與本服務所生之權利義務關係，均以本條款最新修訂之版本為準。</li>
                            </ol>
                            <h4>三、個人資料保護告知及暨同意事項</h4>
                            <p>為提供會員最完善的服務，並保護會員個人資料，依個人資料保護法(下稱個資法)規定，告知您如下事項：</p>
                            <ol>
                                <li>蒐集之目的、個人資料類別、利用期間、地區、對象及方式：<br />
                                    為提供會員有關本服務之各項優惠、商品、服務、活動及最新資訊，並有效管理會員資料、進行服務與調查、滿意度及消費統計分析調查 (下稱蒐集目的)，本服務將於上開蒐集目的消失前，在完成上開蒐集目的之必要地區內，蒐集、處理、利用您填載於本服務申請之個人資料、消費與交易資料，或日後經您同意而提供其他個人資料。在上開蒐集目的範圍內，本服務可能將您全部或部分個人資料，提供予合作廠商。<br />
                                    例：當會員使用本服務進行消費，本公司需透過宅配貨運等合作廠商，方能完成貨品或相關贈品之配送，故當會員完成交易，即表示會員同意並授權本公司得將會員提供個人資料(如收件人姓名、配送地址、電話…等)提供予宅配及貨運合作廠商，以利完成貨品或相關贈品之配送。</li>
                                <li>注意事項：<br />
                                    您同意以電子文件作為行使個資法書面同意之方式。如您不同意提供個人資料，或要求刪除或停止集、處理您的個人資料，您瞭解本服務可能因此無法進行會員資格審核及相關處理作業，或提供您完善的網路服務，尚請見諒。 </li>
                            </ol>
                        </div>
                    </Col>
                </Row>
            </Drawer>
        </div>
    )
}

export default ApplyCasePage