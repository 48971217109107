import { Button } from 'antd'
import React from 'react'
import lineIcon from '../images/line/2x/44dp/line_88.png'

function LineLoginButton({ onClick, children, style, ...props }) {
    return (
        <Button type="primary" onClick={onClick} style={{ color: 'white', backgroundColor: '#06C755', border: '0px', width: '100%', ...style }} {...props}>
            <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
                <img alt="Line" style={{ width: 27, height: 27 }} src={lineIcon} />
                <div style={{ flex: 1 }}>{children ? children : '使用 Line 登入'}</div>
            </div>
        </Button>
    )
}

export default LineLoginButton
