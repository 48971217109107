import React, { useState } from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import { Button, Checkbox, Col, Form, Input, Row, notification } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import LineLoginButton from '../../components/LineLoginButton';
import { fetchCaseStates } from '../../reducers/caseStatesSlice';
import { useDispatch } from 'react-redux';

const liff = window.liff;

function LoginPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams,] = useSearchParams();
  const isLogined = KodomoRecyclingAPI.auth.getLoginUser();
  const [loading, setLoading] = useState(false);
  const showLineLogin = true;

  // print the environment in which the LIFF app is running
  const handleLineLogin = () => {
    const redirect = searchParams.get('redirect');
    if (!liff.isLoggedIn()) {
      KodomoRecyclingAPI.auth.lineLogin({ redirect: redirect });
    } else {
      notification.warning({ message: 'Already logined.' });
    }
  }

  const handleNormalLogin = (values) => {
    // console.log(values);
    setLoading(true);
    KodomoRecyclingAPI.auth.login(values.email, values.password, values.remember, values.store_id).then((response) => {
      // notification.info({ message: '登入成功' });
      dispatch(fetchCaseStates());
      if (searchParams.get('redirect')) {
        navigate(searchParams.get('redirect'));
      } else {
        navigate('/');
      }
    }).catch((error) => {
      // nothing to do.
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <ContentLayout>
      {isLogined ? <p>您已登入成功。</p> : <>
        <Row>
          <Col span={24} style={{ textAlign: 'center' }}>
            <h2>登入</h2>
          </Col>
        </Row>
        <Row justify="center">
          <Col xs={24} xl={12}>
            <Row gutter={[10, 10]}>
              {showLineLogin && <>
                <Col span={24}>
                  {/* 客戶有可能用電腦版作業，因此需保留 */}
                  <LineLoginButton onClick={handleLineLogin} />
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                  或
                </Col>
              </>}
              <Col span={24}>
                <Form labelCol={{ span: 6 }} onFinish={handleNormalLogin} initialValues={{ remember: true }}>
                  <Form.Item name="email" label="電子郵件">
                    <Input placeholder="請輸入您的電子郵件位置" />
                  </Form.Item>
                  <Form.Item name="password" label="密碼">
                    <Input.Password placeholder="請輸入您的密碼" />
                  </Form.Item>
                  <Form.Item style={{ marginBottom: 0 }}>
                    <Form.Item name="remember" valuePropName="checked" style={{ display: 'inline-block', width: '50%' }} >
                      <Checkbox>記住登入狀態</Checkbox>
                    </Form.Item>
                    <Form.Item style={{ display: 'inline-block', width: '50%', textAlign: 'right' }} >
                      {false && <Link to="/forgot-password">忘記密碼? </Link>}
                    </Form.Item>
                  </Form.Item>
                  <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ width: '100%' }}>登入</Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
      }
    </ContentLayout>
  )
}


export default LoginPage