import { Select } from 'antd'
import React, { useEffect, useState } from 'react'
import bankCodes from '../data/taiwanBankCodeTT.json';

function BankSelect({ ...props }) {
    const [options, setOptions] = useState([]);
    useEffect(() => {
        const newOptions = bankCodes.map((bank) => ({
            label: `${bank.code} ${bank.name}`,
            value: bank.code,
        }));
        setOptions(newOptions);
    }, []);
    return (
        <Select
            placeholder="請選擇銀行"
            options={options}
            showSearch
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            {...props} />
    )
}

export default BankSelect