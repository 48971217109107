import React, { useCallback, useEffect, useState } from 'react'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';
import { Select } from 'antd';

function MemberSelect({ ...props }) {
    const [loading, setLoading] = useState(false);
    const [members, setMembers] = useState([]);

    const refresh = useCallback(() => {
        setLoading(true);
        KodomoRecyclingAPI.admin.members.index({}, 1, 999).then((response) => {
            const result = response.data;
            setMembers(result.data);
        }).finally(() => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        refresh();
    }, [refresh]);
    return (
        <Select
            placeholder="請選擇會員"
            loading={loading}
            {...props}>
            {members.map((member) => {
                return <Select.Option value={member.id}>{member.name}</Select.Option>
            })}
        </Select>

    )
}

export default MemberSelect