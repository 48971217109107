import { Navigate } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import NotFoundPage from "../pages/NotFoundPage";
import AdminMembersPage from "../pages/admin/AdminMembersPage";
import LoginPage from "../pages/anonymous/LoginPage";
import {
    // UserOutlined,
    // HomeOutlined,
    // BankOutlined,
    // ShopOutlined,
    // CalendarOutlined,
    // CopyOutlined,
    // LoginOutlined,
    // LogoutOutlined,
    // SearchOutlined,
    // ShoppingCartOutlined,
    // ProfileFilled,
    FormOutlined,
    // HighlightFilled,
    // IdcardFilled,
    // BankOutlined,
    UserOutlined,
    // CalendarOutlined,
    // CarFilled,
    // AccountBookOutlined,
    // DollarCircleOutlined,
    // InboxOutlined,
    // ScheduleOutlined,
    // LineChartOutlined,
    // UnorderedListOutlined,
} from "@ant-design/icons";
import PermissionManager from "./PermissionManager";
import ApplyCasePage from "../pages/users/ApplyCasePage";
// import EditCasePage from "../pages/users/EditCasePage";
import RejectSalePage from "../pages/users/RejectSalePage";
import AdminConfigPage from "../pages/admin/AdminConfigPage";
import AdminStoresPage from "../pages/admin/AdminStoresPage";
import AdminEmployeesPage from "../pages/admin/AdminEmployeesPage";
import AdminCasesPage from "../pages/admin/AdminCasesPage";
import MyCasePage from "../pages/users/MyCasePage";
import AdminCategoriesPage from "../pages/admin/AdminCategoriesPage";
import AcceptSalePage from "../pages/users/AcceptSalePage";
import HqCasesPage from "../pages/hq/HqCasesPage";
import AppraiseCasesPage from "../pages/appraise/AppraiseCasesPage";
import StoreCasesPage from "../pages/store/StoreCasesPage";
import ConfirmDeliveryPage from "../pages/users/ConfirmDeliveryPage";
import ConfirmSalesPage from "../pages/users/ConfirmSalesPage";
import UserLayout from "../layouts/UserLayout";
import PaymentPage from "../pages/users/PaymentPage";
import PaymentResultPage from "../pages/users/PaymentResultPage";
import AnonymousPaymentResultPage from "../pages/anonymous/AnonymousPaymentResultPage";

const menuGroups = [
    {
        id: 'admin',
        name: '管理者',
        access: [PermissionManager.ROLE_ADMIN],
    },
    {
        id: 'hq',
        name: '品牌總部',
        access: [PermissionManager.ROLE_SUPPORT, PermissionManager.ROLE_ACCOUNTING],
    },
    {
        id: 'store',
        name: '門市',
        access: [PermissionManager.ROLE_STORE, PermissionManager.ROLE_STORE_MEMBER],
    },
    {
        id: 'appraise',
        name: '鑑價中心',
        access: [PermissionManager.ROLE_STORE, PermissionManager.ROLE_STORE_APPRAISER],
    },
    {
        id: 'member',
        name: '會員',
        access: [PermissionManager.ROLE_MEMBER],
    },
]
const routes = [
    {
        path: "/user",
        element: <UserLayout />,
        access: [PermissionManager.ROLE_MEMBER],
        children: [
            {
                path: "/user",
                element: <Navigate to="/user/cases" />,
            },
            {
                path: "/user/cases",
                element: <MyCasePage />,
                menu: { title: '我的案件', group: 'member', id: 'case-list', icon: <FormOutlined /> },
            },
            {
                path: '/user/cases/apply',
                element: <ApplyCasePage />,
                menu: { title: '案件申請', group: 'member', id: 'case-apply', icon: <FormOutlined /> },
            },
            {
                path: '/user/cases/:caseId/edit',
                element: <ApplyCasePage />,
                //menu: { title: '案件修改', group: 'member', id: 'case-edit', icon: <FormOutlined /> },
            },
            {
                path: '/user/cases/:caseId/pay',
                element: <PaymentPage />,
                // menu: { title: '付款', group: 'member', id: 'case-pay', icon: <FormOutlined /> },
            },
            {
                path: '/user/cases/:caseId/pay-result',
                element: <PaymentResultPage />,
            },
            {
                path: '/user/cases/:caseId/confirm-delivery',
                element: <ConfirmDeliveryPage />,
                //menu: { title: '確認送件資訊', group: 'member', id: 'case-confirm-delivery', icon: <FormOutlined /> },
            },
            {
                path: '/user/cases/:caseId/confirm-sales',
                element: <ConfirmSalesPage />,
                //menu: { title: '確認是否販售', group: 'member', id: 'confirm-sales', icon: <FormOutlined /> },
            },
            {
                path: '/user/cases/:caseId/sales-accept',
                element: <AcceptSalePage />,
                //menu: { title: '接受販售', group: 'member', id: 'case-sales-accept', icon: <FormOutlined /> },
            },
            {
                path: '/user/cases/:caseId/sales-reject',
                element: <RejectSalePage />,
                //menu: { title: '拒絕販售', group: 'member', id: 'case-sales-reject', icon: <FormOutlined /> },
            },
        ]
    },
    {
        path: "/pay-result",
        element: <UserLayout />,
        children: [
            {
                path: '/pay-result',
                element: <AnonymousPaymentResultPage />,
            }
        ]
    },
    {
        path: "/",
        element: <AdminLayout />,
        errorElement: <NotFoundPage />,
        children: [
            {
                path: "/",
                element: <Navigate to="/login" />,
            },
            {
                path: "/login",
                element: <LoginPage />,
            },
            {
                path: "/admin",
                element: <Navigate to="/admin/cases" />,
            },
            {
                path: "/admin/cases",
                element: <AdminCasesPage />,
                menu: { title: '案件管理', group: 'admin', id: 'cases', icon: <UserOutlined /> },
                children: [
                    {
                        path: "/admin/cases/:userId",
                        element: <AdminCasesPage />,
                    },
                ]
            },
            {
                path: "/admin/users",
                element: <AdminMembersPage />,
                menu: { title: '會員管理', group: 'admin', id: 'users', icon: <UserOutlined /> },
                children: [
                    {
                        path: "/admin/users/:userId",
                        element: <AdminMembersPage />,
                    },
                ]
            },
            {
                path: "/admin/employees",
                element: <AdminEmployeesPage />,
                menu: { title: '員工管理', group: 'admin', id: 'employees', icon: <UserOutlined /> },
                children: [
                    {
                        path: "/admin/employees/:employeeId",
                        element: <AdminEmployeesPage />,
                    },
                ]
            },
            {
                path: "/admin/stores",
                element: <AdminStoresPage />,
                menu: { title: '門市管理', group: 'admin', id: 'stores', icon: <UserOutlined /> },
                children: [
                    {
                        path: "/admin/stores/:userId",
                        element: <AdminStoresPage />,
                    },
                ]
            },
            {
                path: "/admin/good-categories",
                element: <AdminCategoriesPage />,
                menu: { title: '商品分類', group: 'admin', id: 'good-categories', icon: <UserOutlined /> },
                children: [
                    {
                        path: "/admin/good-categories/:userId",
                        element: <AdminCategoriesPage />,
                    },
                ]
            },
            {
                path: "/admin/config",
                element: <AdminConfigPage />,
                menu: { title: '系統設定', group: 'admin', id: 'config', icon: <UserOutlined /> },
            },
            {
                path: "/hq/cases",
                element: <HqCasesPage />,
                menu: { title: '案件清單', group: 'hq', id: 'case', icon: <FormOutlined /> },
            },
            {
                path: "/store/cases",
                element: <StoreCasesPage />,
                menu: { title: '案件清單', group: 'store', id: 'case', icon: <FormOutlined /> },
            },
            {
                path: "/appraise/cases",
                element: <AppraiseCasesPage />,
                menu: { title: '案件清單', group: 'appraise', id: 'case', icon: <FormOutlined /> },
            },
        ]
    },
];

export { routes, menuGroups };