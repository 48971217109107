import React from 'react'
import ContentLayout from '../layouts/ContentLayout'
import { Button, Result } from 'antd'
import AdminLayout from '../layouts/AdminLayout';
import { useNavigate, useRouteError } from 'react-router-dom';

function NotFoundPage() {
  const navigate = useNavigate();
  const error = useRouteError();
  console.error(error);
  const path = window.location.pathname + window.location.hash;
  return (
    <AdminLayout>
      <ContentLayout>
        <div style={{ textAlign: 'center' }}>
          <Result status="404" title="您的權限不足或頁面不存在" subTitle={`無法存取頁面 ${path}`} />
          <Button type="primary" onClick={() => navigate('/')}>返回首頁</Button>
        </div>
      </ContentLayout>
    </AdminLayout>
  )
}

export default NotFoundPage