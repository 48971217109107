import { Radio } from 'antd'
import React from 'react'

function AppropriateTypeRadioGroup({ ...props }) {
    return (
        <Radio.Group {...props}>
            <Radio.Button value="cash">親臨門市</Radio.Button>
            <Radio.Button value="remit">匯款</Radio.Button>
        </Radio.Group>
    )
}

export default AppropriateTypeRadioGroup