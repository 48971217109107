import { Select } from 'antd'
import React, { useCallback, useEffect } from 'react'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI'

/**
 * 
 * @param {string} type 門市 (store) 或鑑價中心 (appraisal) 
 * @returns 
 */
function AuthStoreSelect({ type = undefined, onChange, value, ...props }) {
    const loginUser = KodomoRecyclingAPI.auth.getLoginUser();
    const stores = Object.values(loginUser?.storeroles)?.filter((store) => !type || store.type === type);
    const handleChange = useCallback((changedValue) => {
        onChange && onChange(changedValue);
    }, [onChange]);

    useEffect(() => {
        if (!value && stores.length) {
            handleChange(stores[0].id);
        }
    }, [handleChange, stores, value]);
    return (
        <Select style={{ minWidth: 150 }} onChange={handleChange} value={value} {...props}>
            {stores.map((store) => {
                return <Select.Option value={store.id}>{store.name}</Select.Option>
            })}
        </Select>
    )
}

export default AuthStoreSelect