import React, { useCallback, useEffect, useState } from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import { Button, Col, Form, InputNumber, Row, Select, Space, Spin, notification } from 'antd'
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';

function AdminConfigPage() {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const refresh = useCallback(() => {
        setLoading(true);
        KodomoRecyclingAPI.admin.config.index().then((response) => {
            const result = response.data;
            form.setFieldsValue({
                ...result,
                memberbonus_weight: result.memberbonus_weight_unit === 'rate' ? result.memberbonus_weight * 100:result.memberbonus_weight,
                buyfromherebonus_weight: result.buyfromherebonus_weight_unit === 'rate' ? result.buyfromherebonus_weight * 100:result.buyfromherebonus_weight,
            });
        }).finally(() => {
            setLoading(false);
        });
    }, [form]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleFinish = useCallback((values) => {
        setLoading(true);
        KodomoRecyclingAPI.admin.config.store({
            ...values,
            memberbonus_weight:
                values.memberbonus_weight_unit === 'rate' ?
                    values.memberbonus_weight / 100 :
                    values.memberbonus_weight,
            buyfromherebonus_weight:
                values.buyfromherebonus_weight_unit === 'rate' ?
                    values.buyfromherebonus_weight / 100 :
                    values.buyfromherebonus_weight,
        }).then((response) => {
            notification.info({ message: '修改成功' });
        }).finally(() => {
            setLoading(false);
        });
    }, []);
    return (
        <ContentLayout>
            <Spin spinning={loading}>
                <Row>
                    <Col span={24}>
                        <Form form={form} onFinish={handleFinish} labelCol={{ span: 4 }} initialValues={{
                            memberbonus_weight_unit: 'rate',
                            buyfromherebonus_weight_unit: 'rate',
                        }}>
                            <Form.Item label="預收逆物流費" name="appraisalfee_weight">
                                <InputNumber min={0} addonAfter="元" />
                            </Form.Item>
                            <Form.Item label="會員獎勵紅利" shouldUpdate>
                                {({ getFieldValue }) => <>
                                    <Space>
                                        <Form.Item noStyle name="memberbonus_weight">
                                            <InputNumber min={0} max={getFieldValue('memberbonus_weight_unit') === 'rate' ? 100 : 999999} />
                                        </Form.Item>
                                        <Form.Item noStyle name="memberbonus_weight_unit">
                                            <Select style={{ maxWidth: 100 }} options={[
                                                { label: '%', value: 'rate' },
                                                { label: '點', value: 'fixed' },
                                            ]} />
                                        </Form.Item>
                                    </Space>
                                </>}
                            </Form.Item>
                            <Form.Item label="商品獎勵紅利" shouldUpdate>
                                {({ getFieldValue }) => <>
                                    <Space>
                                        <Form.Item noStyle name="buyfromherebonus_weight">
                                            <InputNumber min={0} max={getFieldValue('buyfromherebonus_weight_unit') === 'rate' ? 100 : 999999} />
                                        </Form.Item>
                                        <Form.Item noStyle name="buyfromherebonus_weight_unit">
                                            <Select style={{ maxWidth: 100 }} options={[
                                                { label: '%', value: 'rate' },
                                                { label: '點', value: 'fixed' },
                                            ]} />
                                        </Form.Item>
                                    </Space>
                                </>}

                            </Form.Item>
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Space>
                                    <Button htmlType="reset">重設</Button>
                                    <Button type="primary" htmlType="submit">送出</Button>
                                </Space>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </ContentLayout>
    )
}

export default AdminConfigPage
