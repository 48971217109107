import React from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import { Col, Row } from 'antd'
import CaseEditTable from '../../components/CaseEditTable';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';

function AdminCasesPage() {
    return (
        <ContentLayout>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                </Col>
                <Col span={24}>
                    <CaseEditTable showAllState={true}
                        onIndex={KodomoRecyclingAPI.admin.cases.index}
                        onShow={KodomoRecyclingAPI.admin.cases.show}
                        onPhotoIndex={(caseId, goodId, page, pagesize) => KodomoRecyclingAPI.admin.cases.goods.photos.index(caseId, goodId, {}, page, pagesize)}
                        onPhotoShow={(caseId, goodId, id) => KodomoRecyclingAPI.admin.cases.goods.photos.show(caseId, goodId, id)}
                        onCredentialShow={(caseId, side, params) => KodomoRecyclingAPI.admin.cases.paymentReceiptCredentials.idcard.show(caseId, side, params)}
                        onBankbookShow={(caseId, params) => KodomoRecyclingAPI.admin.cases.paymentReceiptCredentials.bankbook.show(caseId, params)}
                        onSignatureShow={(caseId) => KodomoRecyclingAPI.admin.cases.paymentReceiptSignature.show(caseId)}
                    />
                </Col>
            </Row>
        </ContentLayout>
    )
}

export default AdminCasesPage