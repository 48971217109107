import React, { useCallback, useEffect, useState } from 'react'
import { Image, Upload, notification } from 'antd'
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';

const CustomUploadItem = ({ file, actions, showUploadList }) => {
    // eslint-disable-next-line no-unused-vars
    const { download, preview, remove } = actions;
    const { url, thumbUrl } = file;
    const imageSrc = url || thumbUrl;
    return (
        <div
            style={{
                width: "100%",
                height: "100%",
                position: "relative",
                cursor: showUploadList?.showPreviewIcon ? 'pointer' : 'unset',
            }}
            onClick={showUploadList?.showPreviewIcon ? preview : undefined}
        >
            {imageSrc && (
                <img
                    alt={file.name}
                    style={{ width: "100%", height: "100%", objectFit: 'cover' }}
                    src={imageSrc}
                />
            )}
            {showUploadList?.showRemoveIcon && <div
                style={{
                    width: 20,
                    position: "absolute",
                    right: 0,
                    top: 0,
                    aspectRatio: 1,
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    cursor: "pointer",
                    textAlign: "center",
                }}
                onClick={(event) => {
                    remove();
                    event.stopPropagation();
                }}
            >
                <CloseOutlined />
            </div>}
        </div>
    );
}
function CredentialUpload({ text, onShow, onStore, onRemove }) {
    const [loading, setLoading] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [imagePreviewVisible, setImagePreviewVisible] = useState(false);
    const [fileList, setFileList] = useState([
    ]);

    const handleChange = ({ file, fileList, event }) => {
        console.log("onChange", file, fileList, event);
        switch (file.status) {
            case 'error':
            case 'success':
            case 'done':
            case 'uploading':
            case 'removed':
            default:
                break;
        }
        setFileList(fileList);
    }

    const refresh = useCallback(() => {
        setLoading(true);
        console.log("CredentialUpload: refresh", onShow)
        onShow && onShow().then((response) => {
            const result = response.data;
            console.log("CredentialUpload: refresh: ", result)
            if (result.filepath) {
                const fileList = [];
                fileList.push({
                    //uid: '',
                    name: text,
                    description: text,
                    status: 'done',
                    url: result.filepath,
                    response: result,
                });
                setFileList(fileList);
            }
        }).finally(() => {
            setLoading(false);

        });
    }, [onShow, text]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleUpload = useCallback((callback) => {
        console.log("handleUpload", callback);
        onStore && onStore(callback.file).then((response) => {
            console.log("handleUpload: response", response);
            callback.onSuccess(response.data);
        }).catch((error) => {
            callback.onError(error);
        }).finally(() => {
            //
        })
    }, [onStore]);

    const handleRemove = () => {
        if (onRemove) {
            setLoading(true);
            onRemove().then((response) => {
                console.log(response);
                // setImageUrl();
                notification.info({ message: '刪除成功' });
                return response;
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };
    const handlePreview = async (file) => {
        if (onShow) {
            setLoading(true);
            return onShow({ size: 'original' }).then(async (response) => {
                const result = response.data;
                setPreviewImage(result.filepath);
                setImagePreviewVisible(true);
                return response;
            }).finally(() => {
                setLoading(false);
            });
        }

        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
    };

    const showUploadList = {
        showDownloadIcon: false,
        showRemoveIcon: onRemove ? true : false,
        showPreviewIcon: true,
    }
    return <>
        <Upload
            listType="picture-card"
            customRequest={handleUpload}
            onRemove={handleRemove}
            onChange={handleChange}
            onPreview={handlePreview}
            disabled={!onStore}
            maxCount={1}
            fileList={fileList}
            itemRender={(originNode, file, fileList, actions) => {
                //console.log("itemRender: file: ", file, originNode);
                const { status } = file;
                if (status === 'error' || status === 'uploading') {
                    return originNode;
                }
                return <CustomUploadItem file={file} actions={actions} showUploadList={showUploadList} />
            }}
        >
            {fileList.length < 1 && <div>
                {loading ? <LoadingOutlined /> : <>
                    {onStore ? <>
                        <PlusOutlined />
                        <div style={{ marginTop: 8, }} >
                            上傳{text}
                        </div>
                    </> : '-'}
                </>}
            </div>}
        </Upload>
        <Image
            alt="Preview"
            style={{
                width: '100%',
                display: 'none',
            }}
            src={previewImage}
            preview={{
                loading: loading,
                visible: imagePreviewVisible,
                onVisibleChange: (value) => {
                    setImagePreviewVisible(value);
                },
            }}
        />
    </>
}


export default CredentialUpload