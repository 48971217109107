import React, { useEffect, useState } from 'react'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';
import { Select } from 'antd';

function RoleSelect({ type = undefined, ...props }) {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        KodomoRecyclingAPI.admin.roles.index({ for: type }, 1, 999).then((response) => {
            const results = response.data;
            setRoles(results.data);
        }).finally(() => {
            setLoading(false);
        });
    }, [type]);

    return (
        <Select
            loading={loading}
            mode="multiple"
            options={roles.map((role) => ({ value: role.id, label: role.name }))}
            {...props}
        />
    )
}

export default RoleSelect