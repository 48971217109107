import React from 'react'
import {
    LoginOutlined,
    LogoutOutlined,
    StarOutlined,
} from "@ant-design/icons";
import { Menu as AntdMenu } from 'antd';
import { useNavigate } from 'react-router-dom';
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';
import PermissionManager from '../config/PermissionManager';
import { menuGroups, routes } from '../config/routes';

function Menu({ onClick }) {
    const navigate = useNavigate();
    const isLoggedIn = () => {
        return KodomoRecyclingAPI.auth.getLoginUser();
    };
    const handleLogout = () => {
        if (KodomoRecyclingAPI.auth.getLoginUser()) {
            KodomoRecyclingAPI.auth.logout();
        }
    }
    const itemsByMenuGroup = menuGroups.map((group) => {
        const getMenuItemsFromRoutes = (routes) => {
            const menuItems = [];
            // Flatten routes
            const flattenRoute = (route) => {
                if (route.menu?.title) {
                    menuItems.push(route);
                }
                if (route.children && route.children.length > 0) {
                    for (const child of route.children) {
                        flattenRoute(child);
                    }
                }
            };
            for (const route of routes) {
                flattenRoute(route);
            }
            return menuItems;
        }
        const menuRoutes = getMenuItemsFromRoutes(routes);
        const filteredMenuRoutes = menuRoutes?.filter((item) => item.menu?.group === group.id);
        const menuItems = filteredMenuRoutes.map((route) => ({
            key: `${group.id}-${route.menu?.id}`,
            label: route.menu?.title,
            icon: route.menu?.icon ?? <StarOutlined />,
            onClick: () => {
                console.log(`${group.id}-${route.menu?.id} clicked`, route);
                navigate(route.path);
            }
        }));
        return {
            type: 'group',
            label: group.name,
            key: `g-${group.id}`,
            roles: group.access,
            children: [
                ...menuItems
            ]
        };
    });
    // console.log("itemsByMenuGroup", itemsByMenuGroup);
    const items = [
        {
            label: '登入',
            key: 'anonymous-login',
            hidden: !!isLoggedIn(),
            icon: <LoginOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS],
        },
        ...itemsByMenuGroup,
        {
            label: '登出',
            key: 'anonymous-logout',
            hidden: !isLoggedIn(),
            icon: <LogoutOutlined />,
            roles: [PermissionManager.ROLE_ANONYMOUS],
        },
    ];
    // console.log("items", items);
    const handleClick = ({ item, key, keyPath, domEvent }) => {
        //console.log(domEvent, key, keyPath);
        switch (key) {
            case 'anonymous-rent':
                navigate('/');
                break;
            case 'anonymous-login':
                if (!isLoggedIn()) {
                    navigate('/login');
                }
                break;
            case 'anonymous-logout':
                if (isLoggedIn()) {
                    handleLogout();
                    navigate('/');
                }
                break;
            case 'anonymous-cart':
                navigate('/cart');
                break;
            default:
                break;
        }
        if (onClick) {
            onClick();
        }
    }

    //console.log("Menu", PermissionManager.getRoles());
    const filteredItems = items.filter((item) => {
        if (item.hidden) {
            return false;
        }
        return PermissionManager.getRoles().reduce((pv, role, idx) => {
            return pv || item.roles.indexOf(role) >= 0
        }, false);
    });
    //console.log(filteredItems);
    return (
        <AntdMenu mode="inline" defaultSelectedKeys={["0"]} items={filteredItems} onClick={handleClick} style={{ background: 'transparent' }} />
    )
}

export default Menu
