import React, { } from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import { Input, Tag } from 'antd'
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI'
import EditableTable from '../../components/EditableTable';
import RoleSelect from '../../components/RoleSelect';

function AdminEmployeesPage() {
    const columns = [
        {
            dataIndex: 'id',
            title: 'ID',
        },
        {
            dataIndex: 'title',
            title: '職稱',
            formComponent: <Input />,
        },
        {
            dataIndex: 'account',
            title: '帳號',
        },
        {
            dataIndex: 'name',
            title: '名稱',
            formComponent: <Input />,
        },
        {
            dataIndex: 'cellphone',
            title: '手機',
            formComponent: <Input />,
        },
        {
            dataIndex: 'email',
            title: '電子信箱',
            formComponent: <Input />,
        },
        {
            dataIndex: 'roles',
            title: '身份',
            formComponent: <RoleSelect type="employee" />,
            render: (value, record) => value?.map((role) => <Tag key={role.id}>{role.name}</Tag>)
        },
        {
            title: '修改密碼',
            hidden: true,
            formItemName: 'password',
            formComponent: <Input.Password />,
        },
        {
            title: '確認密碼',
            hidden: true,
            formItemName: 'password_confirmation',
            formComponent: <Input.Password />,
        },
    ]
    return (
        <ContentLayout>
            <EditableTable
                name="員工"
                columns={columns}
                onIndex={(query, page, pageSize) => KodomoRecyclingAPI.admin.employees.index(query, page, pageSize)}
                onShow={(id) => {
                    return KodomoRecyclingAPI.admin.employees.show(id).then((response) => {
                        response.data = {
                            ...response.data,
                            roles: response?.data?.roles?.map((role) => role.id)
                        }
                        return response;
                    });
                }}
                onStore={(values) => {
                    return KodomoRecyclingAPI.admin.employees.store({
                        ...values,
                        roles: values?.roles?.map((roleId) => ({ id: roleId }))
                    });
                }}
                onUpdate={(id, values) => {
                    return KodomoRecyclingAPI.admin.employees.update(id, {
                        ...values,
                        roles: values?.roles?.map((roleId) => ({ id: roleId }))
                    });
                }}
                onDestroy={(id) => KodomoRecyclingAPI.admin.employees.destroy(id)} />
        </ContentLayout>
    )
}

export default AdminEmployeesPage