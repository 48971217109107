import axios from "axios";
import PermissionManager from "../config/PermissionManager";

const liff = window.liff;

const defaultPageSize = 20;

export default class KodomoRecyclingAPI {
    /**
     * 將 Object 轉為 FormData
     * @param {*} data 
     * @returns FormData 
     */
    static toFormData = (data) => {
        var formData = new FormData();
        for (let key in data) {
            formData.append(key, data[key] !== undefined ? data[key] : '');
        }
        return formData;
    }


    /**
     * 將 Data URL 轉為 Blob 檔案
     * @param {string} dataUrl 
     * @returns Blob
     */
    static dataUrlToFile = (dataUrl) => {
        const [contentType, blobBase64] = dataUrl.split(',')
        const blobBin = atob(blobBase64);
        const array = []
        for (let i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i))
        }
        const file = new Blob([new Uint8Array(array)], { type: contentType })
        return file;
    }

    /**
     * 認證
     */
    static auth = class {
        _appLoginedCallback = null; // 登入完成要觸發的事件，通常是 App 元件，讓 App 能夠更新元件登入狀態
        _tokenData = null; // 儲存 token
        _userData = null; // 儲存使用者資料
        static getLoginUser = () => {
            return this._userData;
        };
        static init = (appLoginedCallback) => {
            this._appLoginedCallback = appLoginedCallback;
        }

        static _afterLogin = (response, saveToken) => {
            const token = response.data.token;
            this._tokenData = token;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            saveToken = true; // Force save
            if (saveToken) {
                localStorage.setItem('token', token);
            }
            return this.refreshPermission();
        }
        static refreshPermission = () => {
            return this.me.show().then((response) => {
                PermissionManager.updateRoles(response.data.rolekeys);
                this._appLoginedCallback(true);
            });
        }
        static login = (email, password, remember, storeId) => {
            let data = new FormData();
            let platform = navigator?.userAgentData?.platform || navigator?.platform || 'unknown'
            const detectBrowser = () => {
                if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1) {
                    return 'Opera';
                } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
                    return 'Chrome';
                } else if (navigator.userAgent.indexOf("Safari") !== -1) {
                    return 'Safari';
                } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
                    return 'Firefox';
                } else if ((navigator.userAgent.indexOf("MSIE") !== -1) || (!!document.documentMode === true)) {
                    return 'IE';//crap
                } else {
                    return 'Unknown';
                }
            }
            data.append('email', email);
            data.append('password', password);
            data.append('device_name', [platform, detectBrowser()].join('-'));
            return axios.post(`/auth/login`, data).then((response) => {
                return this._afterLogin(response, remember);
            });

        }
        static loginWithToken = async () => {
            if (this.getLoginUser()) return;
            const savedToken = localStorage.getItem('token');
            //console.log("loginWithToken", savedToken);
            if (savedToken) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + savedToken;
                await this.me.show().then((response) => {
                    this._tokenData = savedToken;
                    PermissionManager.updateRoles(response.data.rolekeys);
                    this._appLoginedCallback(true);
                }).catch((error) => {
                    localStorage.removeItem('token');
                });
            }
        }
        static lineLogin = ({ redirect }) => {
            if (!liff.isLoggedIn()) {
                const redirectUri = process.env.REACT_APP_URL + (redirect ? redirect : '/');
                console.log("KodomoRecyclingAPI: lineLogin: redirectUri: ", redirectUri)
                liff.login({ redirectUri: redirectUri });
            }
        }
        static lineLogout = () => {
            if (liff.isLoggedIn()) {
                liff.logout();
            }
        }
        static verifyLineLogin = () => {
            if (!liff.isLoggedIn()) {
                console.warn("KodomoRecyclingAPI.auth.verifyLineLogin: liff is not logged in")
                return new Promise((resolve, reject) => { reject({ message: 'liff is not logged in' }); });
            }
            const accessToken = liff.getAccessToken();
            const idToken = liff.getDecodedIDToken();
            console.log("verifyLineLogin: LIFF: idToken", idToken);
            console.log("verifyLineLogin: LIFF: User email", idToken.email);
            return axios.post(`/auth/login/line`, {
                email: idToken.email,
                token: accessToken
            }).then((response) => {
                return this._afterLogin(response, true);
            });
        }
        static clearLoginData = () => {
            if (!this._tokenData && !this._userData) return;
            this._tokenData = null;
            this._userData = null;
            localStorage.removeItem('token');
            this._appLoginedCallback(false);
            PermissionManager.updateRoles([]);
            this.lineLogout();
        }
        static logout = () => {
            if (!this._tokenData && !this._userData) return;
            this.clearLoginData();
            return axios.post(`/auth/logout`);
        }
        static me = class {
            static show = () => {
                return axios.get(`/auth/me`).then((response) => {
                    KodomoRecyclingAPI.auth._userData = response.data;
                    //console.log(this._userData);
                    return response;
                });
            }
            static update = (data) => {
                return axios.patch(`/auth/me`, data);
            }
            static updatePassword = (data) => {
                return axios.post(`/auth/me/password`, data);
            }
            static bindLineLogin = (data) => {
                if (!liff.isLoggedIn()) {
                    console.warn("KodomoRecyclingAPI.auth.bindLineLogin: liff is not logged in")
                    return new Promise((resolve, reject) => { reject({ message: 'liff is not logged in' }); });
                }
                const accessToken = liff.getAccessToken();
                return axios.post(`/auth/me/combine-line`, { token: accessToken });
            }
            static unbindLineLogin = () => {
                return axios.delete(`/auth/me/combine-line`).then((response) => {
                    KodomoRecyclingAPI.auth.lineLogout();
                    return response;
                });
            }
            static bindLineNotify = () => {
                return axios.get(`/auth/me/line-notify`);
            }
            static unbindLineNotify = () => {
                return axios.delete(`/auth/me/line-notify`);
            }
            static cancelPendingEmail = () => {
                return axios.delete(`/auth/me/pending-email`);
            }
            static resendPendingEmail = () => {
                return axios.post(`/auth/me/pending-email`);
            }
            static storeMemberAccept = (code) => {
                return axios.post(`/auth/me/store-member-invitation`, { invitecode: code });
            };
        }
        static signup = (data) => {
            return axios.post(`/auth/signup`, data);
        }
        static forgot = (data) => {
            return axios.post(`/auth/forgot`, data);
        }
        static resetPassword = (data) => {
            return axios.post(`/auth/reset-password`, data);
        }

    }
    static admin = class {
        static cases = class {
            static index = (query = {}, page, pageSize) => {
                return axios.get(`/admin/cases`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };

            static show = (id) => axios.get(`/admin/cases/${id}`);
            static store = (data) => axios.post(`/admin/cases`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static update = (id, data) => axios.patch(`/admin/cases/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/cases/${id}`);

            static goods = class {
                static photos = class {
                    static index = (caseId, goodId, query = {}, page, pageSize) => {
                        return axios.get(`/admin/cases/${caseId}/goods/${goodId}/photos`, {
                            params: {
                                ...query,
                                page: page || 1,
                                count: pageSize || defaultPageSize,
                            },
                        });
                    };

                    static show = (caseId, goodId, id, params = {}) => axios.get(`/admin/cases/${caseId}/goods/${goodId}/photos/${id}`, {
                        params: {
                            ...params,
                            size: 'original',
                        }
                    });
                    static store = (caseId, goodId, data, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData(data);
                        formData.append("image", imageFile);
                        return axios.post(`/admin/cases/${caseId}/goods/${goodId}/photos`, formData)
                    };
                    static update = (caseId, goodId, id, data) => axios.patch(`/admin/cases/${caseId}/goods/${goodId}/photos/${id}`, data);
                    static destroy = (caseId, goodId, id) => axios.delete(`/admin/cases/${caseId}/goods/${goodId}/photos/${id}`);
                }
            }

            static paymentReceiptCredentials = class {
                static idcard = class {
                    static store = (caseId, side, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData({
                            side: side,
                        });
                        formData.append("image", imageFile);
                        return axios.post(`/admin/cases/${caseId}/payment-receipt-credentials/id-card`, formData);
                    }
                    static show = (caseId, side, params) => axios.get(`/admin/cases/${caseId}/payment-receipt-credentials/id-card`, {
                        params: {
                            ...params,
                            side: side,
                        }
                    })
                    static destroy = (caseId, side) => axios.delete(`/admin/cases/${caseId}/payment-receipt-credentials/id-card`, {
                        params: {
                            side: side,
                        }
                    });
                }
                static bankbook = class {
                    static store = (caseId, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData({});
                        formData.append("image", imageFile);
                        return axios.post(`/admin/cases/${caseId}/payment-receipt-credentials/bankbook`, formData);
                    }
                    static show = (caseId, params) => axios.get(`/admin/cases/${caseId}/payment-receipt-credentials/bankbook`, {
                        params: {
                            ...params,
                        }
                    })
                    static destroy = (caseId) => axios.delete(`/admin/cases/${caseId}/payment-receipt-credentials/bankbook`);
                }
            }

            static paymentReceiptSignature = class {
                static store = (caseId, imageFile) => {
                    const formData = KodomoRecyclingAPI.toFormData({});
                    formData.append("image", imageFile);
                    return axios.post(`/admin/cases/${caseId}/payment-receipt-signature`, formData);
                }
                static show = (caseId, id) => axios.get(`/admin/cases/${caseId}/payment-receipt-signature`, {
                    params: {
                        size: 'original',
                    }
                })
                static destroy = (caseId, id) => axios.delete(`/admin/cases/${caseId}/payment-receipt-signature`);
            }
        }
        static stores = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/admin/stores`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };

            static show = (id) => axios.get(`/admin/stores/${id}`);
            static store = (data) => axios.post(`/admin/stores`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static update = (id, data) => axios.patch(`/admin/stores/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/stores/${id}`);

            static members = class {
                static index = (storeId, query, page, pageSize) => {
                    if (!query) query = {};
                    return axios.get(`/admin/stores/${storeId}/members`, {
                        params: {
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                        },
                    });
                };

                static show = (storeId, id) => axios.get(`/admin/stores/${storeId}/members/${id}`);
                static store = (storeId, data) => axios.post(`/admin/stores/${storeId}/members`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                static update = (storeId, id, data) => axios.patch(`/admin/stores/${storeId}/members/${id}`, data);
                static destroy = (storeId, id) => axios.delete(`/admin/stores/${storeId}/members/${id}`);
            }
        }
        static roles = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/admin/roles`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };

            static show = (id) => axios.get(`/admin/roles/${id}`);
            static store = (data) => axios.post(`/admin/roles`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static update = (id, data) => axios.patch(`/admin/roles/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/roles/${id}`);
        }
        static employees = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/admin/employees`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };

            static show = (id) => axios.get(`/admin/employees/${id}`);
            static store = (data) => axios.post(`/admin/employees`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static update = (id, data) => axios.patch(`/admin/employees/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/employees/${id}`);
        }
        static members = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/admin/members`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };

            static show = (id) => axios.get(`/admin/members/${id}`);
            static store = (data) => axios.post(`/admin/members`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static update = (id, data) => axios.patch(`/admin/members/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/members/${id}`);
        }
        static goodCategories = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/admin/good-categories`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };

            static show = (id) => axios.get(`/admin/good-categories/${id}`);
            static store = (data) => axios.post(`/admin/good-categories`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static update = (id, data) => axios.patch(`/admin/good-categories/${id}`, data);
            static destroy = (id) => axios.delete(`/admin/good-categories/${id}`);
        }
        static config = class {
            static index = () => {
                return axios.get(`/admin/config`, {
                    params: {
                    },
                });
            };

            static store = (data) => axios.post(`/admin/config`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
    }

    static user = class {
        static regions = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/user/regions`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };
        }
        static stores = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/user/stores`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };
        }
        static goodCategories = class {
            static index = (query, page, pageSize) => {
                if (!query) query = {};
                return axios.get(`/user/good-categories`, {
                    params: {
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };
        }
        static caseStates = class {
            static index = (query) => {
                if (!query) query = {};
                return axios.get(`/user/case-states`, {
                    params: {
                        ...query,
                    },
                });
            };
        }
    }
    static public = class {

    }
    static store = class {
        static stores = class {
            static cases = class {
                static index = (storeId, query = {}, page, pageSize) => {
                    return axios.get(`/store/stores/${storeId}/cases`, {
                        params: {
                            ...query,
                            page: page || 1,
                            count: pageSize || defaultPageSize,
                        },
                    });
                };
                static show = (storeId, id) => axios.get(`/store/stores/${storeId}/cases/${id}`);
                // 鑑價中心
                static confirmGoodsReachedAppraisalStore = (storeId, id, data) => axios.post(`/store/stores/${storeId}/cases/${id}/confirm-goods-reached-appraisal-store`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                static appraise = (storeId, id, data) => axios.post(`/store/stores/${storeId}/cases/${id}/appraise`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                static launchGoods = (storeId, id, data) => axios.post(`/store/stores/${storeId}/cases/${id}/launch-goods`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                static returnGoods = (storeId, id, data) => axios.post(`/store/stores/${storeId}/cases/${id}/return-goods`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                // 門市
                static receiveGoodsFromMember = (storeId, id, data) => axios.post(`/store/stores/${storeId}/cases/${id}/receive-goods-from-member`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                static cash = (storeId, id, data) => axios.post(`/store/stores/${storeId}/cases/${id}/cash`, JSON.stringify(data), {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });

                static goods = class {
                    static photos = class {
                        static index = (storeId, caseId, goodId, query = {}, page, pageSize) => {
                            return axios.get(`/store/stores/${storeId}/cases/${caseId}/goods/${goodId}/photos`, {
                                params: {
                                    ...query,
                                    page: page || 1,
                                    count: pageSize || defaultPageSize,
                                },
                            });
                        };

                        static show = (storeId, caseId, goodId, id, params = {}) => axios.get(`/store/stores/${storeId}/cases/${caseId}/goods/${goodId}/photos/${id}`, {
                            params: {
                                ...params,
                                size: 'original',
                            }
                        });
                        static store = (storeId, caseId, goodId, data, imageFile) => {
                            const formData = KodomoRecyclingAPI.toFormData(data);
                            formData.append("image", imageFile);
                            return axios.post(`/store/stores/${storeId}/cases/${caseId}/goods/${goodId}/photos`, formData)
                        };
                        static update = (storeId, caseId, goodId, id, data) => axios.patch(`/store/stores/${storeId}/cases/${caseId}/goods/${goodId}/photos/${id}`, data);
                        static destroy = (storeId, caseId, goodId, id) => axios.delete(`/store/stores/${storeId}/cases/${caseId}/goods/${goodId}/photos/${id}`);
                    }
                }
            }
        }
    }
    static member = class {
        static cases = class {
            static index = (query = {}, page, pageSize) => {
                return axios.get(`/member/cases`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };
            static store = (data) => axios.post(`/member/cases`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static show = (id) => axios.get(`/member/cases/${id}`);
            static update = (id, data) => axios.patch(`/member/cases/${id}`, data); // TODO missing
            static apply = (id, data) => axios.post(`/member/cases/${id}/apply`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static confirmDelivery = (id, data) => axios.post(`/member/cases/${id}/confirm-delivery`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static salesAccept = (id, data) => axios.post(`/member/cases/${id}/sales-accept`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static salesReject = (id, data) => axios.post(`/member/cases/${id}/sales-reject`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            static payByCreditCard = (id, { is_bindingcard = false, ...data }) => axios.post(`/member/cases/${id}/pay-creditcard`, JSON.stringify({
                is_bindingcard: is_bindingcard,
                ...data
            }), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static payByCreditCardConfirm = (id, { is_bindingcard = false, payresult_url, paytoken, }) => axios.post(`/member/cases/${id}/pay-creditcard/confirm`, JSON.stringify({
                is_bindingcard: is_bindingcard,
                paytoken: paytoken,
                payresult_url: payresult_url,
            }), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            static goods = class {
                static photos = class {
                    static index = (caseId, goodId, query = {}, page, pageSize) => {
                        return axios.get(`/member/cases/${caseId}/goods/${goodId}/photos`, {
                            params: {
                                ...query,
                                page: page || 1,
                                count: pageSize || defaultPageSize,
                            },
                        });
                    };

                    static show = (caseId, goodId, id, params = {}) => axios.get(`/member/cases/${caseId}/goods/${goodId}/photos/${id}`, {
                        params: {
                            ...params,
                            size: 'original',
                        }
                    });
                    static store = (caseId, goodId, data, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData(data);
                        formData.append("image", imageFile);
                        return axios.post(`/member/cases/${caseId}/goods/${goodId}/photos`, formData)
                    };
                    static update = (caseId, goodId, id, data) => axios.patch(`/member/cases/${caseId}/goods/${goodId}/photos/${id}`, data);
                    static destroy = (caseId, goodId, id) => axios.delete(`/member/cases/${caseId}/goods/${goodId}/photos/${id}`);
                }
            }

            static paymentReceiptCredentials = class {
                static idcard = class {
                    static store = (caseId, side, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData({
                            side: side,
                        });
                        formData.append("image", imageFile);
                        return axios.post(`/member/cases/${caseId}/payment-receipt-credentials/id-card`, formData);
                    }
                    static show = (caseId, side, params) => axios.get(`/member/cases/${caseId}/payment-receipt-credentials/id-card`, {
                        params: {
                            ...params,
                            side: side,
                        }
                    })
                    static destroy = (caseId, side) => axios.delete(`/member/cases/${caseId}/payment-receipt-credentials/id-card`, {
                        params: {
                            side: side,
                        }
                    });
                }
                static bankbook = class {
                    static store = (caseId, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData({});
                        formData.append("image", imageFile);
                        return axios.post(`/member/cases/${caseId}/payment-receipt-credentials/bankbook`, formData);
                    }
                    static show = (caseId, params) => axios.get(`/member/cases/${caseId}/payment-receipt-credentials/bankbook`, {
                        params: {
                            ...params,
                        }
                    })
                    static destroy = (caseId) => axios.delete(`/member/cases/${caseId}/payment-receipt-credentials/bankbook`);
                }
            }

            static paymentReceiptSignature = class {
                static store = (caseId, imageFile) => {
                    const formData = KodomoRecyclingAPI.toFormData({});
                    formData.append("image", imageFile);
                    return axios.post(`/member/cases/${caseId}/payment-receipt-signature`, formData);
                }
                static show = (caseId, id) => axios.get(`/member/cases/${caseId}/payment-receipt-signature`, {
                    params: {
                        size: 'original',
                    }
                })
                static destroy = (caseId, id) => axios.delete(`/member/cases/${caseId}/payment-receipt-signature`);
            }
        }
    }

    static employee = class {
        static cases = class {
            static index = (query = {}, page, pageSize) => {
                return axios.get(`/employee/cases`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };

            static show = (id) => axios.get(`/employee/cases/${id}`);
            static store = (data) => axios.post(`/employee/cases`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static update = (id, data) => axios.patch(`/employee/cases/${id}`, data);
            static destroy = (id) => axios.delete(`/employee/cases/${id}`);

            static goods = class {
                static photos = class {
                    static index = (caseId, goodId, query = {}, page, pageSize) => {
                        return axios.get(`/employee/cases/${caseId}/goods/${goodId}/photos`, {
                            params: {
                                ...query,
                                page: page || 1,
                                count: pageSize || defaultPageSize,
                            },
                        });
                    };

                    static show = (caseId, goodId, id, params = {}) => axios.get(`/employee/cases/${caseId}/goods/${goodId}/photos/${id}`, {
                        params: {
                            ...params,
                            size: 'original',
                        }
                    });
                    static store = (caseId, goodId, data, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData(data);
                        formData.append("image", imageFile);
                        return axios.post(`/employee/cases/${caseId}/goods/${goodId}/photos`, formData)
                    };
                    static update = (caseId, goodId, id, data) => axios.patch(`/employee/cases/${caseId}/goods/${goodId}/photos/${id}`, data);
                    static destroy = (caseId, goodId, id) => axios.delete(`/employee/cases/${caseId}/goods/${goodId}/photos/${id}`);
                }
            }
        }
    }
    static support = class {
        static cases = class {
            static index = (query = {}, page, pageSize) => {
                return axios.get(`/support/cases`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };
            static show = (id) => axios.get(`/support/cases/${id}`);
            static approve = (id, data) => axios.post(`/support/cases/${id}/approve`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static receiveGoodsFromLogistics = (id, data) => axios.post(`/support/cases/${id}/receive-goods-from-logistics`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static issueRecycleBonus = (id, data) => axios.post(`/support/cases/${id}/issue-recyclebonus`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
        }
    }
    static accounting = class {
        static cases = class {
            static index = (query = {}, page, pageSize) => {
                return axios.get(`/accounting/cases`, {
                    params: {
                        ...query,
                        page: page || 1,
                        count: pageSize || defaultPageSize,
                    },
                });
            };
            static show = (id) => axios.get(`/accounting/cases/${id}`);
            static confirmRemittanceAndRecycleBonus = (id, data) => axios.post(`/accounting/cases/${id}/confirm-remittance-and-recyclebonus`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            static remit = (id, data) => axios.post(`/accounting/cases/${id}/remit`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            static paymentReceiptCredentials = class {
                static idcard = class {
                    static store = (caseId, side, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData({
                            side: side,
                        });
                        formData.append("image", imageFile);
                        return axios.post(`/accounting/cases/${caseId}/payment-receipt-credentials/id-card`, formData);
                    }
                    static show = (caseId, side, params) => axios.get(`/accounting/cases/${caseId}/payment-receipt-credentials/id-card`, {
                        params: {
                            ...params,
                            side: side,
                        }
                    })
                    static destroy = (caseId, side) => axios.delete(`/accounting/cases/${caseId}/payment-receipt-credentials/id-card`, {
                        params: {
                            side: side,
                        }
                    });
                }
                static bankbook = class {
                    static store = (caseId, imageFile) => {
                        const formData = KodomoRecyclingAPI.toFormData({});
                        formData.append("image", imageFile);
                        return axios.post(`/accounting/cases/${caseId}/payment-receipt-credentials/bankbook`, formData);
                    }
                    static show = (caseId, params) => axios.get(`/accounting/cases/${caseId}/payment-receipt-credentials/bankbook`, {
                        params: {
                            ...params,
                        }
                    })
                    static destroy = (caseId) => axios.delete(`/accounting/cases/${caseId}/payment-receipt-credentials/bankbook`);
                }
            }

            static paymentReceiptSignature = class {
                static store = (caseId, imageFile) => {
                    const formData = KodomoRecyclingAPI.toFormData({});
                    formData.append("image", imageFile);
                    return axios.post(`/accounting/cases/${caseId}/payment-receipt-signature`, formData);
                }
                static show = (caseId, id) => axios.get(`/accounting/cases/${caseId}/payment-receipt-signature`, {
                    params: {
                        size: 'original',
                    }
                })
                static destroy = (caseId, id) => axios.delete(`/accounting/cases/${caseId}/payment-receipt-signature`);
            }
        }
    }
}