import React, { useCallback, useState } from 'react'
import ContentLayout from '../../layouts/ContentLayout'
import { Col, Drawer, Form, Input, Row, Select } from 'antd'
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';
import RegionSelect from '../../components/RegionSelect';
import EditableTable from '../../components/EditableTable';
import EmployeeSelect from '../../components/EmployeeSelect';
import AddressSelect from '../../components/AddressSelect';

function AdminStoresPage() {
    const [memberDrawer, setMemberDrawer] = useState({ open: false, storeId: null });
    const [selectedType, setSelectedType] = useState('all');

    const handleShowMemberDrawer = useCallback((storeId) => {
        setMemberDrawer({ open: true, storeId: storeId });
    }, []);

    const handleHideMemberDrawer = useCallback(() => {
        setMemberDrawer({ open: false, storeId: null });
    }, []);

    const columns = [
        {
            dataIndex: 'serialno',
            title: 'ID',
        },
        {
            dataIndex: 'name',
            title: '門市名稱',
            formComponent: <Input />,
        },
        {
            dataIndex: 'type',
            title: '門市類型',
            render: (value) => value === 'store' ? '門市' : '鑑價中心',
            formComponent: <Select>
                <Select.Option value="store">門市</Select.Option>
                <Select.Option value="appraisal">鑑價中心</Select.Option>
            </Select>,
        },
        {
            dataIndex: 'taxidnumber',
            title: '統一編號',
            formComponent: <Input />,
        },
        {
            dataIndex: 'contacttel',
            title: '電話',
            formComponent: <Input />,
        },
        {
            dataIndex: 'region_id',
            title: '地區',
            hidden: true,
            formComponent: <RegionSelect />,

        },
        {
            dataIndex: 'address',
            title: '地址',
            hidden: true,
            formComponent: <AddressSelect />,
        },
    ];

    const handleTypeChange = useCallback((value) => {
        setSelectedType(value);
    }, []);

    const handleIndex = useCallback((query, page, pageSize) => {
        if (!query) query = {};
        if (selectedType !== 'all') {
            query.type = selectedType;
        }
        return KodomoRecyclingAPI.admin.stores.index(query, page, pageSize)
    }, [selectedType]);
    return (
        <ContentLayout>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                    <Form>
                        <Form.Item label="門市類型" name="type">
                            <Select
                                value={selectedType}
                                options={[
                                    {
                                        label: '全部',
                                        value: 'all',
                                    },
                                    {
                                        label: '門市',
                                        value: 'store',
                                    },
                                    {
                                        label: '鑑價中心',
                                        value: 'appraisal',
                                    }
                                ]}
                                onChange={handleTypeChange}
                            />
                        </Form.Item>
                    </Form>
                </Col>
                <Col span={24}>
                    <EditableTable
                        name="門市"
                        columns={columns}
                        rowKey="id"
                        onIndex={handleIndex}
                        onDestroy={(id) => KodomoRecyclingAPI.admin.stores.destroy(id)}
                        onShow={(id) => KodomoRecyclingAPI.admin.stores.show(id)}
                        onStore={(values) => KodomoRecyclingAPI.admin.stores.store({
                            ...values,
                            address: values.address?.fullAddress,
                            region_id: values.address.regionId,
                        })}
                        onUpdate={(id, values) => KodomoRecyclingAPI.admin.stores.update(id, {
                            ...values,
                            address: values.address?.fullAddress,
                            region_id: values.address.regionId,
                        })}
                        extraActions={[
                            {
                                name: '成員',
                                onClick: (store) => handleShowMemberDrawer(store.id)
                            }
                        ]}
                    />
                </Col>
            </Row>
            <Drawer title="店家成員" open={memberDrawer.open} onClose={handleHideMemberDrawer} size="large">
                <EditableTable
                    name="店家成員"
                    columns={[
                        {
                            dataIndex: 'employee_id',
                            title: '員工',
                            formComponent: <EmployeeSelect />,
                            render: (value, record) => `${record?.employee?.user?.name ?? '-'} (${record?.employee?.user?.email ?? '-'})`,
                        },
                        {
                            dataIndex: 'storeroles',
                            title: '身分',
                            formComponent: <Select>
                                <Select.Option value="store_manager">店長</Select.Option>
                                <Select.Option value="store_member">成員</Select.Option>
                            </Select>,
                            render: (value, record) => value === "store_manager" ? '店長' : '店員',
                        },
                    ]}
                    rowKey="employee_id"
                    onIndex={memberDrawer.storeId ? (query, page, pageSize) => KodomoRecyclingAPI.admin.stores.members.index(memberDrawer.storeId, query, page, pageSize) : null}
                    onShow={(id) => KodomoRecyclingAPI.admin.stores.members.show(memberDrawer.storeId, id)}
                    onStore={(values) => KodomoRecyclingAPI.admin.stores.members.store(memberDrawer.storeId, values)}
                    onUpdate={(id, values) => KodomoRecyclingAPI.admin.stores.members.update(memberDrawer.storeId, id, values)}
                    onDestroy={(id) => KodomoRecyclingAPI.admin.stores.members.destroy(memberDrawer.storeId, id)}
                />
            </Drawer>
        </ContentLayout>
    )
}

export default AdminStoresPage