import React, { useEffect, useState } from 'react'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';
import { Select } from 'antd';

function EmployeeSelect({ ...props }) {
    const [employees, setEmployees] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        KodomoRecyclingAPI.admin.employees.index({}, 1, 999).then((response) => {
            const result = response.data;
            setEmployees(result.data);
        }).finally(() => {
            setLoading(false);
        })
    }, []);
    return (
        <Select
            showSearch
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            loading={loading}
            options={employees.map((employee) => ({ value: employee.id, label: `${employee.name} - ${employee.email}` }))}
            {...props} />
    )
}

export default EmployeeSelect