import { Col, Input, Row } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import RegionSelect from './RegionSelect'

function AddressSelect({ value = {}, onChange, style }) {
    const [cityData, setCityData] = useState();
    const [regionData, setRegionData] = useState();
    const [addr, setAddr] = useState();

    const parseAddress = useCallback(() => {
        if (typeof value === 'string') {
            function parseAddress(address) {
                const regex = /(?<city>[^縣市]+[縣市])(?<district>[^鄉鎮市區]+[鄉鎮市區])(?<detailedAddress>.+)/;
                const matches = address.match(regex);

                if (!matches) {
                    return { city: null, district: null, detailedAddress: address }
                }

                const { city, district, detailedAddress } = matches.groups;
                return { city, district, detailedAddress };
            }
            const parseResult = parseAddress(value);
            return {
                city: parseResult.city,
                region: parseResult.district,
                address: parseResult.detailedAddress,
                regionId: null,
                fullAddress: null,
            };
        }
        return value;
    }, [value]);

    const triggerChange = useCallback((changedValue) => {
        let newData = {
            city: cityData?.city,
            region: regionData?.district,
            address: addr,
            regionId: regionData?.id,
            fullAddress: null,
            ...changedValue,
        };
        newData.fullAddress = `${newData.city ?? ''}${newData.region ?? ''}${newData.address ?? ''}`;
        console.log("AddressSelect: triggerChange", newData);
        onChange && onChange(newData);
    }, [addr, cityData?.city, onChange, regionData?.district, regionData?.id]);

    const handleChangeCity = useCallback((cityData) => {
        setCityData(cityData);
        triggerChange({ city: cityData?.city });
    }, [triggerChange]);

    const handleChangeRegion = useCallback((regionData) => {
        console.log("regionData", regionData);
        setRegionData(regionData);
        triggerChange({ city: regionData?.city, region: regionData?.district, regionId: regionData?.id });
    }, [triggerChange]);

    const handleChangeAddr = useCallback((e) => {
        setAddr(e.target.value);
        triggerChange({ address: e.target.value });
    }, [triggerChange]);

    useEffect(() => {
        // 若 value 為字串則自動 parseAddress 並轉為物件
        if (typeof value === 'string') {
            const result = parseAddress();
            setCityData({ city: result.city });
            setRegionData({ city: result.city, district: result.region });
            setAddr(result.address);
            triggerChange(result);
        }
    }, [handleChangeAddr, handleChangeCity, handleChangeRegion, parseAddress, triggerChange, value]);

    return (
        <Row gutter={[10, 10]} style={style}>
            <Col span={12}>
                <RegionSelect style={{ width: '100%' }} name="city" type="city" value={value?.city} onChange={handleChangeCity} placeholder="縣市" allowClear />
            </Col>
            <Col span={12}>
                <RegionSelect style={{ width: '100%' }} name="region" type="region" value={value?.region} cityName={cityData?.city} onChange={handleChangeRegion} placeholder="鄉鎮市鎮區" allowClear />
            </Col>
            <Col span={24}>
                <Input style={{ width: '100%' }} value={value?.address} onChange={handleChangeAddr} placeholder="地址" allowClear />
            </Col>
        </Row>
    )
}

export default AddressSelect