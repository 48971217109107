import { Layout } from 'antd';
import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import Menu from '../components/Menu';
import './AdminLayout.css';
import { MenuContext } from './MenuContext';
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';

function AdminLayout({ children, ...props }) {
    const [collapsed, setCollapsed] = useState(true);
    const [isMobile, setIsMobile] = useState(true);
    const loggedIn = KodomoRecyclingAPI.auth.getLoginUser()

    const toggleMenu = () => {
        setCollapsed(!collapsed);
    };
    const hideMask = () => {
        toggleMenu();
    };
    const handleBreakpoint = (isMobile) => {
        setIsMobile(isMobile);
        if (!isMobile && collapsed) {
            if (!loggedIn) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        }
        if (isMobile && !collapsed) {
            setCollapsed(true);
        }
    };
    return (
        <Layout style={{ height: '100%' }}>
            <Layout.Sider
                theme="light"
                trigger={null}
                collapsible
                collapsed={collapsed}
                breakpoint="md"
                onBreakpoint={handleBreakpoint}
                collapsedWidth={(isMobile || !loggedIn) ? 0 : 60}
                style={
                    isMobile
                        ? { position: "absolute", height: "100vh", zIndex: 99, overflowY: 'auto' }
                        : { overflowY: 'auto' }
                }
                className="layout-sider"
            >
                <div style={{ textAlign: 'center', marginTop: 10, marginBottom: 10 }}>
                    <img alt="KODOKODO" style={{ width: '90%', maxWidth: 80 }} src="/logo-dark.png" />
                </div>
                <Menu onClick={() => {
                    if (isMobile) {
                        setTimeout(() => {
                            setCollapsed(true)
                        }, 200);
                    }
                }} />
            </Layout.Sider>
            <div
                onClick={hideMask}
                style={{
                    display:
                        isMobile && !collapsed ? "block" : "none",
                    position: "absolute",
                    height: "100vh",
                    width: "100%",
                    opacity: 1,
                    backgroundColor: "rgba(0,0,0,.45)",
                    animation: "antdDrawerFadeIn .3s cubic-bezier(.7,.3,.1,1)",
                    zIndex: 98,
                }}
            ></div>
            <MenuContext.Provider value={{ toggleMenu: toggleMenu, collapsed: collapsed }}>
                {children ?? <Outlet />}
            </MenuContext.Provider>
        </Layout>
    )
}

export default AdminLayout