import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, notification } from 'antd'
import RwdRadioGroup from '../components/RwdRadioGroup';
import EditableTable from './EditableTable';
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';
import StoreSelect from '../components/StoreSelect';
import DeliveryMethodRadioGroup from '../components/DeliveryMethodRadioGroup';
import CaseStateTag from './CaseStateTag';
import dayjs from 'dayjs';
import UploadPhotoList from './UploadPhotoList';
import AppropriateTypeRadioGroup from './AppropriateTypeRadioGroup';
import BankSelect from './BankSelect';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import MemberSelect from './MemberSelect';
import CredentialUpload from './CredentialUpload';
import SignaturePad from './SignaturePad';
import BuySellAgreementPortal from './BuySellAgreementPortal';

function CaseEditTable({
    roles = [],
    storeId,
    storeType,
    showAllState = false,
    onIndex,
    onShow,
    onPhotoIndex,
    onPhotoShow,
    onCredentialShow,
    onBankbookShow,
    onSignatureShow,
}) {
    const [caseStates, setCaseStates] = useState([]);
    const [caseState, setCaseState] = useState();
    const [searchSerialNo, setSearchSerialNo] = useState();
    const [searchDeliverySerialNo, setDeliverySerialNo] = useState();
    const [printBuySellAgreement, setPrintBuySellAgreement] = useState({
        container: null,
        onLoaded: null,
        data: null,
    });
    const refreshCaseStates = useCallback(() => {
        console.log("refreshCaseStates")
        KodomoRecyclingAPI.user.caseStates.index({ store_id: storeId }).then((response) => {
            const result = response.data;
            setCaseStates(result);
        });
    }, [storeId])

    useEffect(() => {
        refreshCaseStates();
    }, [refreshCaseStates]);

    const options = useMemo(() => {
        const newOptions = caseStates
            .filter((caseState) => {
                if (caseState.id === 1) return false;
                if (storeType === "store" && caseState.storerole === 'store_member') return true;
                if (storeType === "appraisal" && caseState.storerole === 'store_appraiser') return true;
                if (!storeId && caseState.storerole !== 'store_member' && caseState.storerole !== 'store_appraiser') return true;
                return false;
            })
            .map((caseState) => ({
                label: <>{caseState.id} {caseState.adminname} {!caseState?.isfinished && <Badge size="small" count={caseState.casecount} offset={[0, -4]} />}</>,
                value: caseState.id,
                disabled: showAllState ? false : caseState?.role?.guard_name && !roles.includes(caseState?.role?.guard_name),
            }));

        if (newOptions.length) {
            newOptions.push({
                label: '全部',
                value: 'all',
            });
        }
        return newOptions;
    }, [caseStates, roles, showAllState, storeId, storeType]);

    useEffect(() => {
        if (options && options.length && !caseState) {
            setCaseState(options[0].value);
        }
    }, [caseState, options])

    const handleChange = useCallback((e) => {
        setCaseState(e.target.value);
    }, []);

    const handleSelectChange = useCallback((value) => {
        setCaseState(value);
    }, []);

    const handlePrintBuySellAgreement = useCallback((record) => {
        var printWindow = window.open('', '_blank');
        printWindow.document.title = `買賣同意書-${record.serialno}`;
        var contentToPrint = document.createElement('div');
        setPrintBuySellAgreement({
            container: contentToPrint,
            data: record,
            onLoaded: () => {
                printWindow.print();
            }
        });
        printWindow.document.body.appendChild(contentToPrint);
    }, []);

    // 只有符合的狀態才顯示編輯
    const editableColumns = useMemo(() => {
        const columns = [
            // {
            //     title: '申請 ID',
            //     dataIndex: 'id',
            // },
            {
                title: '案件狀態',
                dataIndex: 'casestate_id',
                render: (value, record) => <CaseStateTag caseStateId={value} />,
            },
            {
                title: '案件最後更新時間',
                dataIndex: 'updated_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD HH:mm:ss"),
                sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
            },

            // ------------------------------------------------------------------------

            {
                title: '初審編號',
                dataIndex: 'serialno',
                formComponent: <Input />,
            },
            {
                title: '客戶姓名',
                dataIndex: 'applycontactname',
                formComponent: <Input />,
                //validEditStateId: [1],
            },
            {
                title: '聯絡電話',
                hidden: true,
                dataIndex: 'applycontacttel',
                formComponent: <Input />,
                //validEditStateId: [1],
            },
            {
                title: '商品類別',
                render: (value, record) => {
                    const goods = record.goods;
                    if (goods && goods.length > 0) {
                        const firstGoodCategoryName = goods[0]?.goodcategory?.name;
                        return firstGoodCategoryName;
                    }
                }
            },
            {
                title: '照片',
                hidden: true,
                render: (value, record) => {
                    const goods = record.goods;
                    if (goods && goods.length > 0) {
                        const firstGoodsId = record?.goods[0]?.id;
                        return <CustomUploadPhotoList
                            onIndex={onPhotoIndex}
                            onShow={onPhotoShow}
                            caseId={record.id}
                            goodId={firstGoodsId} />;
                    }
                    return '-';
                },
            },
            {
                title: '申請時間',
                dataIndex: 'created_at',
                render: (value) => dayjs(value).format("YYYY-MM-DD"),
                sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
            },
            {
                title: '會員',
                hidden: true,
                dataIndex: 'member_id',
                render: (value, record) => `${record?.member?.user?.name} (${record?.member?.user?.email ?? '-'})`,
                formComponent: <MemberSelect />,
                //validEditStateId: [1],
            },
            {
                title: '是否為 KODOMO 會員',
                hidden: true,
                dataIndex: 'ismember',
                render: (value) => (value ? '是' : '否'),
                formComponent: <Checkbox />,
                formItemProps: {
                    valuePropName: 'checked',
                },
                validEditStateId: [1, 2],
            },
            {
                title: '是否在 KODOMO 購買',
                hidden: true,
                dataIndex: 'isbuyfromhere',
                render: (value) => (value ? '是' : '否'),
                formComponent: <Checkbox />,
                formItemProps: {
                    valuePropName: 'checked',
                },
                validEditStateId: [1, 2],
            },
            {
                title: '預收逆物流費',
                hidden: true,
                dataIndex: 'appraisalfee',
                align: 'right',
                render: (value) => value?.toLocaleString(),
                formItemProps: {
                    style: {
                        borderTop: '1px solid black',
                        paddingTop: 10,
                    },
                },
                validViewStateId: [1, 2],
            },
            {
                title: '已付逆物流費',
                dataIndex: 'isappraisalfeepaid',
                render: (value) => <YesOrNoIcon is={value} />,
                validEditStateId: [1, 2],
            },
            {
                title: '付款狀態',
                hidden: true,
                dataIndex: 'appraisalfeepaidstate',
                render: (value) => value === 'unpaid' ? '-' : <YesOrNoIcon is={value === 'success'} />,
                validViewStateId: [17, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            },
            {
                title: '付款失敗原因',
                hidden: true,
                dataIndex: 'appraisalfeepaidfailreason',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appraisalfeepaidstate') !== 'fail',
                validViewStateId: [17, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            },
            {
                title: '付款成功時間',
                hidden: true,
                dataIndex: 'appraisalfeepaidtime',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appraisalfeepaidstate') !== 'success',
                render: (value) => value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : '-',
                validViewStateId: [17, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            },
            {
                title: '付款參考編號',
                hidden: true,
                dataIndex: 'appraisalfeepaidrefno',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appraisalfeepaidstate') !== 'success',
                validViewStateId: [17, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            },
            {
                title: '送件方式',
                hidden: true,
                dataIndex: 'deliverymethod',
                render: (value) => value === 'self' ? '送至門市' : '到府收件',
                formComponent: <DeliveryMethodRadioGroup />,
                validEditStateId: [1, 2],
            },
            {
                title: '預計送達門市',
                hidden: true,
                dataIndex: 'deliverystore_id',
                render: (value, record) => `${record?.deliverystore?.name ?? '-'} (${record?.deliverystore?.address ?? '-'})`,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('deliverymethod') !== 'self',
                formComponent: <StoreSelect type="store" />,
                validEditStateId: [1, 2],
            },
            {
                title: '收件聯絡人',
                hidden: true,
                dataIndex: 'pickupcontactname',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('deliverymethod') !== 'pick-up',
                formComponent: <Input />,
                validEditStateId: [1, 2],
            },
            {
                title: '收件聯繫電話',
                hidden: true,
                dataIndex: 'pickupcontacttel',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('deliverymethod') !== 'pick-up',
                formComponent: <Input />,
                validEditStateId: [1, 2],
            },
            {
                title: '收件地址',
                hidden: true,
                dataIndex: 'pickupaddr',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('deliverymethod') !== 'pick-up',
                formComponent: <Input />,
                validEditStateId: [1, 2],
            },
            {
                title: '期望送達/預計收件時間',
                hidden: true,
                dataIndex: 'expectdeliverytime',
                formComponent: <DatePicker />,
                validEditStateId: [1, 2],
            },

            // ------------------------------------------------------------------------

            {
                title: '申請通過',
                hidden: true,
                dataIndex: 'isapproved',
                render: (value) => <YesOrNoIcon is={value} />,
                formComponent: <Radio.Group optionType="button" buttonStyle="solid">
                    <Radio.Button value={true}>是</Radio.Button>
                    <Radio.Button value={false}>否</Radio.Button>
                </Radio.Group>,
                formItemProps: {
                    style: {
                        borderTop: '1px solid black',
                        paddingTop: 10,
                    },
                },
                validViewStateId: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
                validEditStateId: [2],
            },
            {
                title: '參考會員編號/銷貨單編號',
                dataIndex: 'ref_memberno',
                formComponent: <Input />,
                validEditStateId: [2],
                validViewStateId: [2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16],
            },
            {
                title: '商品編號',
                dataIndex: 'deliveryserialno',
                validViewStateId: [4, 5],
            },
            {
                title: '是否收件',
                hidden: true,
                dataIndex: 'isrejected',
                render: (value) => <YesOrNoIcon is={!value} />,
                formComponent: <Radio.Group optionType="button" buttonStyle="solid">
                    <Radio.Button value={false}>是</Radio.Button>
                    <Radio.Button value={true}>否</Radio.Button>
                </Radio.Group>,
                validEditStateId: [4, 5],
                validViewStateId: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16],
            },
            {
                title: '鑑價中心',
                hidden: true,
                dataIndex: 'appraisalstore_id',
                render: (value, record) => `${record?.appraisalstore?.name ?? '-'} (${record?.appraisalstore?.address ?? '-'})`,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('isrejected') ?? false,
                formComponent: <StoreSelect type="appraisal" />,
                validEditStateId: [4, 5],
                validViewStateId: [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 16],
            },


            // ------------------------------------------------------------------------

            // TODO: 並無法拒絕鑑價，且狀態由 casestate 控制，因此此欄位無作用
            // {
            //     title: '已鑑價',
            //     hidden: true,
            //     dataIndex: 'isappraised',
            //     render: (value) => <YesOrNoIcon is={value} />,
            // },
            {
                title: '鑑價訊息',
                hidden: true,
                dataIndex: 'formembercomment',
                formComponent: <Input.TextArea placeholder="請輸入要傳送給客戶的鑑價訊息" />,
                formItemProps: {
                    style: {
                        borderTop: '1px solid black',
                        paddingTop: 10,
                    },
                },
                validEditStateId: [7],
                validViewStateId: [7, 8, 9, 10, 11, 12, 14, 15, 16],
            },
            {
                title: '鑑價備註',
                hidden: true,
                dataIndex: 'processmemo',
                formComponent: <Input.TextArea placeholder="請輸入要給內部人員備註的資訊" />,
                validEditStateId: [7],
                validViewStateId: [7, 8, 9, 10, 11, 12, 14, 15, 16],
            },
            {
                title: '鑑價商品金額',
                hidden: true,
                dataIndex: 'totalamount',
                render: (value) => value?.toLocaleString(),
                formComponent: <InputNumber
                    style={{ width: 150 }}
                    min={0}
                    step={100}
                    controls={false}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')} />,
                validEditStateId: [7],
                validViewStateId: [7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
            },
            // ------------------------------------------------------------------------
            {
                title: '已撥款總金額',
                hidden: true,
                dataIndex: 'paidamount',
                align: 'right',
                render: (value) => value?.toLocaleString(),
                validViewStateId: [8, 9, 10, 11, 12, 15, 16],
            },
            {
                title: '會員獎勵點數',
                hidden: true,
                dataIndex: 'memberbonus',
                align: 'right',
                render: (value) => value?.toLocaleString(),
                validViewStateId: [8, 9, 10, 11, 12, 15, 16],
            },
            {
                title: '商品獎勵點數',
                hidden: true,
                dataIndex: 'buyfromherebonus',
                align: 'right',
                render: (value) => value?.toLocaleString(),
                validViewStateId: [8, 9, 10, 11, 12, 15, 16],
            },
            {
                title: '核發總點數',
                hidden: true,
                dataIndex: 'paidbonus',
                align: 'right',
                render: (value, record) => (record?.memberbonus + record?.buyfromherebonus).toLocaleString(),
                validViewStateId: [8, 9, 10, 11, 12, 15, 16],
            },
            {
                title: '已核發總點數',
                hidden: true,
                dataIndex: 'paidbonus',
                align: 'right',
                render: (value) => value?.toLocaleString(),
                validViewStateId: [8, 9, 10, 11, 12, 15, 16],
            },
            {
                title: '撥款人員',
                hidden: true,
                dataIndex: 'appropriateuser_id',
                render: (value, record) => `${record?.appropriateuser?.name ?? '-'} (${record?.appropriateuser?.email ?? '-'})`,
                formItemProps: {
                    style: {
                        borderTop: '1px solid black',
                        paddingTop: 10,
                    },
                },
                validViewStateId: [15, 16],
            },

            // ------------------------------------------------------------------------
            {
                title: '是否販售',
                hidden: true,
                dataIndex: 'isreadyforsale',
                render: (value) => <YesOrNoIcon is={value} />,
                formItemProps: {
                    style: {
                        borderTop: '1px solid black',
                        paddingTop: 10,
                    },
                },
                formComponent: <Radio.Group optionType="button" buttonStyle="solid">
                    <Radio.Button value={true}>是</Radio.Button>
                    <Radio.Button value={false}>否</Radio.Button>
                </Radio.Group>,
                //validEditStateId: [8],
                validViewStateId: [8, 9, 10, 11, 12, 13, 14, 15, 16],
            },
            {
                title: '撥款方式',
                hidden: true,
                dataIndex: 'appropriatetype',
                render: (value) => (value === 'remit' ? '匯款' : '親臨門市'),
                formComponent: <AppropriateTypeRadioGroup />,
                hiddenFromForm: ({ getFieldValue }) => !getFieldValue('isreadyforsale') ?? true,
                //validEditStateId: [8],
                validViewStateId: [8, 9, 10, 11],
            },
            {
                title: '領款門市',
                hidden: true,
                dataIndex: 'appropriatestore_id',
                render: (value, record) => `${record?.appropriatestore?.name ?? '-'} (${record?.appropriatestore?.address ?? '-'})`,
                formComponent: <StoreSelect type="store" />,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appropriatetype') !== 'cash',
                //validEditStateId: [8],
                validViewStateId: [8, 9, 11],
            },
            {
                title: '預計領款日期',
                dataIndex: 'expectappropriatedate',
                render: (value) => value ? dayjs(value).format("YYYY-MM-DD") : '-',
                validViewStateId: [8, 9, 11],
            },
            {
                title: '匯款戶名',
                hidden: true,
                dataIndex: 'remitaccountname',
                formComponent: <Input />,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appropriatetype') !== 'remit',
                //validEditStateId: [8],
                validViewStateId: [8, 9, 10],
            },
            {
                title: '匯款銀行 (代碼)',
                hidden: true,
                dataIndex: 'remitaccountbankcode',
                formComponent: <BankSelect />,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appropriatetype') !== 'remit',
                //validEditStateId: [8],
                validViewStateId: [8, 9, 10],
            },
            {
                title: '匯款帳號',
                hidden: true,
                dataIndex: 'remitaccount',
                formComponent: <Input />,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appropriatetype') !== 'remit',
                //validEditStateId: [8],
                validViewStateId: [8, 9, 10],
            },
            {
                title: '領款地址',
                hidden: true,
                dataIndex: 'paymentreceiptaddr',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appropriatetype') !== 'remit',
                validViewStateId: [8, 9, 10],
            },
            {
                title: '領款身分證',
                hidden: true,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appropriatetype') !== 'remit',
                render: (value, record) => record.id && onCredentialShow ? <IdCardCredentialUpload caseId={record.id} onShow={onCredentialShow} /> : '-',
                validViewStateId: [8, 9, 10],
            },
            {
                title: '領款存摺封面',
                hidden: true,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('appropriatetype') !== 'remit',
                render: (value, record) => record.id && onBankbookShow ? <BankbookCredentialUpload caseId={record.id} onShow={onBankbookShow} /> : '-',
                validViewStateId: [8, 9, 10],
            },
            {
                title: '簽名',
                hidden: true,
                hiddenFromForm: ({ getFieldValue }) => !getFieldValue('isreadyforsale') ?? true,
                render: (value, record) => record.id && onSignatureShow ? <CustomSignaturePad caseId={record.id} onShow={onSignatureShow} /> : '-',
                validViewStateId: [8, 9, 10, 12],
            },
            {
                title: '退回聯絡人',
                hidden: true,
                dataIndex: 'returncontactname',
                formComponent: <Input />,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('isreadyforsale') ?? true,
                //validEditStateId: [8],
                validViewStateId: [8, 14],
            },
            {
                title: '退回聯繫電話',
                hidden: true,
                dataIndex: 'returncontacttel',
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('isreadyforsale') ?? true,
                formComponent: <Input />,
                //validEditStateId: [8],
                validViewStateId: [8, 14],
            },
            {
                title: '退回地址',
                hidden: true,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('isreadyforsale') ?? true,
                dataIndex: 'returnaddr',
                formComponent: <Input />,
                //validEditStateId: [8],
                validViewStateId: [8, 14],
            },
            // ------------------------------------------------------------------------
            // 10 會計匯款
            {
                title: '買賣同意書',
                hidden: true,
                render: (value, record) => {
                    if (onSignatureShow) {
                        return <Button type="primary" disabled={false} onClick={() => handlePrintBuySellAgreement(record)}>列印</Button>
                    } else {
                        return <>您沒有權限瀏覽簽名，無法列印</>
                    }

                },
                validViewStateId: [10, 11, 12, 13, 14, 15, 16],
            },

            // 11 門市領款
            {
                title: '參考發票號碼',
                hidden: true,
                dataIndex: 'ref_invoiceno',
                render: (value) => value?.toLocaleString(),
                formComponent: <Input placeholder="參考發票號碼" />,
                validEditStateId: [11],
                validViewStateId: [11, 12, 13, 14, 15, 16],
            },

            // ------------------------------------------------------------------------
            {
                title: '退回物流編號',
                hidden: true,
                dataIndex: 'returnpacknumber',
                formComponent: <Input />,
                hiddenFromForm: ({ getFieldValue }) => getFieldValue('isreadyforsale') ?? true,
                validEditStateId: [14],
                validViewStateId: [14, 15, 16],
            },

        ];

        return columns.map((column) => {
            return {
                ...column,
                hiddenFromForm: column.validViewStateId ? (column.validViewStateId.includes(caseState) ? column.hiddenFromForm : true) : column.hiddenFromForm,
                formComponent: column.validEditStateId?.includes(caseState) ? column.formComponent : undefined,
            };
        })
    }, [caseState, handlePrintBuySellAgreement, onBankbookShow, onCredentialShow, onPhotoIndex, onPhotoShow, onSignatureShow]);

    const handleIndex = useCallback((query, page, pageSize) => {
        if (!caseState) return;
        return onIndex({
            casestate_id: caseState === 'all' ? undefined : caseState,
            //casestate_id: caseState,
            serialno: searchSerialNo,
            deliveryserialno: searchDeliverySerialNo,
        }, page, pageSize).then((response) => {
            return response;
        });
    }, [caseState, onIndex, searchDeliverySerialNo, searchSerialNo]);

    const handleShow = useCallback((id) => {
        return onShow(id).then((response) => {
            const result = response.data;
            result.expectdeliverytime = result.expectdeliverytime ? dayjs(result.expectdeliverytime) : null;
            return response;
        });
    }, [onShow]);

    const handleUpdate = useCallback((id, values) => {
        console.log("CaseEditTable: handleUpdate: ", id, values);
        switch (caseState) {
            case 1:
                notification.warning({ message: '此步驟須由客戶來完成' });
                break; // Customer
            // Support
            case 2:
                return KodomoRecyclingAPI.support.cases.approve(id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 3:
                notification.warning({ message: '此步驟須由客戶來完成' });
                break; // Customer
            case 4:
                return KodomoRecyclingAPI.support.cases.receiveGoodsFromLogistics(id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 5:
                if (!storeId) {
                    notification.warning({ message: '此步驟須由門市來完成' });
                    return;
                }
                if (values.isrejected === undefined || values.isrejected === 0) {
                    notification.warning({ message: '請選擇是否收件' });
                    return;
                }
                return KodomoRecyclingAPI.store.stores.cases.receiveGoodsFromMember(storeId, id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 6:
                if (!storeId) {
                    notification.warning({ message: '此步驟須由鑑價中心來完成' });
                    return;
                }
                return KodomoRecyclingAPI.store.stores.cases.confirmGoodsReachedAppraisalStore(storeId, id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 7:
                if (!storeId) {
                    notification.warning({ message: '此步驟須由鑑價中心來完成' });
                    return;
                }
                return KodomoRecyclingAPI.store.stores.cases.appraise(storeId, id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 8:
                notification.warning({ message: '此步驟須由客戶來完成' });
                break; // Customer
            case 9:
                return KodomoRecyclingAPI.accounting.cases.confirmRemittanceAndRecycleBonus(id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 10:
                return KodomoRecyclingAPI.accounting.cases.remit(id, values);
            case 11:
                if (!storeId) {
                    notification.warning({ message: '此步驟須由門市來完成' });
                    return;
                }
                return KodomoRecyclingAPI.store.stores.cases.cash(storeId, id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 12:
                return KodomoRecyclingAPI.support.cases.issueRecycleBonus(id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 13:
                if (!storeId) {
                    notification.warning({ message: '此步驟須由鑑價中心來完成' });
                    return;
                }
                return KodomoRecyclingAPI.store.stores.cases.launchGoods(storeId, id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            case 14:
                if (!storeId) {
                    notification.warning({ message: '此步驟須由鑑價中心來完成' });
                    return;
                }
                return KodomoRecyclingAPI.store.stores.cases.returnGoods(storeId, id, values).then((response) => {
                    refreshCaseStates();
                    return response;
                });
            default:
                // if (KodomoRecyclingAPI.auth.getLoginUser().rolekeys.includes('admin')) {
                //     return KodomoRecyclingAPI.admin.cases.update(id, values).then((response) => {
                //         refreshCaseStates();
                //         return response;
                //     });
                // }
                return new Promise((resolve, reject) => {
                    resolve({
                        data: null,
                    });
                });
        }

    }, [caseState, refreshCaseStates, storeId]);
    //console.log("editableColumns", editableColumns);

    const getReadyOnly = useCallback(() => {
        if ([8, 15, 16].includes(caseState)) return true;
        return false;

    }, [caseState])

    const getActionText = useCallback(() => {
        switch (caseState) {
            case 4: // 待門市收件
            case 5: // 待到府收件
            case 6: // 待鑑價中心收件
                return '完成收件';
            case 9:
                return '已審核';
            case 10:
                return '已匯款';
            case 11:
                return '已領款';
            case 12:
                return '已核發';
            case 13:
                return '已上架';
            default:
                return '送出';
        }
    }, [caseState]);
    // console.log("caseState", caseState, getActionText());
    // console.log("onSignatureShow", onSignatureShow);
    return (
        <Row gutter={[10, 10]}>
            <Col span={24}>
                {false && <RwdRadioGroup
                    options={options}
                    onChange={handleChange}
                    value={caseState}
                    optionType="button"
                    buttonStyle="solid"
                />}
                <Form labelCol={{ span: 4 }}>
                    <Form.Item label="初審編號">
                        <Input allowClear placeholder="例: 230815001I717K" style={{ maxWidth: 250 }} onChange={(e) => {
                            setSearchSerialNo(e.target.value);
                        }} />
                    </Form.Item>
                    <Form.Item label="商品編號">
                        <Input allowClear placeholder="例: 000230821101" style={{ maxWidth: 250 }} onChange={((e) => {
                            setDeliverySerialNo(e.target.value)
                        })} />
                    </Form.Item>
                    <Form.Item label="狀態">
                        <Select
                            style={{ width: 250 }}
                            options={options}
                            onChange={handleSelectChange}
                            value={caseState}
                        />
                    </Form.Item>
                </Form>
            </Col>
            <Col span={24}>
                <EditableTable
                    name="案件"
                    readonly={getReadyOnly()}
                    columns={editableColumns}
                    drawerSize="large"
                    onIndex={handleIndex}
                    onShow={handleShow}
                    onUpdate={handleUpdate}
                    editText="處理"
                    drawerProps={{
                        hideReset: true,
                        submitText: getActionText(),
                        highlightEditable: true,
                    }}
                />
                <BuySellAgreementPortal
                    targetDomNode={printBuySellAgreement.container}
                    onLoaded={printBuySellAgreement.onLoaded}
                    caseData={printBuySellAgreement.data}
                    onCredentialShow={onCredentialShow}
                    onBankbookShow={onBankbookShow}
                    onSignatureShow={onSignatureShow}
                />
            </Col>
        </Row>
    )
}

function CustomUploadPhotoList({ onIndex, onShow, caseId, goodId }) {
    const handleIndex = useMemo(() => {
        return onIndex ? () => onIndex(caseId, goodId, 1, 999) : undefined;
    }, [caseId, goodId, onIndex]);

    const handleShow = useMemo(() => {
        return onShow ? (id) => onShow(caseId, goodId, id) : undefined;
    }, [caseId, goodId, onShow])

    return <UploadPhotoList
        onIndex={handleIndex}
        onShow={handleShow}
    />
}

function YesOrNoIcon({ is }) {
    if (is === undefined || is === null) {
        return '-';
    }
    return is ? <CheckOutlined style={{ color: 'green' }} /> : <CloseOutlined style={{ color: 'red' }} />;
}

function IdCardCredentialUpload({ caseId, onShow }) {
    const handleShowFront = useMemo(() => {
        return onShow ? (params) => onShow(caseId, 'front', params) : undefined;
    }, [caseId, onShow]);

    const handleShowBack = useMemo(() => {
        return onShow ? (params) => onShow(caseId, 'back', params) : undefined;
    }, [caseId, onShow]);
    return <>
        <CredentialUpload text="正面" onShow={handleShowFront} />
        <CredentialUpload text="背面" onShow={handleShowBack} />
    </>
}
function BankbookCredentialUpload({ caseId, onShow }) {
    const handleShow = useMemo(() => {
        return onShow ? (params) => onShow(caseId, params) : undefined;
    }, [caseId, onShow]);
    return <CredentialUpload onShow={handleShow} />
}
function CustomSignaturePad({ caseId, onShow }) {
    const handleShow = useMemo(() => {
        return onShow ? (params) => onShow(caseId, params) : undefined;
    }, [caseId, onShow]);

    return <SignaturePad onShow={handleShow} />
}
export default CaseEditTable