import React, { useMemo } from 'react'
import { Col, Row } from 'antd'
import ContentLayout from '../../layouts/ContentLayout'
import PermissionManager from '../../config/PermissionManager';
import CaseEditTable from '../../components/CaseEditTable';
import KodomoRecyclingAPI from '../../services/KodomoRecyclingAPI';

function HqCasesPage() {
    const roles = PermissionManager.getRoles();

    const handleCredentialShow = useMemo(() => {
        if (roles.includes(PermissionManager.ROLE_ACCOUNTING)) {
            return (caseId, side, params) => KodomoRecyclingAPI.accounting.cases.paymentReceiptCredentials.idcard.show(caseId, side, params);
        }
        return null;
    }, [roles]);
    const handleBankbookShow = useMemo(() => {
        if (roles.includes(PermissionManager.ROLE_ACCOUNTING)) {
            return (caseId, params) => KodomoRecyclingAPI.accounting.cases.paymentReceiptCredentials.bankbook.show(caseId, params);
        }
        return null;
    }, [roles]);
    const handleSignatureShow = useMemo(() => {
        if (roles.includes(PermissionManager.ROLE_ACCOUNTING)) {
            return (caseId) => KodomoRecyclingAPI.accounting.cases.paymentReceiptSignature.show(caseId);
        }
        return null;
    }, [roles]);
    return (
        <ContentLayout>
            <Row gutter={[10, 10]}>
                <Col span={24}>
                </Col>
                <Col span={24}>
                    <CaseEditTable
                        roles={roles}
                        onIndex={KodomoRecyclingAPI.employee.cases.index}
                        onShow={KodomoRecyclingAPI.employee.cases.show}
                        onPhotoIndex={(caseId, goodId, page, pagesize) => KodomoRecyclingAPI.employee.cases.goods.photos.index(caseId, goodId, {}, page, pagesize)}
                        onPhotoShow={(caseId, goodId, id) => KodomoRecyclingAPI.employee.cases.goods.photos.show(caseId, goodId, id)}
                        onCredentialShow={handleCredentialShow}
                        onBankbookShow={handleBankbookShow}
                        onSignatureShow={handleSignatureShow}
                    />
                </Col>
            </Row>
        </ContentLayout>
    )
}

export default HqCasesPage