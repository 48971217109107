import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'

const ImageUploadInput = forwardRef(({ onChange, }, ref) => {
    const [isIOS, setIsIOS] = useState(false);
    const iOSUploadInputRef = useRef();
    const androidUploadInputRef = useRef();

    const click = useCallback(() => {
        if (isIOS) {
            iOSUploadInputRef.current.click();
        } else {
            androidUploadInputRef.current.click();
        }
    }, [isIOS]);
    const reset = useCallback(() => {
        if (isIOS) {
            iOSUploadInputRef.current.value = "";
        } else {
            androidUploadInputRef.current.value = "";
        }
    }, [isIOS]);

    useImperativeHandle(ref, () => ({
        click: click,
        reset: reset,
    }), [click, reset]);

    const handleChange = useCallback((event) => {
        onChange(event);
        reset();
    }, [onChange, reset]);

    useEffect(() => {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
        setIsIOS(isIOS);
    }, []);

    return <>
        <input
            ref={iOSUploadInputRef}
            multiple={false}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            onChange={handleChange} />
        <input
            ref={androidUploadInputRef}
            multiple={false}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            capture="camera"
            onChange={handleChange} />
    </>
});

function CustomMultipleUpload({ fileList, onPreview, onChange, onRemove, customRequest, itemRender, children, ...props }) {
    const [uploadQueue, setUploadQueue] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const fileInputRef = useRef();

    const generateUid = useCallback(() => {
        // Generate unique id by time and random string.
        return `uid-${new Date().getTime()}-${Math.random().toString(36).substring(2)}`;
    }, []);
    const generateThumbUrl = useCallback(async (file) => {
        //create thumbnail of image by canvas
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const img = new Image();
        img.src = URL.createObjectURL(file);
        const dataUrl = await new Promise((resolve, reject) => {
            img.onload = () => {
                const width = img.width;
                const height = img.height;
                canvas.width = 102;
                canvas.height = 102;
                ctx.drawImage(img, 0, 0, width, height, 0, 0, 102, 102);
                resolve(canvas.toDataURL());
            };
            img.onerror = (error) => {
                reject(error);
            }
        });
        return dataUrl;
    }, []);

    const handleChange = useCallback(async (originalFileObj, status, event) => {
        const newFileList = [...fileList];
        const index = fileList.findIndex((item) => item.uid === originalFileObj.uid);
        let newFile = null;

        // console.log("CustomMultipleUpload: handleChange", status, event, originalFileObj, newFileList)
        if (status === "removed") {
            newFileList.splice(index, 1);
            onChange({ file: originalFileObj, fileList: newFileList, event });
            return;
        } else {
            if (index >= 0) {
                newFile = { ...newFileList[index] };
                newFile.status = status;
                newFileList[index] = newFile;
            } else {
                const newUid = generateUid();
                // console.log("CustomMultipleUpload: handleChange: new uid", newUid);
                newFile = {
                    uid: newUid,
                    type: originalFileObj.type,
                    name: originalFileObj.name,
                    lastModified: originalFileObj.lastModified,
                    lastModifiedDate: originalFileObj.lastModifiedDate,
                    percent: 0,
                    size: originalFileObj.size,
                    status: status,
                    thumbUrl: await generateThumbUrl(originalFileObj),
                    ...event,
                };
                newFileList.push(newFile);
            }
        }
        onChange({ file: newFile, fileList: newFileList, event });
    }, [fileList, generateThumbUrl, generateUid, onChange]);

    const processQueue = useCallback(async () => {
        if (uploadQueue.length > 0 && !isUploading) {
            const { file, filename } = uploadQueue[0];
            const handleProgress = ({ percent }) => {
                handleChange(file, "uploading", { percent });
            };
            const handleSuccess = (response) => {
                handleChange(file, "done", { response });
                // 上傳成功後，移除隊列中的這個文件，然後處理下一個文件
                setUploadQueue((currentQueue) => currentQueue.slice(1));
                setIsUploading(false);
            };
            const handleError = (error) => {
                handleChange(file, "error", { error });
                setUploadQueue((currentQueue) => currentQueue.slice(1));
                setIsUploading(false);
            };
            setIsUploading(true);
            await handleChange(file, "uploading", { percent: 0 });
            customRequest({ file, filename, onProgress: handleProgress, onSuccess: handleSuccess, onError: handleError });
        }
    }, [uploadQueue, isUploading, handleChange, customRequest]);

    useEffect(() => {
        processQueue();
    }, [uploadQueue, isUploading, processQueue]);

    const handleUploadFileChange = (event) => {
        const files = event.target.files;
        // console.log("Selected files", files);
        if (files.length > 0) {
            const filesArray = Array.from(files).map(file => ({
                file,
                filename: file.name,
            }));
            setUploadQueue((currentQueue) => [...currentQueue, ...filesArray]);
        }
    };

    const openFileUpload = useCallback(() => {
        fileInputRef.current.click();
    }, []);

    return <>
        <span
            className="ant-upload-wrapper ant-upload-picture-card-wrapper"
        >
            <div className="ant-upload-list ant-upload-list-picture-card">
                {fileList.map((file) => {
                    const actions = {
                        preview: () => {
                            onPreview(file);
                        }, remove: () => {
                            if (onRemove) {
                                onRemove(file).then((response) => {
                                    // console.log(response);
                                    handleChange(file, "removed", { response });
                                });
                            }
                        }
                    };
                    const status = file.status;
                    let originalNode = null;
                    if (status === "error") {
                        originalNode = <div
                            key={file.uid}
                            style={{
                                display: "inline-block",
                                width: 102,
                                height: 102,
                                marginBlock: '0 8px',
                                marginInline: '0 8px',
                                verticalAlign: 'top',
                                backgroundColor: 'rgba(0,0,0,0.02)',
                                border: '1px dashed #d9d9d9',
                                borderRadius: 8,
                                cursor: 'pointer',
                                transition: 'border-color 0.3s',
                            }}>
                            <span tabIndex={0} role="button" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                textAlign: 'center',
                            }}>
                                上傳失敗
                            </span>
                        </div>
                    }
                    if (status === "uploading") {
                        originalNode = <div
                            key={file.uid}
                            style={{
                                display: "inline-block",
                                width: 102,
                                height: 102,
                                marginBlock: '0 8px',
                                marginInline: '0 8px',
                                verticalAlign: 'top',
                                backgroundColor: 'rgba(0,0,0,0.02)',
                                border: '1px dashed #d9d9d9',
                                borderRadius: 8,
                                cursor: 'pointer',
                                transition: 'border-color 0.3s',
                            }}
                        >
                            <span tabIndex={0} role="button" style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                textAlign: 'center',
                            }}>
                                上傳中 ({file.percent}%)
                            </span>
                        </div>
                    }
                    return <div
                        key={file.uid}
                        style={{
                            display: "inline-block",
                            width: 102,
                            height: 102,
                            marginBlock: '0 8px',
                            marginInline: '0 8px',
                            verticalAlign: 'top',
                        }}>
                        {itemRender(originalNode, file, fileList, actions)}
                    </div>;
                })}
                {children && <div
                    onClick={openFileUpload}
                    style={{
                        display: "inline-block",
                        width: 102,
                        height: 102,
                        marginBlock: '0 8px',
                        marginInline: '0 8px',
                        verticalAlign: 'top',
                        backgroundColor: 'rgba(0,0,0,0.02)',
                        border: '1px dashed #d9d9d9',
                        borderRadius: 8,
                        cursor: 'pointer',
                        transition: 'border-color 0.3s',
                    }}>
                    <span tabIndex={0} role="button" style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                    }}>
                        <ImageUploadInput ref={fileInputRef} onChange={handleUploadFileChange} />
                        <div>
                            {children}
                        </div>
                    </span>
                </div>}
            </div>
        </span>
    </>
}


export default CustomMultipleUpload