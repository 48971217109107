import './App.css';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { RouterProvider, createHashRouter } from 'react-router-dom';
import { ConfigProvider, Skeleton } from 'antd';
import { menuGroups, routes } from './config/routes';
import './config/request';
import zhTW from 'antd/locale/zh_TW';
import KodomoRecyclingAPI from './services/KodomoRecyclingAPI';
import PermissionManager from './config/PermissionManager';
import { useDispatch } from 'react-redux';
import { fetchCaseStates } from './reducers/caseStatesSlice';
import axios from "axios";
import { responseOnFulfilledInterceptor, responseOnRejectedInterceptor } from './config/request';
import ECPayLoader from './utils/ECPayLoader';

const liff = window.liff;

function App() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const [searchParams,] = useSearchParams();
  const [isReady, setIsReady] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [appLogined, setAppLogined] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [roles, setRoles] = useState([]);

  const handleAppLogined = (value) => {
    setAppLogined(value);
  }

  const handleUpdateRole = (roles) => {
    setRoles(roles);
  }

  useEffect(() => {
    console.log("liff", liff);
    liff.ready.then((response) => {
      console.log("Line liff sdk initialized.");
    });
  }, []);

  useEffect(() => {
    ECPayLoader.ready().then(() => {
      console.log("ECPay initialized");
    });
  }, []);

  const loginWithLine = useCallback(async () => {
    if (!liff.isLoggedIn()) return;
    if (KodomoRecyclingAPI.auth.getLoginUser()) return;
    console.log("login with line");
    await KodomoRecyclingAPI.auth.verifyLineLogin().then((response) => {
      const idToken = liff.getDecodedIDToken();
      console.log("LIFF: idToken", idToken);
      console.log("LIFF: User email", idToken.email);
      console.log("LIFF: Get profile....");
      liff.getProfile().then((profile) => {
        console.log("LIFF: User Profile", profile);
      });

      // if (searchParams.get('redirect')) {
      //   navigate(searchParams.get('redirect'));
      // }
    });
  }, []);

  const initCallback = useCallback(() => {
    // console.log("init");
    KodomoRecyclingAPI.auth.init(handleAppLogined);
    PermissionManager.init(handleUpdateRole);
    // console.log("init callback");
    liff.ready.then(() => {
      // console.log("liff ready");
      // try login with token first, login with line second.
      // 若既有使用者要綁定 line 登入，則必須先以 token 登入，再進行 line 登入，否則會變成以 line 註冊新使用者
      KodomoRecyclingAPI.auth.loginWithToken().finally(() => {
        loginWithLine().finally(() => {
          setIsReady(true);
          if (KodomoRecyclingAPI.auth.getLoginUser()) {
            dispatch(fetchCaseStates());
          }
        });
      });
    });

  }, [dispatch, loginWithLine]);

  useEffect(() => {
    initCallback();
  }, [initCallback]);

  const filteredRoutes = useMemo(() => {
    const filterRoutes = (routes, roles) => {
      const newRoutes = [];
      routes.forEach((route) => {
        const newRoute = Object.assign({}, route);
        let access = newRoute.access;
        if (!access) {
          const menuGroup = newRoute?.menu?.group;
          if (menuGroup) {
            access = menuGroups.find((row) => row.id === menuGroup)?.access;
          }
        }

        if (newRoute.children) {
          newRoute.children = filterRoutes(route.children, roles);
        }
        if (access) {
          const haveAccess = roles.reduce((pv, role, idx) => {
            return pv || access.indexOf(role) >= 0
          }, false);
          if (haveAccess) {
            newRoutes.push(newRoute);
          } else {
            // skip
          }
        } else {
          // default have access if undefined.
          newRoutes.push(newRoute);
        }
      });
      return newRoutes;
    }
    return filterRoutes(routes, roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roles]);
  //console.log("filteredRoutes", filteredRoutes);

  const router = createHashRouter(filteredRoutes);
  useEffect(() => {
    console.log("Add a response interceptor", router);
    // Add a response interceptor
    const id = axios.interceptors.response.use(
      (response) => responseOnFulfilledInterceptor(response, router.navigate),
      (error) => responseOnRejectedInterceptor(error, router.navigate));

    return () => {
      console.log("axios eject", id);
      axios.interceptors.response.eject(id);
    }
  }, [router]);

  const urlParams = new URLSearchParams(window.location.search);
  const liffState = urlParams.get('liff.state');
  if (liffState) {
    // liffState 存在時 liff 登入進行中，顯示讀取畫面
    return <div style={{ padding: 20 }}><Skeleton active /></div>;
  }
  return (
    <ConfigProvider locale={zhTW} theme={{ token: { colorPrimary: '#69CB64', fontSize: 16 } }}>
      <div className="App">
        {!isReady ?
          <div style={{ padding: 20 }}><Skeleton active /></div> :
          <RouterProvider router={router} />}
      </div>
    </ConfigProvider>
  );
}

export default App;
