import React, { useCallback, useEffect, useState } from 'react'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI'
import { Select } from 'antd';

function RegionSelect({ type = "city", cityName = undefined, cityId = undefined, value, onChange, ...props }) {
    const [regions, setRegions] = useState([]);
    const [loading, setLoading] = useState(false);

    //console.log("RegionSelect: ", type, cityName, props);
    useEffect(() => {
        setLoading(true);
        KodomoRecyclingAPI.user.regions.index({ type: type, cityname: cityName, cityid: cityId }, 1, 999).then((response) => {
            const results = response.data;
            const items = results.data.filter((region) => {
                if (type === "region") {
                    return !!region.district;
                }
                if (type === "city") {
                    return !!region.city;
                }
                return !!region.name;
            });
            setRegions(items);
        }).finally(() => {
            setLoading(false);
        });
    }, [cityName, cityId, type]);

    const handleChange = useCallback((value) => {
        const newValue = regions.find((row) => row.id === value);
        onChange && onChange(newValue);
    }, [onChange, regions]);

    const getName = useCallback((region) => {
        if (type === "city") {
            return region.city;
        }
        if (type === "region") {
            return region.district;
        }
    }, [type]);

    const getValue = useCallback(() => {
        const foundRegion = regions.find((row) => getName(row) === value);
        return foundRegion?.id;
    }, [getName, regions, value]);

    return (
        <Select
            style={{ minWidth: 100 }}
            loading={loading}
            showSearch
            defaultActiveFirstOption={true}
            filterOption={(input, option) => (option?.label ?? '').includes(input)}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={regions.map((region) => ({ value: region.id, label: getName(region) }))}
            value={getValue()}
            onChange={handleChange}
            {...props}
        />
    )
}

export default RegionSelect