import { Tag } from 'antd'
import React, { useCallback } from 'react'
import { useSelector } from 'react-redux';
import { selectCaseStatesById } from '../reducers/caseStatesSlice';

function CaseStateTag({ caseStateId }) {
  const caseState = useSelector((state) => selectCaseStatesById(state, caseStateId));
  const getColor = useCallback((num) => {
    const colors = [
      'magenta',
      'red',
      'volcano',
      'orange',
      'gold',
      'lime',
      'green',
      'cyan',
      'blue',
      'geekblue',
      'purple',
    ];
    return colors[colors.length % num];
  }, []);
  return (
    <Tag color={getColor(caseState?.id)}>{caseState?.adminname}</Tag>
  )
}

export default CaseStateTag