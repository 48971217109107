import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Space, notification } from 'antd'
import ReactSignatureCanvas from 'react-signature-canvas'
import KodomoRecyclingAPI from '../services/KodomoRecyclingAPI';

function SignaturePad({ onShow, onStore, onDestroy, ...props }) {
    const defaultWidth = 340;
    const defaultHeight = 340;
    const [, setCanvasSize] = useState({ width: defaultWidth, height: defaultHeight });
    // eslint-disable-next-line no-unused-vars
    const [sigDataUrl, setSigDataUrl] = useState();
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false);

    const canvas = useRef(null);
    const wrapper = useRef(null);
    const restoreData = useCallback(() => {
        if (!canvas.current) return;
        canvas.current.clear();
        if (sigDataUrl) {
            canvas.current.fromDataURL(sigDataUrl, { ratio: 1 });
        }
    }, [sigDataUrl]);

    const resized = useCallback(() => {
        // console.log("resized");
        if (!canvas.current) return;
        // const rawCanvas = canvas.current.getCanvas();
        // console.log("canvas", rawCanvas.width, rawCanvas.height);
        const wrapperWidth = wrapper.current ? wrapper.current.clientWidth : defaultWidth;
        const wrapperHeight = wrapper.current ? wrapper.current.clientHeight : defaultHeight;

        // console.log("wrapper", wrapperWidth, wrapperHeight);
        const tmpData = canvas.current.toDataURL();

        // Resize canvas
        canvas.current.getCanvas().width = wrapperWidth;
        canvas.current.getCanvas().height = wrapperHeight;
        setCanvasSize({ width: wrapperWidth, height: wrapperHeight });

        canvas.current.clear();
        canvas.current.fromDataURL(tmpData, { ratio: 1 });
    }, [canvas]);

    useEffect(() => {
        restoreData();
    }, [restoreData]);

    useEffect(() => {
        setTimeout(() => {
            resized(); // after drawer visible
        }, 100);
        window.addEventListener("resize", resized);
        return () => window.removeEventListener("resize", resized);
    }, [resized]);

    const handleClear = () => {
        canvas.current.clear();
    }
    const refresh = useCallback(() => {
        setLoading(true);
        onShow && onShow().then((response) => {
            const result = response.data;
            // console.log("read signature size", result?.filedata?.length);
            setSigDataUrl(result.filedata);
        }).finally(() => {
            setLoading(false);
        });
    }, [onShow]);

    useEffect(() => {
        refresh();
    }, [refresh]);

    const handleFinishSignature = () => {
        const dataUrl = canvas.current.toDataURL();
        // console.log("save signature size", dataUrl.length);
        // console.log('handleFinishSig', itemId, vehicleId, file, dataUrl);
        const file = KodomoRecyclingAPI.dataUrlToFile(dataUrl);

        onStore && onStore(file).then((response) => {
            notification.info({ message: '簽名儲存成功' });
        }).finally(() => {
            refresh();
        });
    }

    const handleDeleteSignature = () => {
        onDestroy && onDestroy().then((response) => {
            notification.info({ message: '簽名刪除成功' });
        }).finally(() => {
            setSigDataUrl();
            refresh();
        });
    }

    return <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ flex: 1, lineHeight: 0, border: '1px solid black', overflow: 'auto' }}>
            {onStore ?
                <div ref={wrapper} style={{ height: '100%', minHeight: 540, position: 'relative' }}>
                    <ReactSignatureCanvas ref={canvas} penColor='blue' clearOnResize={false}
                        canvasProps={{ width: defaultWidth, height: defaultHeight, className: 'sigCanvas' }} />
                    <div style={{ position: 'absolute', bottom: 10, right: 10 }}>
                        <Space>
                            {onDestroy && sigDataUrl && <Button onClick={handleDeleteSignature}>刪除</Button>}
                            {onStore && <Button onClick={handleClear}>清除</Button>}
                            {onStore && <Button type="primary" onClick={handleFinishSignature}>送出</Button>}
                        </Space>
                    </div>
                </div>
                :
                <div style={{ minHeight: 540 }}>
                    {sigDataUrl ? <img src={sigDataUrl} alt="Preview" style={{ height: '100%' }} /> : null}
                </div>
            }
        </div>
    </div>

}

export default SignaturePad